import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { DAILY_STRIPE_SUMMARY_COLUMNS } from '../constants';
import {
  DailyStripeSummaryExtrasDTO,
  IDailyStripeSummaryResponseDTO,
} from '../types/reports.types';
import * as paymentsTypes from './reportsActionTypes';

export interface DailyAdyenSummaryState {
  status: string;
  refreshing: boolean;
  columns: IAppTableColumn[];
  dailyAdyenSummaryData: IDailyStripeSummaryResponseDTO[];
  lastUpdated: string;
  totalCount: number;
}

const initialState: DailyAdyenSummaryState = {
  status: 'idle',
  refreshing: false,
  columns: DAILY_STRIPE_SUMMARY_COLUMNS,
  dailyAdyenSummaryData: [],
  lastUpdated: '',
  totalCount: 0,
};

interface DailyAdyenSummaryProps {
  country: string;
  date_from?: string;
  date_to?: string;
  is_export?: string;
  offset?: string;
  limit?: number;
  skip?: number;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: DailyAdyenSummaryProps,
): Promise<
  PaginatedResponseDTO<
    IDailyStripeSummaryResponseDTO,
    DailyStripeSummaryExtrasDTO
  >
> => {
  const url = URLS.REPORTS.DAILY_ADYEN_SUMMARY;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<
        IDailyStripeSummaryResponseDTO,
        DailyStripeSummaryExtrasDTO
      >
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchDailyAdyenSummaryDetails = createAppAsyncThunk<
  PaginatedResponseDTO<
    IDailyStripeSummaryResponseDTO,
    DailyStripeSummaryExtrasDTO
  >,
  DailyAdyenSummaryProps
>(
  paymentsTypes.REQUEST_DAILY_ADYEN_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const fetchDailyAdyenSummaryExport = createAppAsyncThunk<
  PaginatedResponseDTO<
    IDailyStripeSummaryResponseDTO,
    DailyStripeSummaryExtrasDTO
  >,
  DailyAdyenSummaryProps
>(
  paymentsTypes.REQUEST_DAILY_ADYEN_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const dailyAdyenSummarySlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setColumns: (
      state: DailyAdyenSummaryState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
    resetDailyAdyenSummaryState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDailyAdyenSummaryDetails.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchDailyAdyenSummaryDetails.fulfilled, (state, action) => {
        state.dailyAdyenSummaryData = action.payload.results;
        state.lastUpdated = action.payload.extras.lastUpdated;
        state.totalCount = action.payload.extras.totalCount;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchDailyAdyenSummaryDetails.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchDailyAdyenSummaryExport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDailyAdyenSummaryExport.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(fetchDailyAdyenSummaryExport.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setColumns, resetDailyAdyenSummaryState } =
  dailyAdyenSummarySlice.actions;

export default dailyAdyenSummarySlice.reducer;
