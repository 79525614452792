import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { SALES_BY_MENU_ITEMS_SUMMARY_COLUMNS } from '../constants';
import {
  Categories,
  SalesByMenuItemSummaryDTO,
  SalesByMenuItemSummaryExtrasDTO,
  TotalCountsForSalesByMenuItems,
} from '../types/reports.types';
import * as requestTypes from './reportsActionTypes';

export interface SalesByMenuItemSummaryState {
  status: string;
  refreshing: boolean;
  columns: IAppTableColumn[];
  salesByMenuItemData: SalesByMenuItemSummaryDTO[];
  exportStatus: string;
  orderCounts: TotalCountsForSalesByMenuItems;
  categories: Categories[];
  selectedCategory: string[];
}

const initialState: SalesByMenuItemSummaryState = {
  status: 'idle',
  refreshing: false,
  columns: SALES_BY_MENU_ITEMS_SUMMARY_COLUMNS,
  salesByMenuItemData: [],
  exportStatus: 'idle',
  orderCounts: {
    totalSales: '0',
    totalItems: '0',
  },
  categories: [],
  selectedCategory: [],
};

interface SalesByMenuItemSummaryProp {
  date_from?: string;
  date_to?: string;
  categories?: string;
  offset?: string;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: SalesByMenuItemSummaryProp,
): Promise<
  PaginatedResponseDTO<
    SalesByMenuItemSummaryDTO,
    SalesByMenuItemSummaryExtrasDTO
  >
> => {
  const url = URLS.REPORTS.SALES_BY_MENU_SUMMARY;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<
        SalesByMenuItemSummaryDTO,
        SalesByMenuItemSummaryExtrasDTO
      >
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchSalesByMenuItemSummary = createAppAsyncThunk<
  PaginatedResponseDTO<
    SalesByMenuItemSummaryDTO,
    SalesByMenuItemSummaryExtrasDTO
  >,
  SalesByMenuItemSummaryProp
>(
  requestTypes.REQUEST_SALES_BY_MENU_ITEM_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const fetchSalesByMenuItemSummaryExport = createAppAsyncThunk<
  PaginatedResponseDTO<
    SalesByMenuItemSummaryDTO,
    SalesByMenuItemSummaryExtrasDTO
  >,
  SalesByMenuItemSummaryProp
>(
  requestTypes.REQUEST_SALES_BY_MENU_ITEM_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const salesByMenuItemSummary = createSlice({
  name: 'salesByMenuItemSummary',
  initialState,
  reducers: {
    setColumns: (
      state: SalesByMenuItemSummaryState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
    setSelectedCategory: (
      state: SalesByMenuItemSummaryState,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedCategory = action.payload;
    },
    resetSalesByMenuItemSummaryReportsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSalesByMenuItemSummary.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchSalesByMenuItemSummary.fulfilled, (state, action) => {
        state.salesByMenuItemData = action.payload.results;
        state.orderCounts = action.payload.extras.totalCounts;
        state.categories = action.payload.extras.categories;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchSalesByMenuItemSummary.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchSalesByMenuItemSummaryExport.pending, (state) => {
        state.exportStatus = 'loading';
      })
      .addCase(fetchSalesByMenuItemSummaryExport.fulfilled, (state) => {
        state.exportStatus = 'success';
      })
      .addCase(fetchSalesByMenuItemSummaryExport.rejected, (state) => {
        state.exportStatus = 'failed';
      });
  },
});

export const {
  setColumns,
  resetSalesByMenuItemSummaryReportsState,
  setSelectedCategory,
} = salesByMenuItemSummary.actions;

export const { reducer: salesByMenuItemReducer } = salesByMenuItemSummary;

export default salesByMenuItemSummary.reducer;
