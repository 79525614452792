export const REQUEST_ADDONS = 'ADDONS/REQUEST_ADDONS';
export const REQUEST_SINGLE_ADDON = 'ADDONS/REQUEST_SINGLE_ADDON';
export const REQUEST_TO_CREATE_ADDONS = 'ADDONS/REQUEST_TO_CREATE_ADDONS';
export const REQUEST_TO_UPDATE_ADDONS = 'ADDONS/REQUEST_TO_UPDATE_ADDONS';
export const REQUEST_TO_PATCH_UPDATE_ADDONS =
  'ADDONS/REQUEST_TO_PATCH_UPDATE_ADDONS';
export const REQUEST_TO_DELETE_ADDONS = 'ADDONS/REQUEST_TO_DELETE_ADDONS';
export const REQUEST_TO_UPDATE_ADDONS_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_ADDONS_SEQUENCE_NUMBER';
export const REQUEST_TO_UPDATE_ADDONS_ITEMS_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_ADDONS_ITEMS_SEQUENCE_NUMBER';
