import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { ORDER_TYPE, URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  GetOrderTypesQueryDTO,
  OrderTypesResponseDTO,
  OrderTypesState,
  UpdateDefaultOrderTypeRequestBodyDTO,
  UpdateOrderTypesRequestBodyDTO,
} from '../types/order-types.types';
import * as orderTypesActionTypes from './orderTypesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchOrderTypes = createAsyncThunk<
  OrderTypesResponseDTO,
  GetOrderTypesQueryDTO
>(
  orderTypesActionTypes.REQUEST_ORDER_TYPES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<CommonResponseDTO<OrderTypesResponseDTO>>(
        URLS.ORDER_TYPES,
        {
          params,
        },
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateOrderTypes = createAsyncThunk<
  OrderTypesResponseDTO,
  UpdateOrderTypesRequestBodyDTO
>(
  orderTypesActionTypes.UPDATE_ORDER_TYPES,
  async (data: UpdateOrderTypesRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<OrderTypesResponseDTO>>(
        URLS.ORDER_TYPES,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateDefaultOrderType = createAsyncThunk<
  OrderTypesResponseDTO,
  UpdateDefaultOrderTypeRequestBodyDTO
>(
  orderTypesActionTypes.SET_DEFAULT_ORDER_TYPE,
  async (data: UpdateDefaultOrderTypeRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<OrderTypesResponseDTO>>(
        `${URLS.ORDER_TYPES}/default`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

const orderTypesInitialState: OrderTypesState = {
  orderTypesData: {
    id: 0,
    resId: 0,
    defaultOrderType: ORDER_TYPE.DINEIN,
    enabledOrderTypes: [],
  },
  refreshing: false,
};

export const orderTypesSlice = createSlice({
  name: 'orderTypes',
  initialState: orderTypesInitialState,
  reducers: { resetOrderTypesState: () => orderTypesInitialState },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderTypes.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchOrderTypes.fulfilled, (state, action) => {
        state.orderTypesData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchOrderTypes.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateOrderTypes.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateOrderTypes.fulfilled, (state, action) => {
        state.orderTypesData = action.payload;
        state.refreshing = false;
      })
      .addCase(updateOrderTypes.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateDefaultOrderType.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateDefaultOrderType.fulfilled, (state, action) => {
        state.orderTypesData = action.payload;
        state.refreshing = false;
      })
      .addCase(updateDefaultOrderType.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetOrderTypesState } = orderTypesSlice.actions;

export default orderTypesSlice.reducer;
