import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';

import { SNACKBARTYPE } from '../../../components/AppSnackbar/constants';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  DevicesRequestBodyDTO,
  DevicesState,
  IDevicesResponseDTO,
} from '../types/devices.types';
import * as posDevicesTypes from './posDevicesActionTypes';
import { fetchTicketConfigs } from './ticketsSlice';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchDevicesData = createAsyncThunk<
  { devicesData: IDevicesResponseDTO[]; saveTicketStatus: boolean },
  void,
  { rejectValue: string }
>(
  posDevicesTypes.REQUEST_POS_DEVICES,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.get<CommonResponseDTO<IDevicesResponseDTO[]>>(
        URLS.DEVICES,
      );

      if (response?.data?.data && response.data.data.length > 0) {
        const saveTicketStatus = (
          await dispatch(
            fetchTicketConfigs(response.data.data[0].resId),
          ).unwrap()
        ).saveTickets;

        return {
          devicesData: response.data.data,
          saveTicketStatus: saveTicketStatus,
        };
      } else {
        return {
          devicesData: [],
          saveTicketStatus: false,
        };
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createPosDevicesData = createAppAsyncThunk<
  IDevicesResponseDTO,
  DevicesRequestBodyDTO
>(
  posDevicesTypes.REQUEST_TO_CREATE_POS_DEVICES,
  async (data: DevicesRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<IDevicesResponseDTO>>(
        URLS.DEVICES,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteDevicesData = createAppAsyncThunk<string, string>(
  posDevicesTypes.REQUEST_TO_DELETE_POS_DEVICES,
  async (id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.DEVICES}/${id}`);
      return id;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'Cannot delete while there are pending Open tables',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleDevice = createAppAsyncThunk<IDevicesResponseDTO, string>(
  posDevicesTypes.REQUEST_SINGLE_POS_DEVICE,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.DEVICES}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<IDevicesResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateDevicesData = createAppAsyncThunk<
  IDevicesResponseDTO,
  { id: string; data: Partial<DevicesRequestBodyDTO> }
>(
  posDevicesTypes.REQUEST_TO_PATCH_UPDATE_DEVICES,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<CommonResponseDTO<IDevicesResponseDTO>>(
        `${URLS.DEVICES}/${id}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'Device cannot be deactivated while there are pending Open tables.',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      } else if (
        error instanceof AxiosError &&
        error?.response?.status === HttpStatusCode.Forbidden
      ) {
        openGlobalSnackbar(
          'There is an open shift for this device. If you make it a secondary device, that shift could not close.',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const resetDeviceLogin = createAppAsyncThunk<
  IDevicesResponseDTO,
  string
>(posDevicesTypes.REQUEST_TO_RESET_LOG_IN, async (id, { rejectWithValue }) => {
  try {
    const response = await Api.patch<CommonResponseDTO<IDevicesResponseDTO>>(
      `${URLS.DEVICES}/${id}/reset-login`,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    return rejectWithValue('An error occurred');
  }
});

export const deletePendingTickets = createAppAsyncThunk<
  IDevicesResponseDTO,
  string
>(
  posDevicesTypes.REQUEST_TO_DELETE_PENDING_TICKETS,
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete<CommonResponseDTO<IDevicesResponseDTO>>(
        `${URLS.DEVICES}/${id}/pending-tickets`,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const resetDevicePassword = createAppAsyncThunk<
  IDevicesResponseDTO,
  { id: string; data: { password: string } }
>(
  posDevicesTypes.REQUEST_TO_RESET_DEVICE_PASSWORD,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<CommonResponseDTO<IDevicesResponseDTO>>(
        `${URLS.DEVICES}/${id}/reset-password`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const DevicesInitialState: DevicesState = {
  devicesData: [],
  refreshing: false,
  loadingSingleDevice: false,
  saveTicketStatus: false,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: DevicesInitialState,
  reducers: {
    resetDevicesState: () => DevicesInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevicesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchDevicesData.fulfilled, (state, action) => {
        state.devicesData = action.payload.devicesData;
        state.saveTicketStatus = action.payload.saveTicketStatus;
        state.refreshing = false;
      })
      .addCase(fetchDevicesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createPosDevicesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createPosDevicesData.fulfilled, (state, action) => {
        state.devicesData.push(action.payload);
        state.refreshing = false;
      })
      .addCase(createPosDevicesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteDevicesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteDevicesData.fulfilled, (state, action) => {
        const index = state.devicesData.findIndex(
          (posDevice) => posDevice._id === action.payload,
        );
        if (index !== -1) {
          state.devicesData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteDevicesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleDevice.pending, (state) => {
        state.loadingSingleDevice = true;
      })
      .addCase(getSingleDevice.fulfilled, (state) => {
        state.loadingSingleDevice = false;
      })
      .addCase(getSingleDevice.rejected, (state) => {
        state.loadingSingleDevice = false;
      })
      .addCase(patchUpdateDevicesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateDevicesData.fulfilled, (state, action) => {
        const updatedDevice = action.payload;
        const index = state.devicesData.findIndex(
          (posDevice) => posDevice.id === updatedDevice.id,
        );
        if (index !== -1) {
          state.devicesData[index] = {
            ...state.devicesData[index],
            ...updatedDevice,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateDevicesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(resetDeviceLogin.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(resetDeviceLogin.fulfilled, (state, action) => {
        const updatedDevice = action.payload;
        const index = state.devicesData.findIndex(
          (posDevice) => posDevice.id === updatedDevice.id,
        );
        if (index !== -1) {
          state.devicesData[index] = {
            ...state.devicesData[index],
            ...updatedDevice,
          };
        }
        state.refreshing = false;
      })
      .addCase(resetDeviceLogin.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deletePendingTickets.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deletePendingTickets.fulfilled, (state, action) => {
        const updatedDevice = action.payload;
        const index = state.devicesData.findIndex(
          (posDevice) => posDevice.id === updatedDevice.id,
        );
        if (index !== -1) {
          state.devicesData[index] = {
            ...state.devicesData[index],
            ...updatedDevice,
          };
        }
        state.refreshing = false;
      })
      .addCase(deletePendingTickets.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(resetDevicePassword.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(resetDevicePassword.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(resetDevicePassword.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetDevicesState } = devicesSlice.actions;

export default devicesSlice.reducer;
