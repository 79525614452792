import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar/constants';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import {
  CreateAreasRequestBodyDTO,
  IRestaurantAreasResponseDTO,
  RestaurantAreasState,
} from '../types/restaurant-areas.types';
import * as restaurantAreasTypes from './restaurantAreasActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchRestaurantAreasData = createAsyncThunk<
  IRestaurantAreasResponseDTO[],
  void
>(
  restaurantAreasTypes.REQUEST_RESTAURANT_AREAS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<IRestaurantAreasResponseDTO[]>
      >(URLS.RESTAURANT_AREAS);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createRestaurantAreasData = createAppAsyncThunk<
  IRestaurantAreasResponseDTO,
  CreateAreasRequestBodyDTO
>(
  restaurantAreasTypes.REQUEST_TO_CREATE_RESTAURANT_AREAS,
  async (data: CreateAreasRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<IRestaurantAreasResponseDTO>
      >(URLS.RESTAURANT_AREAS, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleRestaurantArea = createAppAsyncThunk<
  IRestaurantAreasResponseDTO,
  string
>(
  restaurantAreasTypes.REQUEST_SINGLE_RESTAURANT_AREA,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.RESTAURANT_AREAS}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<IRestaurantAreasResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateRestaurantAreasData = createAppAsyncThunk<
  IRestaurantAreasResponseDTO,
  { id: string; data: Partial<CreateAreasRequestBodyDTO> }
>(
  restaurantAreasTypes.REQUEST_TO_PATCH_UPDATE_RESTAURANT_AREAS,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IRestaurantAreasResponseDTO>
      >(`${URLS.RESTAURANT_AREAS}/${id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updatedRestaurantAreaSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  restaurantAreasTypes.REQUEST_TO_UPDATE_RESTAURANT_AREAS_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const restaurantAreaData = [
        ...cloneDeep(getState().restaurantAreas.restaurantAreasData),
      ];

      const reorderData = reorder<IRestaurantAreasResponseDTO>({
        list: restaurantAreaData,
        dragId,
        dropId,
        id: true,
      });

      if (!reorderData) return;

      const {
        list: newRestaurantAreaData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setRestaurantAreaState(newRestaurantAreaData));

      const response = await Api.patch<
        CommonResponseDTO<IRestaurantAreasResponseDTO>
      >(`${URLS.RESTAURANT_AREAS}/${dragId}`, {
        sequenceNumber: newRestaurantAreaData[dropItemIndex].sequenceNumber,
      });

      if (response.status !== 200) {
        newRestaurantAreaData[dragItemIndex].sequenceNumber =
          dragSequenceNumber;
        newRestaurantAreaData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setRestaurantAreaState(newRestaurantAreaData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const RestaurantAreasInitialState: RestaurantAreasState = {
  restaurantAreasData: [],
  refreshing: false,
  loadingSingleRestaurantArea: false,
  assignedTables: [],
};

export const restaurantAreasSlice = createSlice({
  name: 'restaurantAreas',
  initialState: RestaurantAreasInitialState,
  reducers: {
    resetRestaurantAreasState: () => RestaurantAreasInitialState,
    resetRestaurantAreasEditingDataState: (state) => {
      state.editingData = undefined;
      state.assignedTables = [];
    },
    setAssignedTables: (state, action: PayloadAction<string[]>) => {
      state.assignedTables = action.payload;
    },
    setRestaurantAreaState: (
      state,
      action: { payload: IRestaurantAreasResponseDTO[] },
    ) => {
      state.restaurantAreasData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestaurantAreasData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchRestaurantAreasData.fulfilled, (state, action) => {
        state.restaurantAreasData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchRestaurantAreasData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleRestaurantArea.pending, (state) => {
        state.loadingSingleRestaurantArea = true;
      })
      .addCase(getSingleRestaurantArea.fulfilled, (state, action) => {
        state.editingData = action.payload;
        state.loadingSingleRestaurantArea = false;
      })
      .addCase(getSingleRestaurantArea.rejected, (state) => {
        state.loadingSingleRestaurantArea = false;
      })
      .addCase(createRestaurantAreasData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createRestaurantAreasData.fulfilled, (state, action) => {
        state.restaurantAreasData.push(action.payload);
        state.refreshing = false;
      })
      .addCase(createRestaurantAreasData.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const {
  resetRestaurantAreasState,
  resetRestaurantAreasEditingDataState,
  setAssignedTables,
  setRestaurantAreaState,
} = restaurantAreasSlice.actions;

export default restaurantAreasSlice.reducer;
