export const REQUEST_TICKETS_CONFIG = 'TICKETS/REQUEST_TICKETS_CONFIG';
export const REQUEST_TICKETS = 'TICKETS/REQUEST_TICKETS';
export const REQUEST_TOGGLE_SAVE_TICKETS =
  'TICKETS/REQUEST_TOGGLE_SAVE_TICKETS';
export const REQUEST_TOGGLE_PREDEFINED_TICKETS =
  'TICKETS/REQUEST_TOGGLE_PREDEFINED_TICKETS';
export const REQUEST_CREATE_TICKET = 'TICKETS/REQUEST_CREATE_TICKET';
export const REQUEST_SINGLE_TICKET = 'TICKETS/REQUEST_SINGLE_TICKET';
export const REQUEST_TO_UPDATE_TICKET = 'TICKETS/REQUEST_TO_UPDATE_TICKET';
export const REQUEST_TO_DELETE_TICKET = 'TICKETS/REQUEST_TO_DELETE_TICKET';
export const REQUEST_TO_CLEAR_TICKET = 'TICKETS/REQUEST_TO_CLEAR_TICKET';
export const REQUEST_TO_CLEAR_ALL_TICKETS =
  'TICKETS/REQUEST_TO_CLEAR_ALL_TICKETS';
export const REQUEST_TO_UPDATE_TICKET_SEQUENCE_NUMBER =
  'TICKETS/REQUEST_TO_UPDATE_TICKET_SEQUENCE_NUMBER';
export const REQUEST_TOGGLE_SHIFT_CLOSE = 'TICKETS/REQUEST_TOGGLE_SHIFT_CLOSE';
