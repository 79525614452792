import { createSlice } from '@reduxjs/toolkit';

import { DiscountsPaginationState } from '../types/discounts-pagination.types';

export const discountsPaginationInitialState: DiscountsPaginationState = {
  limit: 10,
  skip: 0,
  search_key: '',
};

export const discountsPaginationSlice = createSlice({
  name: 'discountsPagination',
  initialState: discountsPaginationInitialState,
  reducers: {
    resetDiscountsPaginationState: () => discountsPaginationInitialState,
    setDiscountsPaginationLimit: (state, action) => {
      state.limit = action.payload;
    },
    setDiscountsPaginationSkip: (state, action) => {
      state.skip = action.payload;
    },
    setDiscountsPaginationSearchKey: (
      state,
      action: {
        payload: string | undefined;
      },
    ) => {
      state.search_key = action?.payload?.trim();
    },
  },
});

export const {
  resetDiscountsPaginationState,
  setDiscountsPaginationLimit,
  setDiscountsPaginationSkip,
  setDiscountsPaginationSearchKey,
} = discountsPaginationSlice.actions;

export default discountsPaginationSlice.reducer;
