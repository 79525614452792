import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export const ACTIVE_ORDERS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'orderDateTime',
    header: 'DATE AND TIME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'serviceType',
    header: 'ORDER TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'id',
    header: 'ORDER ID',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'placementType',
    header: 'PLACEMENT',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'paymentMethod',
    header: 'PAYMENT',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'foodCost',
    header: 'FOOD COST',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'foodDiscount',
    header: 'FOOD DISCOUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },

  {
    accessorKey: 'subTotal',
    header: 'SUB TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'serviceCharge',
    header: 'SERVICE CHARGE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'deliveryDiscount',
    header: 'DELIVERY DISCOUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'gst',
    header: 'GST',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'totalIncome',
    header: 'TOTAL INCOME',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'INCOME',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'deliveryCharges',
    header: 'DELIVERY CHARGES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'orderFees',
    header: 'ORDER FEE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'transactionPercentage',
    header: 'TRANSACTION FEE %',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'transactionFee',
    header: 'TRANSACTION FEE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'cardCountry',
    header: 'CARD COUNTRY',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'cardType',
    header: 'CARD TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'totalCharges',
    header: 'TOTAL CHARGES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'CHARGES',
    visible: true,
    visibilityChangeable: true,
  },

  {
    accessorKey: 'totalRefunds',
    header: 'TOTAL REFUND',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'TOTAL',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'totalEarnings',
    header: 'TOTAL EARNINGS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'TOTAL',
    visible: true,
    visibilityChangeable: true,
  },
];
