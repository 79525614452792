import {
  APP_LIST_ALIGNMENT,
  IAppListColumn,
} from '../../../components/AppList/types/list.types';
import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export * from './tabs.constants';

export enum N_ACTION_KEYS {
  PAUSE = 'PAUSE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum GENERAL_SETTINGS_VIEW {
  STRIPE_POS = 'STRIPE_POS',
  STRIPE_PAYMENTS = 'STRIPE_PAYMENTS',
  ADYEN_PAYMENTS = 'ADYEN_PAYMENTS',
  LOADING = 'LOADING',
}

export const NOTIFICATIONS_CATEGORIES = [
  { name: 'Refunds', value: 'REFUNDS' },
  // TODO - temp disabled until shift function implement
  // { name: 'Shift Open', value: 'SHIFT_OPEN' },
  { name: 'Shift Close', value: 'SHIFT_CLOSE' },
];

export const TICKET_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: '',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      pl: 3,
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DEVICES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'type',
    header: 'TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'serialNo',
    header: 'SERIAL NO',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'handedOverAt',
    header: 'HANDED OVER',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'lastAccessed',
    header: 'LAST ACCESS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'lastTransactionAt',
    header: 'LAST TRANSACTION',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DEFAULT_PAYMENT_TYPES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'type',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const CUSTOM_PAYMENT_TYPES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'paymentType',
    header: 'PAYMENT TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const RESTAURANT_AREAS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'tables',
    header: 'ASSIGNED TABLES',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'defaultOrderType',
    header: 'DEFAULT ORDER TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'last_updated',
    header: 'LAST UPDATED',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const AREA_CREATION_TICKET_LIST_COLUMNS: IAppListColumn[] = [
  {
    accessorKey: 'name',
    header: '',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_LIST_ALIGNMENT.CENTER,
    bodyAlign: APP_LIST_ALIGNMENT.CENTER,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
];

export const NOTIFICATIONS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'email',
    header: 'EMAIL',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'categories',
    header: 'NOTIFICATIONS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'NOTIFICATION STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const PAYOUT_SCHEDULES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'accountNumber',
    header: 'RECIPIENT ACCOUNT',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'schedule',
    header: 'SCHEDULE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const BANK_ACCOUNTS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'accountNumber',
    header: 'ACCOUNT NUMBER',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];
