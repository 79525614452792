import { ENVIRONMENTS } from '../constants';

interface IEnvironment {
  isDebug: boolean;
  sentryEnabled: boolean;
  mixpanelEnabled: boolean;
  env: ENVIRONMENTS;
  version: string;
  mixpanelToken: string;
  baseURL: string;
  muiLicenseKey: string;
}

const Environment: IEnvironment = {
  isDebug: import.meta.env.MODE !== ENVIRONMENTS.PRODUCTION,
  sentryEnabled:
    import.meta.env.VITE_APP_ENVIRONMENT === ENVIRONMENTS.QA ||
    import.meta.env.VITE_APP_ENVIRONMENT === ENVIRONMENTS.PRODUCTION,
  mixpanelEnabled: import.meta.env.VITE_APP_ENVIRONMENT !== ENVIRONMENTS.LOCAL,
  env: import.meta.env.VITE_APP_ENVIRONMENT as ENVIRONMENTS,
  version: import.meta.env.PACKAGE_VERSION,
  mixpanelToken: import.meta.env.VITE_APP_MIXPANEL as string,
  baseURL: import.meta.env.VITE_APP_BASEURL as string,
  muiLicenseKey: import.meta.env.VITE_APP_MUI_LICENSE_KEY as string,
};

export default Environment;
