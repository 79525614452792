export const REQUEST_SHIFT_SUMMARY_STATUS =
  'SHIFT_SUMMARY/REQUEST_SHIFT_SUMMARY_STATUS';

export const REQUEST_SHIFT_SUMMARY = 'SHIFT_SUMMARY/REQUEST_SHIFT_SUMMARY';
export const REQUEST_SHIFT_SUMMARY_EXPORT =
  'SHIFT_SUMMARY/REQUEST_SHIFT_SUMMARY_EXPORT';

export const REQUEST_SINGLE_SHIFT_SUMMARY =
  'SHIFT_SUMMARY/REQUEST_SINGLE_SHIFT_SUMMARY';

export const REQUEST_SINGLE_SHIFT_SUMMARY_EXPORT =
  'SHIFT_SUMMARY/REQUEST_SINGLE_SHIFT_SUMMARY_EXPORT';

export const REQUEST_CONSOLIDATED_SHIFT_SUMMARY =
  'SHIFT_SUMMARY/REQUEST_CONSOLIDATED_SHIFT_SUMMARY';

export const REQUEST_CONSOLIDATED_SHIFT_SUMMARY_EXPORT =
  'SHIFT_SUMMARY/REQUEST_CONSOLIDATED_SHIFT_SUMMARY_EXPORT';
