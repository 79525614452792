import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import mixpanel from 'mixpanel-browser';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { resetReportLastUpdateState } from '../../../redux/AppSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import { resetAddOnsState } from '../../menu/redux/addOnGroupSlice';
import { resetCategoriesState } from '../../menu/redux/categoriesSlice';
import { resetChoicesState } from '../../menu/redux/choiceGroupSlice';
import { resetDishesState } from '../../menu/redux/dishesSlice';
import { resetMenuPaginationState } from '../../menu/redux/menuPaginationSlice';
import { resetSubCategoryState } from '../../menu/redux/subCategoriesSlice';
import { resetOrderState } from '../../orders/redux/ordersSlice';
import { resetPaymentState } from '../../payments/redux/paymentsSlice';
import { resetReconciliationSummaryState } from '../../reports/redux/reconciliationSummarySlices';
import {
  ChangeRestaurantRequestDTO,
  ForgotPasswordRequestDTO,
  ForgotPasswordResponseDTO,
  LoginRequestDTO,
  LoginResponseDTO,
  OTPVerificationRequestDTO,
  OTPVerificationResponseDTO,
  ProfileResponseDTO,
  RegisterEmployeeRequestBodyDTO,
  RegisterEmployeeResponseBodyDTO,
  ResetPasswordRequestDTO,
  RestaurantResponseDTO,
  TokenVerificationResponseDTO,
} from '../types/auth.types';
import {
  REQUEST_CHANGE_RESTAURANT,
  REQUEST_FORGOT_PASSWORD,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_OTP_VERIFICATION,
  REQUEST_PASSWORD_RESET,
  REQUEST_PROFILE,
  REQUEST_REGISTER,
  REQUEST_RESTAURANT,
  REQUEST_TOKEN_VERIFICATION,
} from './authActionTypes';
import { setTag, setUser } from '@sentry/react';
import { resetDiscountsState } from '../../discounts/redux/discountsSlice';
import { resetDiscountsPaginationState } from '../../discounts/redux/discountsPaginationSlice';
import { resetDiscountReasonsState } from '../../discounts/redux/discountReasonsSlice';
import { resetEmployeesState } from '../../employee/redux/employeeSlice';
import { resetEmployeePaginationState } from '../../employee/redux/employeePaginationSlice';
import { resetPagesState } from '../../menu/redux/pagesSlice';
import { resetHourlySalesSummaryReportsState } from '../../reports/redux/hourlySalesSummarySlice';
import { resetOrderCountSummaryReportsState } from '../../reports/redux/orderCountSummarySlice';
import { resetReceiptsState } from '../../reports/redux/receiptsSlice';
import { resetReportPaginationState } from '../../reports/redux/reportPaginationSlice';
import { resetSalesSummaryState } from '../../reports/redux/salesSummarySlice';
import { resetSalesByMenuItemSummaryReportsState } from '../../reports/redux/salesByMenuItemSummarySlice';
import { resetShiftSummaryRecordStatus } from '../../reports/redux/shiftSummarySlice';
import { resetAccountState } from '../../settings/redux/accountSlice';
import { resetDevicesState } from '../../settings/redux/devicesSlice';
import { resetGeneralSettingsState } from '../../settings/redux/generalSettingsSlice';
import { resetNotificationsState } from '../../settings/redux/notificationsSlice';
import { resetOrderTypesState } from '../../settings/redux/orderTypesSlice';
import { resetRestaurantAreasState } from '../../settings/redux/restaurantAreasSlice';
import { resetTaxesState } from '../../settings/redux/taxesSlice';
import { resetTicketsState } from '../../settings/redux/ticketsSlice';
import { resetTransactionChargeState } from '../../settings/redux/transactionChargesSlice';
import { resetReceiptState } from '../../settings/redux/receiptSlice';
import { resetPaymentTypesState } from '../../settings/redux/paymentTypesSlice';
import { resetAdyenSessionState } from './adyenSessionSlice';

export interface AuthState {
  token?: string;
  user?: ProfileResponseDTO;
  restaurant?: RestaurantResponseDTO;
  isLoadingLogin: boolean;
  isChangingRestaurant: boolean;
  isLoadingUser: boolean;
  isLoadingRestaurant: boolean;
  isLoadingTokenVerification: boolean;
  isLoadingForgotPassword: boolean;
  isLoadingOTPVerify: boolean;
  isLoadingResetPassword: boolean;
  passwordResetEmail: string | null;
  otpToken: string | null;
  resId?: number;
  isArchivedRestaurant: boolean;
}

const initialState: AuthState = {
  isLoadingLogin: false,
  isChangingRestaurant: false,
  isLoadingUser: false,
  isLoadingRestaurant: false,
  isLoadingTokenVerification: false,
  isLoadingForgotPassword: false,
  isLoadingOTPVerify: false,
  isLoadingResetPassword: false,
  passwordResetEmail: null,
  otpToken: null,
  resId: undefined,
  isArchivedRestaurant: false,
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const login = createAppAsyncThunk<LoginResponseDTO, LoginRequestDTO>(
  REQUEST_LOGIN,
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.post<CommonResponseDTO<LoginResponseDTO>>(
        URLS.AUTH.LOGIN,
        body,
      );

      localStorage.setItem('userToken', response.data.data.token);

      dispatch(setToken(response.data.data.token));
      await dispatch(getProfile());
      await dispatch(getRestaurant());

      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === HttpStatusCode.Unauthorized ||
          error.response?.status === HttpStatusCode.Forbidden)
      ) {
        return rejectWithValue(error.response?.data?.message);
      }

      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const changeRestaurant = createAppAsyncThunk<
  LoginResponseDTO,
  ChangeRestaurantRequestDTO
>(REQUEST_CHANGE_RESTAURANT, async (body, { rejectWithValue, dispatch }) => {
  try {
    const response = await Api.post<CommonResponseDTO<LoginResponseDTO>>(
      URLS.AUTH.CHANGE_RESTAURANT,
      body,
    );

    localStorage.setItem('userToken', response.data.data.token);

    try {
      await dispatch(getProfile()).unwrap();
      await dispatch(getRestaurant()).unwrap();
    } catch (error) {
      // If profile or restaurant fetch fails, we should reject
      SentryCaptureError(error);
      openGlobalSnackbar(
        'Failed to load profile or restaurant data',
        SNACKBARTYPE.ERROR,
      );
      return rejectWithValue('Failed to load profile or restaurant data');
    }

    return response.data.data;
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.Unauthorized
    ) {
      return rejectWithValue(error.response?.data?.message);
    }

    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const getProfile = createAppAsyncThunk<ProfileResponseDTO, void>(
  REQUEST_PROFILE,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth;

      if (!token) {
        return rejectWithValue('no token');
      }

      const response = await Api.get<CommonResponseDTO<ProfileResponseDTO>>(
        URLS.AUTH.PROFILE,
      );

      mixpanel.identify(response.data.data.id.toString());

      setUser({
        email: response.data.data.email,
        id: response.data.data.id,
        username: response.data.data.name,
        segment: response.data.data.role,
      });

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const getRestaurant = createAppAsyncThunk<RestaurantResponseDTO, void>(
  REQUEST_RESTAURANT,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth;

      if (!token) {
        return rejectWithValue('no token');
      }

      const response = await Api.get<CommonResponseDTO<RestaurantResponseDTO>>(
        URLS.AUTH.RESTAURANT,
      );

      setTag('resId', response.data.data.id);
      setTag('resName', response.data.data.name);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Failed to load restaurant data', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const verifyToken = createAppAsyncThunk<
  TokenVerificationResponseDTO,
  string
>(REQUEST_TOKEN_VERIFICATION, async (token, { rejectWithValue }) => {
  try {
    const response = await Api.post<
      CommonResponseDTO<TokenVerificationResponseDTO>
    >(URLS.AUTH.TOKEN_VERIFICATION, { token });

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    return rejectWithValue('something went wrong');
  }
});

export const register = createAppAsyncThunk<
  RegisterEmployeeResponseBodyDTO,
  RegisterEmployeeRequestBodyDTO
>(REQUEST_REGISTER, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<
      CommonResponseDTO<RegisterEmployeeResponseBodyDTO>
    >(URLS.AUTH.REGISTER, body);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

export const logout = createAppAsyncThunk(
  REQUEST_LOGOUT,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      // discounts
      dispatch(resetDiscountsPaginationState());
      dispatch(resetDiscountsState());
      dispatch(resetDiscountReasonsState());

      // employee
      dispatch(resetEmployeesState());
      dispatch(resetEmployeePaginationState());

      // menu
      dispatch(resetPagesState());
      dispatch(resetAddOnsState());
      dispatch(resetCategoriesState());
      dispatch(resetChoicesState());
      dispatch(resetDishesState());
      dispatch(resetSubCategoryState());
      dispatch(resetMenuPaginationState());

      // orders
      dispatch(resetOrderState());

      // payments
      dispatch(resetPaymentState());

      // reports
      dispatch(resetHourlySalesSummaryReportsState());
      dispatch(resetOrderCountSummaryReportsState());
      dispatch(resetReceiptsState());
      dispatch(resetReconciliationSummaryState());
      dispatch(resetReportPaginationState());
      dispatch(resetSalesByMenuItemSummaryReportsState());
      dispatch(resetSalesSummaryState());
      dispatch(resetShiftSummaryRecordStatus());

      // settings
      dispatch(resetAccountState());
      dispatch(resetDevicesState());
      dispatch(resetGeneralSettingsState());
      dispatch(resetNotificationsState());
      dispatch(resetOrderTypesState());
      dispatch(resetPaymentTypesState());
      dispatch(resetReceiptState());
      dispatch(resetRestaurantAreasState());
      dispatch(resetTaxesState());
      dispatch(resetTicketsState());
      dispatch(resetTransactionChargeState());

      // auth
      dispatch(resetAuthState());
      dispatch(resetAdyenSessionState());

      // app
      dispatch(resetReportLastUpdateState());
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const forgotPassword = createAppAsyncThunk<
  ForgotPasswordResponseDTO,
  ForgotPasswordRequestDTO
>(REQUEST_FORGOT_PASSWORD, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<
      CommonResponseDTO<ForgotPasswordResponseDTO>
    >(URLS.AUTH.FORGOT_PASSWORD, body);

    return response.data.data;
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.NotFound
    ) {
      openGlobalSnackbar('User not found', SNACKBARTYPE.ERROR);
      return rejectWithValue(error.response?.data?.message);
    }

    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const otpVerification = createAppAsyncThunk<
  OTPVerificationResponseDTO,
  OTPVerificationRequestDTO
>(REQUEST_OTP_VERIFICATION, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<
      CommonResponseDTO<OTPVerificationResponseDTO>
    >(URLS.AUTH.OTP_VERIFICATION, body);

    return response.data.data;
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.NotFound
    ) {
      openGlobalSnackbar('User not found', SNACKBARTYPE.ERROR);
      return rejectWithValue(error.response?.data?.message);
    }

    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.UnprocessableEntity
    ) {
      openGlobalSnackbar(
        'OTP is invalid, Please try again',
        SNACKBARTYPE.ERROR,
      );
      return rejectWithValue(error.response?.data?.message);
    }

    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.Unauthorized
    ) {
      openGlobalSnackbar(
        'OTP is expired, Please try again',
        SNACKBARTYPE.ERROR,
      );
      return rejectWithValue(error.response?.data?.message);
    }

    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const resetPassword = createAppAsyncThunk<void, ResetPasswordRequestDTO>(
  REQUEST_PASSWORD_RESET,
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<void>>(
        URLS.AUTH.RESET_PASSWORD,
        body,
      );

      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.Unauthorized
      ) {
        openGlobalSnackbar(
          'OTP authorization required, Please try again',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }

      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.RequestTimeout
      ) {
        openGlobalSnackbar(
          'OTP token expired, Please try again',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }

      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        openGlobalSnackbar('User not found', SNACKBARTYPE.ERROR);
        return rejectWithValue(error.response?.data?.message);
      }

      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'Token is invalid, Please try again',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }

      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearError: () => {
      return cloneDeep(initialState);
    },
    setResId: (state, action: PayloadAction<number | undefined>) => {
      state.resId = action.payload;
    },
    clearResId: (state) => {
      state.resId = undefined;
    },
    setIsChangingRestaurant: (state, action: PayloadAction<boolean>) => {
      state.isChangingRestaurant = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.isArchivedRestaurant = false;
        state.isLoadingLogin = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.isLoadingLogin = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoadingLogin = false;
      })
      .addCase(changeRestaurant.pending, (state) => {
        state.isArchivedRestaurant = false;
      })
      .addCase(changeRestaurant.fulfilled, (state, action) => {
        state.token = action.payload.token;
      })
      .addCase(getProfile.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoadingUser = false;
      })
      .addCase(getProfile.rejected, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(getRestaurant.pending, (state) => {
        state.isArchivedRestaurant = false;
        state.isLoadingRestaurant = true;
      })
      .addCase(getRestaurant.fulfilled, (state, action) => {
        state.restaurant = action.payload;
        state.isLoadingRestaurant = false;
      })
      .addCase(getRestaurant.rejected, (state) => {
        state.restaurant = undefined;
        state.isArchivedRestaurant = true;
        state.isLoadingRestaurant = false;
      })
      .addCase(verifyToken.pending, (state) => {
        state.isLoadingTokenVerification = true;
      })
      .addCase(verifyToken.fulfilled, (state) => {
        state.isLoadingTokenVerification = false;
      })
      .addCase(verifyToken.rejected, (state) => {
        state.isLoadingTokenVerification = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
        state.token = undefined;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoadingForgotPassword = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoadingForgotPassword = false;
        state.passwordResetEmail = action.payload.email;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoadingForgotPassword = false;
      })
      .addCase(otpVerification.pending, (state) => {
        state.isLoadingOTPVerify = true;
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.isLoadingOTPVerify = false;
        state.otpToken = action.payload.token;
      })
      .addCase(otpVerification.rejected, (state) => {
        state.isLoadingOTPVerify = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoadingResetPassword = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoadingResetPassword = false;
        state.passwordResetEmail = '';
        state.otpToken = '';
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoadingResetPassword = false;
      });
  },
});

export const {
  resetAuthState,
  clearError,
  setToken,
  setResId,
  clearResId,
  setIsChangingRestaurant,
} = authSlice.actions;

export default authSlice.reducer;
