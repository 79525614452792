import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { CHOICE_GROUPS_COLUMNS } from '../constants';
import {
  CreateChoiceGroupsRequestBodyDTO,
  GetAllChoicesQueryDTO,
  IChoice,
  IChoiceGroupsResponseDTO,
} from '../types/choice-groups.types';
import * as choiceGroupTypes from './choiceGroupsActionTypes';

export type ChoiceGroupsState = {
  choiceGroupsData: IChoiceGroupsResponseDTO[];
  extras: BasePaginationExtras;
  loadingSingleChoice: boolean;
  refreshing: boolean;
  columns: IAppTableColumn[];
  editingData?: IChoiceGroupsResponseDTO;
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchChoiceGroupData = createAppAsyncThunk<
  PaginatedResponseDTO<IChoiceGroupsResponseDTO, BasePaginationExtras>,
  Omit<GetAllChoicesQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(
  choiceGroupTypes.REQUEST_CHOICE_GROUPS,
  async (params, { rejectWithValue, getState }) => {
    const { limit, skip, search_key } = getState().menuPagination;
    try {
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<IChoiceGroupsResponseDTO, BasePaginationExtras>
        >
      >(URLS.CHOICE_GROUPS, {
        params: {
          ...params,
          limit,
          skip,
          search_key,
        },
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleChoiceGroup = createAppAsyncThunk<
  IChoiceGroupsResponseDTO,
  string
>(
  choiceGroupTypes.REQUEST_SINGLE_CHOICE_GROUP,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.CHOICE_GROUPS}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<IChoiceGroupsResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createChoiceGroupData = createAppAsyncThunk<
  IChoiceGroupsResponseDTO,
  CreateChoiceGroupsRequestBodyDTO
>(
  choiceGroupTypes.CREATE_CHOICE_GROUPS,
  async (data: CreateChoiceGroupsRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<IChoiceGroupsResponseDTO>
      >(URLS.CHOICE_GROUPS, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const putUpdateChoiceGroupData = createAppAsyncThunk<
  IChoiceGroupsResponseDTO,
  { _id: string; data: CreateChoiceGroupsRequestBodyDTO }
>(
  choiceGroupTypes.UPDATE_CHOICE_GROUPS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<
        CommonResponseDTO<IChoiceGroupsResponseDTO>
      >(`${URLS.CHOICE_GROUPS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateChoiceGroupData = createAppAsyncThunk<
  IChoiceGroupsResponseDTO,
  { _id: string; data: Partial<CreateChoiceGroupsRequestBodyDTO> }
>(
  choiceGroupTypes.PATCH_UPDATE_CHOICE_GROUPS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IChoiceGroupsResponseDTO>
      >(`${URLS.CHOICE_GROUPS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteChoiceGroupData = createAppAsyncThunk<string, string>(
  choiceGroupTypes.DELETE_CHOICE_GROUPS,
  async (_id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.CHOICE_GROUPS}/${_id}`);
      return _id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updatedChoiceSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  choiceGroupTypes.REQUEST_TO_UPDATE_CHOICE_GROUPS_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const choicesData = [
        ...cloneDeep(getState().choiceGroups.choiceGroupsData),
      ];

      const reorderData = reorder<IChoiceGroupsResponseDTO>({
        list: choicesData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newChoicesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setChoicesState(newChoicesData));

      const response = await Api.patch<
        CommonResponseDTO<IChoiceGroupsResponseDTO>
      >(`${URLS.CHOICE_GROUPS}/${dragId}`, {
        sequenceNumber: newChoicesData[dropItemIndex].sequenceNumber,
      });

      if (response.status !== 200) {
        newChoicesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newChoicesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setChoicesState(newChoicesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updatedChoiceItemsSequenceNumbers = createAppAsyncThunk<
  void,
  {
    dragId: string;
    dropId: string;
    items: IChoice[];
    name: string;
    id?: string;
  }
>(
  choiceGroupTypes.REQUEST_TO_UPDATE_CHOICE_ITEMS_SEQUENCE_NUMBER,
  async (
    { dragId, dropId, items, name, id },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const reorderData = reorder<IChoice>({
        list: items,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newChoicesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setChoiceItemsState(newChoicesData));

      const response = await Api.patch<
        CommonResponseDTO<IChoiceGroupsResponseDTO>
      >(`${URLS.CHOICE_GROUPS}/${id}`, {
        choices: [
          {
            _id: dragId,
            sequenceNumber: newChoicesData[dropItemIndex].sequenceNumber,
            name: name,
          },
        ],
      });

      if (response.status !== 200) {
        newChoicesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newChoicesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setChoiceItemsState(newChoicesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const choiceGroupsInitialState: ChoiceGroupsState = {
  choiceGroupsData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  loadingSingleChoice: false,
  columns: CHOICE_GROUPS_COLUMNS,
};

export const choiceGroupsSlice = createSlice({
  name: 'choiceGroups',
  initialState: choiceGroupsInitialState,
  reducers: {
    resetChoicesState: () => choiceGroupsInitialState,
    resetChoicesEditingDataState: (state) => {
      state.editingData = undefined;
    },
    setChoicesState: (
      state,
      action: { payload: IChoiceGroupsResponseDTO[] },
    ) => {
      state.choiceGroupsData = action.payload;
    },
    setChoiceItemsState: (state, action: { payload: IChoice[] }) => {
      if (state.editingData) {
        state.editingData.choices = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChoiceGroupData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchChoiceGroupData.fulfilled, (state, action) => {
        state.choiceGroupsData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchChoiceGroupData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createChoiceGroupData.pending, (state) => {
        state.loadingSingleChoice = true;
      })
      .addCase(createChoiceGroupData.fulfilled, (state) => {
        state.loadingSingleChoice = false;
      })
      .addCase(createChoiceGroupData.rejected, (state) => {
        state.loadingSingleChoice = false;
      })
      .addCase(putUpdateChoiceGroupData.pending, (state) => {
        state.loadingSingleChoice = true;
      })
      .addCase(putUpdateChoiceGroupData.fulfilled, (state, action) => {
        const updatedChoiceGroup = action.payload;
        const index = state.choiceGroupsData.findIndex(
          (choiceGroup) => choiceGroup._id === updatedChoiceGroup._id,
        );
        if (index !== -1) {
          state.choiceGroupsData[index] = updatedChoiceGroup;
        }
        state.loadingSingleChoice = false;
      })
      .addCase(putUpdateChoiceGroupData.rejected, (state) => {
        state.loadingSingleChoice = false;
      })
      .addCase(patchUpdateChoiceGroupData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateChoiceGroupData.fulfilled, (state, action) => {
        const updatedChoiceGroup = action.payload;
        const index = state.choiceGroupsData.findIndex(
          (choiceGroup) => choiceGroup._id === updatedChoiceGroup._id,
        );
        if (index !== -1) {
          state.choiceGroupsData[index] = {
            ...state.choiceGroupsData[index],
            ...updatedChoiceGroup,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateChoiceGroupData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteChoiceGroupData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteChoiceGroupData.fulfilled, (state, action) => {
        const index = state.choiceGroupsData.findIndex(
          (choiceGroup) => choiceGroup._id === action.payload,
        );
        if (index !== -1) {
          state.choiceGroupsData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteChoiceGroupData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleChoiceGroup.pending, (state) => {
        state.loadingSingleChoice = true;
      })
      .addCase(getSingleChoiceGroup.fulfilled, (state, action) => {
        state.loadingSingleChoice = false;
        state.editingData = action.payload;
      })
      .addCase(getSingleChoiceGroup.rejected, (state) => {
        state.loadingSingleChoice = false;
      });
  },
});

export const {
  resetChoicesState,
  resetChoicesEditingDataState,
  setChoicesState,
  setChoiceItemsState,
} = choiceGroupsSlice.actions;

export default choiceGroupsSlice.reducer;
