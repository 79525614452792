export const REQUEST_CHOICE_GROUPS = 'CHOICE_GROUPS/REQUEST_CHOICE_GROUPS';
export const REQUEST_SINGLE_CHOICE_GROUP =
  'CATEGORIES/REQUEST_SINGLE_CHOICE_GROUP';
export const CREATE_CHOICE_GROUPS = 'CATEGORIES/CREATE_CHOICE_GROUPS';
export const UPDATE_CHOICE_GROUPS = 'CATEGORIES/UPDATE_CHOICE_GROUPS';
export const PATCH_UPDATE_CHOICE_GROUPS =
  'CATEGORIES/PATCH_UPDATE_CHOICE_GROUPS';
export const DELETE_CHOICE_GROUPS = 'CATEGORIES/DELETE_CHOICE_GROUPS';
export const EDIT_CHOICE_GROUPS = 'CATEGORIES/EDIT_CHOICE_GROUPS';
export const REQUEST_TO_UPDATE_CHOICE_GROUPS_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_CHOICE_GROUPS_SEQUENCE_NUMBER';
export const REQUEST_TO_UPDATE_CHOICE_ITEMS_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_CHOICE_ITEMS_SEQUENCE_NUMBER';
