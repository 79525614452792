export const REQUEST_RECEIPTS = 'SETTINGS/RECEIPTS/REQUEST_RECEIPTS';
export const REQUEST_SINGLE_RECEIPT =
  'SETTINGS/RECEIPTS/REQUEST_SINGLE_RECEIPT';
export const REQUEST_TO_CREATE_RECEIPTS =
  'SETTINGS/RECEIPTS/REQUEST_TO_CREATE_RECEIPTS';
export const REQUEST_TO_UPDATE_RECEIPTS =
  'SETTINGS/RECEIPTS/REQUEST_TO_UPDATE_RECEIPTS';
export const REQUEST_TO_PATCH_UPDATE_RECEIPTS =
  'SETTINGS/RECEIPTS/REQUEST_TO_PATCH_UPDATE_RECEIPTS';
export const REQUEST_TO_DELETE_RECEIPTS =
  'SETTINGS/RECEIPTS/REQUEST_TO_DELETE_RECEIPTS';
