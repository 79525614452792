export const REQUEST_GENERAL_SETTINGS = 'RECEIPTS/REQUEST_GENERAL_SETTINGS';
export const REQUEST_TO_PATCH_UPDATE_GENERAL_SETTINGS =
  'RECEIPTS/REQUEST_TO_PATCH_UPDATE_GENERAL_SETTINGS';
export const REQUEST_TO_TOGGLE_SHIFT_MANAGEMENT_ACCESS =
  'RECEIPTS/REQUEST_TO_TOGGLE_SHIFT_MANAGEMENT_ACCESS';
export const REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS =
  'RECEIPTS/REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS';
export const REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS_MANDATORY =
  'RECEIPTS/REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS_MANDATORY';
export const REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION =
  'RECEIPTS/REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION';
export const REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION_MANDATORY =
  'RECEIPTS/REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION_MANDATORY';
