import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  CreateDiscountReasonRequestBodyDTO,
  DiscountReasonsState,
  IDiscountReasonsResponseDTO,
  UpdateDiscountReasonRequestBodyDTO,
} from '../types/discount-reasons.types';
import { GetAllDiscountsRequestQueryDTO } from '../types/discounts.types';
import * as discountReasonTypes from './discountReasons.ActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchDiscountReasonsData = createAppAsyncThunk<
  PaginatedResponseDTO<IDiscountReasonsResponseDTO, BasePaginationExtras>,
  | Omit<GetAllDiscountsRequestQueryDTO, 'limit' | 'skip' | 'search_key'>
  | undefined
>(
  discountReasonTypes.REQUEST_DISCOUNT_REASONS,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip, search_key } = getState().discountsPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<
            IDiscountReasonsResponseDTO,
            BasePaginationExtras
          >
        >
      >(URLS.DISCOUNT_REASONS, {
        params: {
          ...params,
          limit,
          skip,
          search_key,
        },
      });

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createDiscountReasonData = createAppAsyncThunk<
  IDiscountReasonsResponseDTO,
  CreateDiscountReasonRequestBodyDTO
>(
  discountReasonTypes.REQUEST_TO_CREATE_DISCOUNT_REASONS,
  async (data: CreateDiscountReasonRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<IDiscountReasonsResponseDTO>
      >(URLS.DISCOUNT_REASONS, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateDiscountReasonsData = createAppAsyncThunk<
  IDiscountReasonsResponseDTO,
  { _id: string; data: UpdateDiscountReasonRequestBodyDTO }
>(
  discountReasonTypes.REQUEST_TO_PATCH_UPDATE_DISCOUNT_REASONS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IDiscountReasonsResponseDTO>
      >(`${URLS.DISCOUNT_REASONS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleDiscountReason = createAppAsyncThunk<
  IDiscountReasonsResponseDTO,
  string
>(
  discountReasonTypes.REQUEST_SINGLE_DISCOUNT_REASON,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.DISCOUNT_REASONS}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<IDiscountReasonsResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const discountReasonsInitialState: DiscountReasonsState = {
  discountReasonsData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  loadingSingleDiscountReason: false,
};

export const discountReasonsSlice = createSlice({
  name: 'discounts',
  initialState: discountReasonsInitialState,
  reducers: {
    resetDiscountReasonsState: () => discountReasonsInitialState,
    setDiscountReasonsState: (
      state,
      action: { payload: IDiscountReasonsResponseDTO[] },
    ) => {
      state.discountReasonsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscountReasonsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchDiscountReasonsData.fulfilled, (state, action) => {
        state.discountReasonsData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchDiscountReasonsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(patchUpdateDiscountReasonsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateDiscountReasonsData.fulfilled, (state, action) => {
        const updatedDiscountReason = action.payload;
        const index = state.discountReasonsData.findIndex(
          (discountReason) => discountReason._id === updatedDiscountReason._id,
        );
        if (index !== -1) {
          state.discountReasonsData[index] = {
            ...state.discountReasonsData[index],
            ...updatedDiscountReason,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateDiscountReasonsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createDiscountReasonData.pending, (state) => {
        state.loadingSingleDiscountReason = true;
      })
      .addCase(createDiscountReasonData.fulfilled, (state, action) => {
        state.discountReasonsData.push(action.payload);
        state.loadingSingleDiscountReason = false;
      })
      .addCase(createDiscountReasonData.rejected, (state) => {
        state.loadingSingleDiscountReason = false;
      })
      .addCase(getSingleDiscountReason.pending, (state) => {
        state.loadingSingleDiscountReason = true;
      })
      .addCase(getSingleDiscountReason.fulfilled, (state) => {
        state.loadingSingleDiscountReason = false;
      })
      .addCase(getSingleDiscountReason.rejected, (state) => {
        state.loadingSingleDiscountReason = false;
      });
  },
});

export const { resetDiscountReasonsState, setDiscountReasonsState } =
  discountReasonsSlice.actions;

export default discountReasonsSlice.reducer;
