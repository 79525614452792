import dayjs from 'dayjs';

export enum PAYMENTS_STEPS {
  NUMPAD = 'numpad',
  SUCCESS = 'success',
  FAILURE = 'failure',
  PROCESSING = 'processing',
  TAP_TO_PAY = 'taptopay',
}

export enum REFUND_STEPS {
  SELECTION = 'selection',
  PROCESSING = 'processing',
  SUCCESS = 'success',
}

export enum REFUND_TYPES {
  WHOLE = 'WHOLE',
  PART = 'PART',
}

export const PLACEHOLDER_DATE_TIME = dayjs('2024-01-01T00:00:00.000Z').toDate();
