import { SNACKBARTYPE } from './constants';

let snackbarFunction: (message: string, type: SNACKBARTYPE) => void;

export const setGlobalSnackbar = (
  fn: (message: string, type: SNACKBARTYPE) => void,
) => {
  snackbarFunction = fn;
};

export const openGlobalSnackbar = (message: string, type: SNACKBARTYPE) => {
  snackbarFunction?.(message, type);
};
