import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { CATEGORIES_COLUMNS } from '../constants';
import {
  CategoriesResponseDTO,
  CategoriesState,
  CreateCategoryRequestBodyDTO,
  GetAllCategoriesQueryDTO,
} from '../types/categories.types';
import * as categoriesTypes from './categoriesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchAllCategoriesData = createAppAsyncThunk<
  PaginatedResponseDTO<CategoriesResponseDTO, BasePaginationExtras>,
  GetAllCategoriesQueryDTO | undefined
>(
  categoriesTypes.REQUEST_ALL_CATEGORIES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<CategoriesResponseDTO, BasePaginationExtras>
        >
      >(URLS.CATEGORIES, {
        params,
      });

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchCategoriesData = createAppAsyncThunk<
  PaginatedResponseDTO<CategoriesResponseDTO, BasePaginationExtras>,
  Omit<GetAllCategoriesQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(
  categoriesTypes.REQUEST_CATEGORIES,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip, search_key } = getState().menuPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<CategoriesResponseDTO, BasePaginationExtras>
        >
      >(URLS.CATEGORIES, {
        params: {
          ...params,
          limit,
          skip,
          search_key,
        },
      });

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleCategory = createAppAsyncThunk<
  CategoriesResponseDTO,
  string
>(categoriesTypes.REQUEST_SINGLE_CATEGORY, async (id, { rejectWithValue }) => {
  try {
    const url = `${URLS.CATEGORIES}/${id}`;
    const response =
      await Api.get<CommonResponseDTO<CategoriesResponseDTO>>(url);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const createCategoriesData = createAppAsyncThunk<
  CategoriesResponseDTO,
  CreateCategoryRequestBodyDTO
>(
  categoriesTypes.REQUEST_TO_CREATE_CATEGORIES,
  async (data: CreateCategoryRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<CategoriesResponseDTO>>(
        URLS.CATEGORIES,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateCategoriesData = createAppAsyncThunk<
  CategoriesResponseDTO,
  { id: string; data: CreateCategoryRequestBodyDTO }
>(
  categoriesTypes.REQUEST_TO_UPDATE_CATEGORIES,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<CommonResponseDTO<CategoriesResponseDTO>>(
        `${URLS.CATEGORIES}/${id}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateCategoriesData = createAppAsyncThunk<
  CategoriesResponseDTO,
  { id: string; data: Partial<CreateCategoryRequestBodyDTO> }
>(
  categoriesTypes.REQUEST_TO_PATCH_UPDATE_CATEGORIES,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<CategoriesResponseDTO>
      >(`${URLS.CATEGORIES}/${id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteCategoriesData = createAppAsyncThunk<string, string>(
  categoriesTypes.REQUEST_TO_DELETE_CATEGORIES,
  async (id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.CATEGORIES}/${id}`);
      return id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateCategorySequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  categoriesTypes.REQUEST_TO_UPDATE_CATEGORY_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const categoriesData = [
        ...cloneDeep(getState().categories.categoriesData),
      ];

      const reorderData = reorder<CategoriesResponseDTO>({
        list: categoriesData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newCategoriesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setCategoriesState(newCategoriesData));

      const response = await Api.patch<
        CommonResponseDTO<CategoriesResponseDTO>
      >(`${URLS.CATEGORIES}/${dragId}`, {
        sequenceNumber: newCategoriesData[dropItemIndex].sequenceNumber,
      });

      if (response.status !== 200) {
        newCategoriesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newCategoriesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setCategoriesState(newCategoriesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const categoriesInitialState: CategoriesState = {
  categoriesData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  loadingSingleCategory: false,
  columns: CATEGORIES_COLUMNS,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState,
  reducers: {
    resetCategoriesState: () => categoriesInitialState,
    setCategoriesState: (
      state,
      action: { payload: CategoriesResponseDTO[] },
    ) => {
      state.categoriesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategoriesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchAllCategoriesData.fulfilled, (state, action) => {
        state.categoriesData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit;
        state.extras.skip = action.payload.extras.skip;
        state.extras.total = action.payload.extras.total;
        state.refreshing = false;
      })
      .addCase(fetchAllCategoriesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchCategoriesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchCategoriesData.fulfilled, (state, action) => {
        state.categoriesData = action.payload.results ?? [];

        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchCategoriesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createCategoriesData.pending, (state) => {
        state.loadingSingleCategory = true;
      })
      .addCase(createCategoriesData.fulfilled, (state, action) => {
        state.categoriesData.push(action.payload);
        state.loadingSingleCategory = false;
      })
      .addCase(createCategoriesData.rejected, (state) => {
        state.loadingSingleCategory = false;
      })
      .addCase(updateCategoriesData.pending, (state) => {
        state.loadingSingleCategory = true;
      })
      .addCase(updateCategoriesData.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const index = state.categoriesData.findIndex(
          (category) => category._id === updatedCategory._id,
        );
        if (index !== -1) {
          state.categoriesData[index] = updatedCategory;
        }
        state.loadingSingleCategory = false;
      })
      .addCase(updateCategoriesData.rejected, (state) => {
        state.loadingSingleCategory = false;
      })
      .addCase(patchUpdateCategoriesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateCategoriesData.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const index = state.categoriesData.findIndex(
          (category) => category._id === updatedCategory._id,
        );
        if (index !== -1) {
          state.categoriesData[index] = {
            ...state.categoriesData[index],
            ...updatedCategory,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateCategoriesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteCategoriesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteCategoriesData.fulfilled, (state, action) => {
        const index = state.categoriesData.findIndex(
          (category) => category._id === action.payload,
        );
        if (index !== -1) {
          state.categoriesData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteCategoriesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleCategory.pending, (state) => {
        state.loadingSingleCategory = true;
      })
      .addCase(getSingleCategory.fulfilled, (state) => {
        state.loadingSingleCategory = false;
      })
      .addCase(getSingleCategory.rejected, (state) => {
        state.loadingSingleCategory = false;
      })
      .addCase(updateCategorySequenceNumbers.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateCategorySequenceNumbers.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(updateCategorySequenceNumbers.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetCategoriesState, setCategoriesState } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
