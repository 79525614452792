import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { SUBCATEGORIES_COLUMNS } from '../constants';
import {
  CreateSubCategoryRequestBodyDTO,
  GetAllSubCategoriesQueryDTO,
  SubCategoriesState,
  SubCategoryResponseDTO,
} from '../types/subCategory.types';
import * as subCategoriesTypes from './subCategoriesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchSubCategoriesData = createAppAsyncThunk<
  PaginatedResponseDTO<SubCategoryResponseDTO, BasePaginationExtras>,
  Omit<GetAllSubCategoriesQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(
  subCategoriesTypes.REQUEST_SUB_CATEGORIES,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip, search_key } = getState().menuPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<SubCategoryResponseDTO, BasePaginationExtras>
        >
      >(URLS.SUB_CATEGORIES, {
        params: {
          ...params,
          limit,
          skip,
          search_key,
        },
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createSubCategoryData = createAppAsyncThunk<
  SubCategoryResponseDTO,
  CreateSubCategoryRequestBodyDTO
>(
  subCategoriesTypes.REQUEST_TO_CREATE_SUB_CATEGORY,
  async (data: CreateSubCategoryRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<SubCategoryResponseDTO>
      >(URLS.SUB_CATEGORIES, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateSubCategoryData = createAppAsyncThunk<
  SubCategoryResponseDTO,
  { id: string; data: CreateSubCategoryRequestBodyDTO }
>(
  subCategoriesTypes.REQUEST_TO_UPDATE_SUB_CATEGORY,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<CommonResponseDTO<SubCategoryResponseDTO>>(
        `${URLS.SUB_CATEGORIES}/${id}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateSubCategoryData = createAppAsyncThunk<
  SubCategoryResponseDTO,
  { id: string; data: Partial<CreateSubCategoryRequestBodyDTO> }
>(
  subCategoriesTypes.REQUEST_TO_PATCH_UPDATE_SUB_CATEGORY,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<SubCategoryResponseDTO>
      >(`${URLS.SUB_CATEGORIES}/${id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteSubCategoryData = createAppAsyncThunk<string, string>(
  subCategoriesTypes.REQUEST_TO_DELETE_SUB_CATEGORY,
  async (id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.SUB_CATEGORIES}/${id}`);
      return id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleSubCategory = createAppAsyncThunk<
  SubCategoryResponseDTO,
  string
>(
  subCategoriesTypes.REQUEST_SINGLE_SUB_CATEGORY,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await Api.get<CommonResponseDTO<SubCategoryResponseDTO>>(
        `${URLS.SUB_CATEGORIES}/${id}`,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateSubCategorySequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  subCategoriesTypes.REQUEST_TO_UPDATE_SUB_CATEGORY_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const subCategoriesData = [
        ...cloneDeep(getState().subCategories.subCategoriesData),
      ];

      const reorderData = reorder<SubCategoryResponseDTO>({
        list: subCategoriesData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newSubCategoriesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setSubCategoriesState(newSubCategoriesData));

      const response = await Api.patch<
        CommonResponseDTO<SubCategoryResponseDTO>
      >(`${URLS.SUB_CATEGORIES}/${dragId}`, {
        sequenceNumber: newSubCategoriesData[dropItemIndex].sequenceNumber,
      });

      if (response.status !== 200) {
        newSubCategoriesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newSubCategoriesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setSubCategoriesState(newSubCategoriesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const subCategoriesInitialState: SubCategoriesState = {
  subCategoriesData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  loadingSingleSubCategory: false,
  columns: SUBCATEGORIES_COLUMNS,
};

export const subCategoriesSlice = createSlice({
  name: 'subCategories',
  initialState: subCategoriesInitialState,
  reducers: {
    resetSubCategoryState: () => subCategoriesInitialState,
    setSubCategoriesState: (
      state,
      action: { payload: SubCategoryResponseDTO[] },
    ) => {
      state.subCategoriesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubCategoriesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchSubCategoriesData.fulfilled, (state, action) => {
        state.subCategoriesData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchSubCategoriesData.rejected, (state) => {
        state.refreshing = true;
      })
      .addCase(createSubCategoryData.pending, (state) => {
        state.loadingSingleSubCategory = true;
      })
      .addCase(createSubCategoryData.fulfilled, (state, action) => {
        state.subCategoriesData.push(action.payload);
        state.loadingSingleSubCategory = false;
      })
      .addCase(createSubCategoryData.rejected, (state) => {
        state.loadingSingleSubCategory = false;
      })
      .addCase(updateSubCategoryData.pending, (state) => {
        state.loadingSingleSubCategory = true;
      })
      .addCase(updateSubCategoryData.fulfilled, (state, action) => {
        const index = state.subCategoriesData.findIndex(
          (sub) => sub._id === action.payload._id,
        );
        if (index !== -1) {
          state.subCategoriesData[index] = action.payload;
        }
        state.loadingSingleSubCategory = false;
      })
      .addCase(updateSubCategoryData.rejected, (state) => {
        state.loadingSingleSubCategory = false;
      })
      .addCase(patchUpdateSubCategoryData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateSubCategoryData.fulfilled, (state, action) => {
        const index = state.subCategoriesData.findIndex(
          (sub) => sub._id === action.payload._id,
        );
        if (index !== -1) {
          state.subCategoriesData[index] = {
            ...state.subCategoriesData[index],
            ...action.payload,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateSubCategoryData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteSubCategoryData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteSubCategoryData.fulfilled, (state, action) => {
        const index = state.subCategoriesData.findIndex(
          (sub) => sub._id === action.payload,
        );
        if (index !== -1) {
          state.subCategoriesData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteSubCategoryData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleSubCategory.pending, (state) => {
        state.loadingSingleSubCategory = true;
      })
      .addCase(getSingleSubCategory.fulfilled, (state) => {
        state.loadingSingleSubCategory = false;
      })
      .addCase(getSingleSubCategory.rejected, (state) => {
        state.loadingSingleSubCategory = false;
      })
      .addCase(updateSubCategorySequenceNumbers.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateSubCategorySequenceNumbers.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(updateSubCategorySequenceNumbers.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetSubCategoryState, setSubCategoriesState } =
  subCategoriesSlice.actions;

export default subCategoriesSlice.reducer;
