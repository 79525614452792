import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

import Environment from '../config/environment';
import { Colors } from '../theme/colors';

const linkStyle: CSSProperties = {
  color: Colors.fontColorSecondary,
  fontSize: '14px',
  textDecoration: 'none',
  fontWeight: 500,
};

function AppAuthFooter() {
  return (
    <Box sx={{ mt: 2, marginLeft: 1 }}>
      <Typography>
        <a href="https://eatme.sg/" style={linkStyle}>
          © EatMe
        </a>
        <span> · </span>
        <a href="https://eatme.sg/privacy-policy/" style={linkStyle}>
          Privacy & terms
        </a>
        <span> · </span>
        <span style={linkStyle}> v{Environment.version} </span>
      </Typography>
    </Box>
  );
}

export default AppAuthFooter;
