import { useCallback, useEffect, useState } from 'react';

type PaginationState = {
  pageIndex: number;
  pageSize: number;
  total: number;
};

export const usePaginationState = (initialState: PaginationState) => {
  const [state, setState] = useState({
    ...initialState,
    totalPages: Math.ceil(initialState.total / initialState.pageSize),
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      totalPages: Math.ceil(initialState.total / prevState.pageSize),
      total: initialState.total,
    }));
  }, [initialState.total, initialState.pageSize]);

  const onChange = useCallback(
    (page: number, size?: number) => {
      const newPageSize = size ?? state.pageSize;
      const newTotalPages = Math.ceil(initialState.total / newPageSize);
      const newPageIndex = Math.min(
        Math.max(page, 0),
        newTotalPages > 0 ? newTotalPages - 1 : 0,
      );

      setState((prevState) => ({
        ...prevState,
        pageIndex: newPageIndex,
        pageSize: newPageSize,
        totalPages: newTotalPages,
      }));
    },
    [initialState, state.pageSize],
  );

  return {
    ...state,
    onChange,
  };
};
