import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export const reportItems = [
  {
    name: 'Sales Summary',
    details: 'A quick look at a your recent sales performance and key metrics.',
    route: '/reports/sales-summary',
  },
  {
    name: 'Receipts',
    details:
      'An overview of transactions based on receipts, providing insights into individual sales interactions for better tracking and analysis.',
    route: '/reports/receipts',
  },
  {
    name: 'Shifts',
    details:
      'A concise report summarizing key metrics from a specific work shift, offering a quick overview of performance during that timeframe.',
    route: '/reports/shifts-summary/detailed',
  },
  {
    name: 'Payment Reconciliation Summary',
    details:
      'A report facilitating the matching and verification of payments received against transactions, ensuring accurate financial reconciliation.',
    route: '/reports/payment-reconciliation-summary/daily-summary',
  },
  {
    name: 'Order Summary',
    details: 'A report facilitating the number of different orders placed.',
    route: '/reports/order-count-summary',
  },
  {
    name: 'Sales by Item',
    details:
      'An analysis of sales performance based on specific items and categories, aiding inventory management and product strategy.',
    route: '/reports/sales-by-menu-summary',
  },
  {
    name: 'Hourly Sales',
    details: 'An analysis of sales performance based on specific hour each day',
    route: '/reports/hourly-sales-summary',
  },
];

export enum PaymentTypes {
  CARD_LOCAL = 'Card - Local',
  CARD_INTERNATIONAL = 'Card - International',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MC = 'mc',
  AMEX = 'amex',
  UNION_PAY = 'unionpay',
  CUP = 'cup',
  JCB = 'jcb',
  WALLETS = 'Wallets',
  ALI_PAY = 'alipay',
  GRAB_PAY = 'grabpay',
  PAY_NOW = 'paynow',
  WE_CHAT_PAY = 'wechat_pay',
  CASH = 'Cash',
  OTHER_PAYMENTS = 'Other Payments',
  ADJUSTMENTS = 'Adjustments',
  REFUNDS = 'Refunds',
  GRAND_TOTAL = 'GRAND TOTAL',
}

export const RECONCILIATION_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'paymentType',
    header: 'PAYMENT TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'counts',
    header: 'COUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'collections',
    header: 'COLLECTIONS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'refunds',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'trxPercentageFee',
    header: 'TRX FEE % VALUE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'trxFixedFee',
    header: 'TRX FIXED FEE VALUE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'trxGrandTotal',
    header: 'TRX FEE GRAND TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'balance',
    header: 'BALANCE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const ADYEN_RECONCILIATION_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'paymentType',
    header: 'PAYMENT TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'counts',
    header: 'COUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'collections',
    header: 'COLLECTIONS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'refunds',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'trxGrandTotal',
    header: 'TRX FEE TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'balance',
    header: 'PAYABLE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const RECEIPTS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'placedAtTime',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'receipt',
    header: 'RECEIPT #',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'ticket',
    header: 'TABLE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'employee',
    header: 'EMPLOYEE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'paymentType',
    header: 'PAYMENT TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'total',
    header: 'NET TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const ORDER_ANALYTICS_SUMMARY: IAppTableColumn[] = [
  {
    accessorKey: 'orderDate',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Date',
    visible: true,
  },
  {
    accessorKey: 'dineInCounts',
    header: 'DINE-IN',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Order Types',
    visible: true,
  },
  {
    accessorKey: 'pickUpCounts',
    header: 'TAKEAWAY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Order Types',
    visible: true,
  },
  {
    accessorKey: 'deliveryCounts',
    header: 'DELIVERY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Order Types',
    visible: true,
  },
  {
    accessorKey: 'otherPaymentsCounts',
    header: 'OTHER PAYMENTS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Order Types',
    visible: true,
  },
  {
    accessorKey: 'totalOrders',
    header: 'TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Total',
    visible: true,
  },
];

export const SALES_BY_MENU_ITEMS_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'menuItem',
    header: 'ITEM',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'ITEM NAME',
    visible: true,
  },
  {
    accessorKey: 'category',
    header: 'CATEGORY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'ITEM CATEGORY',
    visible: true,
  },
  {
    accessorKey: 'qtySold',
    header: 'ITEM QTY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'SALES',
    visible: true,
  },
  {
    accessorKey: 'price',
    header: 'PRICE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'SALES',
    visible: true,
  },
  {
    accessorKey: 'sales',
    header: 'NET AMOUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'SALES',
    visible: true,
  },
];

export const SHIFT_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'createdAt',
    header: 'OPENED DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'shiftNumber',
    header: 'SHIFT #',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'openedByName',
    header: 'OPENED BY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'closedByName',
    header: 'CLOSED BY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalGross',
    header: 'GROSS SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalDiscount',
    header: 'DISCOUNTS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalRefundAmount',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'netTotal',
    header: 'NET SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const EXPORT_SHIFT_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'createdAt',
    header: 'OPENED DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'shiftNumber',
    header: 'SHIFT NUMBER',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'openedByName',
    header: 'OPENED BY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'closedByName',
    header: 'CLOSED BY',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalGross',
    header: 'GROSS SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalDiscount',
    header: 'DISCOUNTS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalRefundAmount',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'netTotal',
    header: 'NET SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const EXPORT_RECEIPTS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'placedAtTime',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'receipt',
    header: 'RECEIPT',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'ticket',
    header: 'TABLE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'employee',
    header: 'EMPLOYEE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'paymentType',
    header: 'PAYMENT TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'total',
    header: 'NET TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];
export const SALES_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'date',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'grossSales',
    header: 'GROSS SALE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'discounts',
    header: 'DISCOUNTS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'refunds',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'netSales',
    header: 'NET SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export const HOURLY_SALES_SUMMARY: IAppTableColumn[] = [
  {
    accessorKey: 'date',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'hour_placed',
    header: 'HOUR',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'totalEarnings',
    header: 'SALES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];

export enum SINGLE_SHIFT_SUMMARY_TABS {
  SHIFTS = 'shifts',
  SALES = 'sales',
  CATEGORY_SALES = 'category_sales',
  SERVICE_CHARGE = 'service_charge',
  TAXES = 'taxes',
  DISCOUNTS = 'discounts',
  VOIDS = 'voids',
  CASH_SUMMARY = 'cash_summary',
  PAYMENTS = 'payments',
}

export const DAILY_STRIPE_SUMMARY_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'date',
    header: 'DATE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'count',
    header: 'COUNT',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'collections',
    header: 'COLLECTIONS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'transactionFees',
    header: 'TRX FEE TOTAL',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'refunds',
    header: 'REFUNDS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'adjustments',
    header: 'ADJUSTMENT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
  {
    accessorKey: 'payable',
    header: 'PAYABLE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: '',
    visible: true,
  },
];
