import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  ISalesSummaryStatusResponseDTO,
  ReportsSalesSummaryState,
  SalesSummaryExtras,
  SalesSummaryFilterType,
  SalesSummaryParams,
  SalesSummaryResponseDTO,
  TotalSalesDetails,
} from '../types/sales-summary.types';
import * as salesSummaryTypes from './salesSummaryActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchSalesSummaryGraphData = createAppAsyncThunk<
  SalesSummaryResponseDTO[],
  SalesSummaryParams
>(
  salesSummaryTypes.REQUEST_GRAPH_SALES_SUMMARIES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<{ results: SalesSummaryResponseDTO[] }>
      >(`${URLS.REPORTS.SALES_SUMMARY_V2}/graph-data`, {
        params,
      });
      return response.data.data.results;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchSalesSummaryTableData = createAppAsyncThunk<
  PaginatedResponseDTO<SalesSummaryResponseDTO, SalesSummaryExtras>,
  SalesSummaryParams
>(
  salesSummaryTypes.REQUEST_TABLE_SALES_SUMMARIES,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip } = getState().reportPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<SalesSummaryResponseDTO, SalesSummaryExtras>
        >
      >(`${URLS.REPORTS.SALES_SUMMARY_V2}/table-data`, {
        params: { ...params, limit, skip },
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchSalesSummaryDataExport = createAppAsyncThunk<
  PaginatedResponseDTO<SalesSummaryResponseDTO, SalesSummaryExtras>,
  SalesSummaryParams
>(
  salesSummaryTypes.REQUEST_SALES_SUMMARIES_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<SalesSummaryResponseDTO, SalesSummaryExtras>
        >
      >(`${URLS.REPORTS.SALES_SUMMARY_V2}/graph-data`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchSalesSummaryStatus = createAppAsyncThunk<
  ISalesSummaryStatusResponseDTO,
  void
>(
  salesSummaryTypes.REQUEST_SALES_SUMMARIES_STATUS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<ISalesSummaryStatusResponseDTO>
      >(`${URLS.REPORTS.SALES_SUMMARY_V2}/status`);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

const initialState: ReportsSalesSummaryState = {
  salesSummaryGraphData: [],
  salesSummaryTableData: [],
  extras: {
    limit: 10,
    total: 0,
    date_from: undefined,
    date_to: undefined,
    employeeIds: undefined,
    totalSalesDetails: [] as TotalSalesDetails[],
  },
  refreshing: false,
  refreshingGraph: false,
  refreshingStatus: false,
  salesSummaryStatus: { showLoadingScreen: false },
  activeTab: [],
  selectedFilterType: undefined,
};

const salesSummarySlice = createSlice({
  name: 'salesSummary',
  initialState,
  reducers: {
    resetSalesSummaryState: () => initialState,
    setActiveTab: (state, action: PayloadAction<string[]>) => {
      state.activeTab = action.payload;
    },
    setSelectedFilterType: (
      state,
      action: PayloadAction<SalesSummaryFilterType>,
    ) => {
      state.selectedFilterType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesSummaryGraphData.pending, (state) => {
        state.refreshingGraph = true;
      })
      .addCase(fetchSalesSummaryGraphData.fulfilled, (state, action) => {
        state.salesSummaryGraphData = action.payload;
        state.refreshingGraph = false;
      })
      .addCase(fetchSalesSummaryGraphData.rejected, (state) => {
        state.refreshingGraph = false;
      })
      .addCase(fetchSalesSummaryTableData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchSalesSummaryTableData.fulfilled, (state, action) => {
        state.salesSummaryTableData = action.payload.results;
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.extras.lastUpdated = action.payload.extras.lastUpdated;
        state.extras.employeeIds = action.payload.extras.employeeIds;
        state.extras.totalSalesDetails =
          action.payload.extras.totalSalesDetails ?? [];
        state.refreshing = false;
      })
      .addCase(fetchSalesSummaryTableData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchSalesSummaryStatus.pending, (state) => {
        state.refreshingStatus = true;
      })
      .addCase(fetchSalesSummaryStatus.fulfilled, (state, action) => {
        state.salesSummaryStatus = action.payload;
        state.refreshingStatus = false;
      })
      .addCase(fetchSalesSummaryStatus.rejected, (state) => {
        state.refreshingStatus = false;
      });
  },
});

export const { resetSalesSummaryState, setActiveTab, setSelectedFilterType } =
  salesSummarySlice.actions;

export default salesSummarySlice.reducer;
