export const SENTRY_TYPES = {
  network_call: 'network_call',
};

export const URLS = {
  AUTH: {
    LOGIN: 'v1/auth/login',
    CHANGE_RESTAURANT: 'v1/auth/change-rest',
    PROFILE: 'v1/auth/profile',
    RESTAURANT: 'v1/auth/restaurant',
    TOKEN_VERIFICATION: 'v1/auth/token-verify',
    REGISTER: 'v1/auth/register',
    FORGOT_PASSWORD: 'v1/auth/forgot-password',
    OTP_VERIFICATION: 'v1/auth/otp-verification',
    RESET_PASSWORD: 'v1/auth/reset-password',
  },

  CATEGORIES: 'v1/categories',
  CHOICE_GROUPS: 'v1/choice-groups',
  ORDERS: 'v1/orders',
  SUB_CATEGORIES: '/v1/sub-categories',
  PAYMENTS: 'v1/payments',
  ADYEN: {
    DEFAULT: 'v1/adyen',
    ACCOUNTS: {
      DEFAULT: 'v1/adyen/accounts',
      CREATE_ONBOARDING_URL: 'v1/adyen/accounts/onboarding-link',
      WARNINGS: 'v1/adyen/accounts/warnings',
    },
    STORE: 'v1/adyen/stores',
    BUSINESS_LINE: 'v1/adyen/business-lines',
    BANK_ACCOUNTS: 'v1/adyen/bank-accounts',
    PAYOUT_SCHEDULES: 'v1/adyen/payout-schedules',
    SESSION: 'v1/adyen/sessions/platform',
  },
  PAGES: 'v1/pages',
  DISHES: 'v1/dishes',
  ADDON_GROUPS: 'v1/addon-groups',
  IMAGES: {
    CREATE_PRESIGNED_URL: 'v1/images/presigned-url',
    DEFAULT: 'v1/images',
  },
  PREDEFINED_TICKETS: 'v1/settings/predefined-tickets',
  RECEIPT: 'v1/settings/receipt/',
  TRANSACTION_CHARGES: 'v1/settings/transaction-charges',
  ORDER_TYPES: 'v1/settings/pos-settings',
  TAXES: 'v1/settings/restaurant-charges',
  GENERAL_SETTINGS: {
    DEFAULT: 'v1/settings/general-settings',
    TOGGLE_SHIFT_MANAGEMENT:
      'v1/settings/general-settings/toggle/shift-management',
    TOGGLE_GUEST_COUNT: 'v1/settings/general-settings/toggle/guest-count',
    TOGGLE_GUEST_COUNT_MANDATORY:
      'v1/settings/general-settings/toggle/guest-count-mandatory',
    TOGGLE_CUSTOMER_INFORMATION:
      'v1/settings/general-settings/toggle/customer-information',
    TOGGLE_CUSTOMER_INFORMATION_MANDATORY:
      'v1/settings/general-settings/toggle/customer-information-mandatory',
  },
  REPORTS: {
    RECONCILIATION_SUMMARY: 'v1/reports/reconciliation-summary/summary',
    RECEIPTS_DEFAULT: 'v1/reports/receipts',
    RECEIPTS_STATUS: 'v1/reports/receipts/status',
    ORDER_COUNT_SUMMARY: 'v1/reports/order-summary',
    SALES_BY_MENU_SUMMARY: 'v1/reports/menu-summary',
    SALES_SUMMARY: 'v1/reports/sales-summary',
    SALES_SUMMARY_V2: 'v2/reports/sales-summary',
    HOURLY_SALES_SUMMARY: 'v1/reports/hourly-sales-summary',
    DAILY_STRIPE_SUMMARY:
      'v1/reports/reconciliation-summary/daily-stripe-summary',
    DAILY_ADYEN_SUMMARY:
      'v1/reports/reconciliation-summary/daily-adyen-summary',
    ADYEN_RECONCILIATION_SUMMARY:
      'v1/reports/reconciliation-summary/adyen-summary',
  },
  SHIFT_SUMMARY: {
    DEFAULT: 'v1/reports/shifts',
    SHIFT_SUMMARY_STATUS: 'v1/reports/shifts/status',
    SINGLE_SHIFT_SUMMARY_V2: 'v2/reports/shifts',
    CONSOLIDATED_SHIFT_SUMMARY: 'v1/reports/shifts/consolidated-summary',
  },
  DEVICES: '/v1/settings/devices',
  EMPLOYEES: 'v1/employees',
  PAYMENT_TYPES: '/v1/settings/payment-types',
  RESTAURANT_AREAS: '/v1/settings/table-areas',
  NOTIFICATIONS: '/v1/settings/notifications',
  DISCOUNTS: '/v1/discounts',
  DISCOUNT_REASONS: '/v1/discount-reasons',
  ACCOUNT_DELETION: '/delete-user-info-email',
};

export enum ENVIRONMENTS {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  QA = 'qa',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export * from './ApiConstants';
export * from './AppConstants';

export enum RESTAURANT_ROLES {
  OWNER = 'OWNER', // owner of the restaurant
  ADMINISTRATOR = 'ADMINISTRATOR', // can do anything expect deleting the owner/ account
  MANAGER = 'MANAGER', // can't change system settings
  CASHIER = 'CASHIER', // only ready only access for POS
  WAITER = 'WAITER', // CASHIER + cannot charge
  SUPPORT = 'SUPPORT',
}

export enum PAYMENT_METHOD_TYPES {
  CARD = 'card_present',
  CARD_ONLINE = 'card',
  ALI_PAY = 'alipay',
  GRAB_PAY = 'grabpay',
  PAY_NOW = 'paynow',
  WE_CHAT_PAY = 'wechat_pay',
  CASH = 'cash',
  CUSTOM = 'custom',
  STRIPE_TERMINAL = 'Stripe Terminal',
}

export enum CARD_TYPES {
  VISA = 'visa',
  MASTER = 'master',
  AMEX = 'amex',
}

export enum DEVICE_STATUS {
  AVAILABLE = 'AVAILABLE',
  INACTIVE = 'INACTIVE',
  OCCUPIED = 'OCCUPIED',
  DELETED = 'DELETED',
}

export enum DEVICE_STATUS_API {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  OCCUPIED = 'OCCUPIED',
}

export enum TABLE_AREA_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum EMPLOYEE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  DELETED = 'DELETED',
}

export enum DISCOUNT_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum DISCOUNT_TYPES {
  FIXED_PERCENTAGE = 'FIXED_PERCENTAGE',
  FIXED_VALUE = 'FIXED_VALUE',
  OPEN_PERCENTAGE = 'OPEN_PERCENTAGE',
  OPEN_VALUE = 'OPEN_VALUE',
}

export enum DISCOUNT_APPLY_TYPES {
  ANY_ITEM = 'ANY_ITEM',
  FULL_ORDER = 'FULL_ORDER',
  SPECIFIC_ITEM = 'SPECIFIC_ITEM',
}

export enum PERMISSION_TYPES {
  MANAGER = 'MANAGER',
  ANY = 'ANY',
}
