import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { ADDON_GROUPS_COLUMNS } from '../constants';
import {
  AddOnGroupsState,
  CreateAddOnGroupsRequestBodyDTO,
  GetAllAddOnsQueryDTO,
  IAddOn,
  IAddOnGroupsResponseDTO,
} from '../types/add-on-groups.types';
import * as addOnsTypes from './addOnGroupsActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchAddOnsData = createAppAsyncThunk<
  PaginatedResponseDTO<IAddOnGroupsResponseDTO, BasePaginationExtras>,
  Omit<GetAllAddOnsQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(addOnsTypes.REQUEST_ADDONS, async (params, { rejectWithValue, getState }) => {
  try {
    const { limit, skip, search_key } = getState().menuPagination;
    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<IAddOnGroupsResponseDTO, BasePaginationExtras>
      >
    >(URLS.ADDON_GROUPS, {
      params: {
        ...params,
        limit,
        skip,
        search_key,
      },
    });

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const getSingleAddOn = createAppAsyncThunk<
  IAddOnGroupsResponseDTO,
  string
>(addOnsTypes.REQUEST_SINGLE_ADDON, async (id, { rejectWithValue }) => {
  try {
    const url = `${URLS.ADDON_GROUPS}/${id}`;
    const response =
      await Api.get<CommonResponseDTO<IAddOnGroupsResponseDTO>>(url);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const createAddOnGroupData = createAppAsyncThunk<
  IAddOnGroupsResponseDTO,
  CreateAddOnGroupsRequestBodyDTO
>(
  addOnsTypes.REQUEST_TO_CREATE_ADDONS,
  async (data: CreateAddOnGroupsRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<IAddOnGroupsResponseDTO>
      >(URLS.ADDON_GROUPS, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const putUpdateAddOnGroupData = createAppAsyncThunk<
  IAddOnGroupsResponseDTO,
  { _id: string; data: CreateAddOnGroupsRequestBodyDTO }
>(
  addOnsTypes.REQUEST_TO_UPDATE_ADDONS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<
        CommonResponseDTO<IAddOnGroupsResponseDTO>
      >(`${URLS.ADDON_GROUPS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateAddOnGroupData = createAppAsyncThunk<
  IAddOnGroupsResponseDTO,
  { _id: string; data: Partial<CreateAddOnGroupsRequestBodyDTO> }
>(
  addOnsTypes.REQUEST_TO_PATCH_UPDATE_ADDONS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IAddOnGroupsResponseDTO>
      >(`${URLS.ADDON_GROUPS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteAddOnGroupData = createAppAsyncThunk<string, string>(
  addOnsTypes.REQUEST_TO_DELETE_ADDONS,
  async (_id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.ADDON_GROUPS}/${_id}`);
      return _id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const addOnsInitialState: AddOnGroupsState = {
  addOnGroupsData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  columns: ADDON_GROUPS_COLUMNS,
  loadingSingleAddOn: false,
};

export const updateAddOnsSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  addOnsTypes.REQUEST_TO_UPDATE_ADDONS_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const AddOnsData = [...cloneDeep(getState().addOnGroups.addOnGroupsData)];

      const reorderData = reorder<IAddOnGroupsResponseDTO>({
        list: AddOnsData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newAddOnsData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setAddOnsState(newAddOnsData));

      const response = await Api.patch<
        CommonResponseDTO<IAddOnGroupsResponseDTO>
      >(`${URLS.ADDON_GROUPS}/${dragId}`, {
        sequenceNumber: newAddOnsData[dropItemIndex].sequenceNumber,
      });

      if (response.status !== 200) {
        newAddOnsData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newAddOnsData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setAddOnsState(newAddOnsData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updatedAddonItemsSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string; items: IAddOn[]; name: string; id: string }
>(
  addOnsTypes.REQUEST_TO_UPDATE_ADDONS_ITEMS_SEQUENCE_NUMBER,
  async (
    { dragId, dropId, items, name, id },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const reorderData = reorder<IAddOn>({
        list: items,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newAddonsData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setAddOnItemsState(newAddonsData));

      const response = await Api.patch<
        CommonResponseDTO<IAddOnGroupsResponseDTO>
      >(`${URLS.ADDON_GROUPS}/${id}`, {
        addons: [
          {
            _id: dragId,
            sequenceNumber: newAddonsData[dropItemIndex].sequenceNumber,
            name: name,
          },
        ],
      });

      if (response.status !== 200) {
        newAddonsData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newAddonsData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setAddOnItemsState(newAddonsData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const addOnsSlice = createSlice({
  name: 'addons',
  initialState: addOnsInitialState,
  reducers: {
    resetAddOnsState: () => addOnsInitialState,
    resetAddOnsEditingDataState: (state) => {
      state.editingData = undefined;
    },
    setAddOnsState: (state, action: { payload: IAddOnGroupsResponseDTO[] }) => {
      state.addOnGroupsData = action.payload;
    },
    setAddOnItemsState: (state, action: { payload: IAddOn[] }) => {
      if (state.editingData) {
        state.editingData.addons = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddOnsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchAddOnsData.fulfilled, (state, action) => {
        state.addOnGroupsData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchAddOnsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createAddOnGroupData.pending, (state) => {
        state.loadingSingleAddOn = true;
      })
      .addCase(createAddOnGroupData.fulfilled, (state, action) => {
        state.addOnGroupsData.push(action.payload);
        state.loadingSingleAddOn = false;
      })
      .addCase(createAddOnGroupData.rejected, (state) => {
        state.loadingSingleAddOn = false;
      })
      .addCase(putUpdateAddOnGroupData.pending, (state) => {
        state.loadingSingleAddOn = true;
      })
      .addCase(putUpdateAddOnGroupData.fulfilled, (state, action) => {
        const updatedAddOn = action.payload;
        const index = state.addOnGroupsData.findIndex(
          (addOn) => addOn._id === updatedAddOn._id,
        );
        if (index !== -1) {
          state.addOnGroupsData[index] = updatedAddOn;
        }
        state.loadingSingleAddOn = false;
      })
      .addCase(putUpdateAddOnGroupData.rejected, (state) => {
        state.loadingSingleAddOn = false;
      })
      .addCase(patchUpdateAddOnGroupData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateAddOnGroupData.fulfilled, (state, action) => {
        const updatedAddOn = action.payload;
        const index = state.addOnGroupsData.findIndex(
          (addOn) => addOn._id === updatedAddOn._id,
        );
        if (index !== -1) {
          state.addOnGroupsData[index] = {
            ...state.addOnGroupsData[index],
            ...updatedAddOn,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateAddOnGroupData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteAddOnGroupData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteAddOnGroupData.fulfilled, (state, action) => {
        const index = state.addOnGroupsData.findIndex(
          (addOn) => addOn._id === action.payload,
        );
        if (index !== -1) {
          state.addOnGroupsData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteAddOnGroupData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleAddOn.pending, (state) => {
        state.loadingSingleAddOn = true;
      })
      .addCase(getSingleAddOn.fulfilled, (state, action) => {
        state.loadingSingleAddOn = false;
        state.editingData = action.payload;
      })
      .addCase(getSingleAddOn.rejected, (state) => {
        state.loadingSingleAddOn = false;
      });
  },
});

export const {
  resetAddOnsState,
  resetAddOnsEditingDataState,
  setAddOnsState,
  setAddOnItemsState,
} = addOnsSlice.actions;

export default addOnsSlice.reducer;
