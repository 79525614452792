import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import { ACTIVE_ORDERS_COLUMNS } from '../constants';
import {
  ActiveOrdersPayload,
  ActiveOrderState,
  IOrdersResponseDTO,
} from '../types/orders.types';
import * as ordersTypes from './ordersActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchOrderDetails = createAppAsyncThunk<
  IOrdersResponseDTO[],
  ActiveOrdersPayload
>(ordersTypes.REQUEST_ORDERS, async (params, { rejectWithValue }) => {
  try {
    const response = await Api.post<CommonResponseDTO<IOrdersResponseDTO[]>>(
      `${URLS.ORDERS}/active-orders`,
      {
        type: params.type,
        limit: params.limit,
        pageNo: params.pageNo,
        status: params.status,
        paymentMethod: params.paymentMethod,
        orderSource: params.orderSource,
        priceRange: params.priceRange,
      },
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

export const fetchOrdersExport = createAppAsyncThunk<
  IOrdersResponseDTO[],
  ActiveOrdersPayload
>(ordersTypes.REQUEST_ORDERS_EXPORT, async (params, { rejectWithValue }) => {
  try {
    const response = await Api.post<CommonResponseDTO<IOrdersResponseDTO[]>>(
      `${URLS.ORDERS}/active-orders`,
      {
        type: params.type,
        status: params.status,
        paymentMethod: params.paymentMethod,
        orderSource: params.orderSource,
        priceRange: params.priceRange,
      },
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

const initialActiveOrderState: ActiveOrderState = {
  orderFilters: {
    paymentMethods: [],
    orderSources: [],
    priceRanges: {
      minValue: {
        name: '',
        value: 0,
      },
      maxValue: {
        name: '',
        value: 0,
      },
      ranges: [],
    },
  },
  ordersCount: {
    pendingOrders: 0,
    acceptOrders: 0,
    readyOrders: 0,
    onTheWayOrders: 0,
    allOrders: 0,
  },
  orderDetails: [],
  exportOrders: [],
  status: 'idle',
  refreshing: false,
  exportStatus: 'idle',
  error: null,
  currentTab: 'all',
  filters: {
    paymentMethod: '',
    orderSource: '',
    priceRange: {
      id: null,
      active: false,
      filterType: '',
      minValue: 0,
      maxValue: 0,
      ranges: '',
    },
  },

  columns: ACTIVE_ORDERS_COLUMNS,
};

export const activeOrderSlice = createSlice({
  name: 'activeOrders',
  initialState: initialActiveOrderState,
  reducers: {
    resetOrderState: () => initialActiveOrderState,
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },

    updateFilter: (
      state,
      action: PayloadAction<Partial<ActiveOrderState['filters']>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    updatePriceRange: (
      state,
      action: PayloadAction<Partial<ActiveOrderState['filters']['priceRange']>>,
    ) => {
      state.filters.priceRange = {
        ...state.filters.priceRange,
        ...action.payload,
      };
    },
    resetPriceRange: (state) => {
      state.filters.priceRange = {
        id: null,
        active: false,
        filterType: '',
        minValue: 0,
        maxValue: 0,
        ranges: '',
      };
    },
    setColumns: (
      state: ActiveOrderState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetails.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchOrderDetails.fulfilled, (state) => {
        state.status = 'success';
        state.refreshing = false;
      })

      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'An error occurred';
        state.refreshing = false;
      })
      .addCase(fetchOrdersExport.pending, (state) => {
        state.exportStatus = 'loading';
      })
      .addCase(fetchOrdersExport.fulfilled, (state) => {
        state.exportStatus = 'success';
      })
      .addCase(fetchOrdersExport.rejected, (state) => {
        state.exportStatus = 'failed';
      });
  },
});

export const {
  setActiveTab,
  updateFilter,
  updatePriceRange,
  resetPriceRange,
  setColumns,
  resetOrderState,
} = activeOrderSlice.actions;

export const { reducer: activeOrderReducer } = activeOrderSlice;

export default activeOrderSlice.reducer;
