export const REQUEST_RESTAURANT_AREAS =
  'SETTINGS/RESTAURANT_AREAS/REQUEST_RESTAURANT_AREAS';
export const REQUEST_TO_CREATE_RESTAURANT_AREAS =
  'SETTINGS/RESTAURANT_AREAS/REQUEST_TO_CREATE_RESTAURANT_AREAS';
export const REQUEST_SINGLE_RESTAURANT_AREA =
  'SETTINGS/RESTAURANT_AREAS/REQUEST_SINGLE_RESTAURANT_AREA';
export const REQUEST_TO_PATCH_UPDATE_RESTAURANT_AREAS =
  'SETTINGS/RESTAURANT_AREAS/REQUEST_TO_PATCH_UPDATE_RESTAURANT_AREAS';
export const REQUEST_TO_UPDATE_RESTAURANT_AREAS_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_RESTAURANT_AREAS_SEQUENCE_NUMBER';
