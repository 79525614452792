export const REQUEST_RECONCILIATION_SUMMARY =
  'REPORTS/REQUEST_RECONCILIATION_SUMMARY';
export const REQUEST_RECONCILIATION_SUMMARY_EXPORT =
  'REPORTS/REQUEST_RECONCILIATION_SUMMARY_EXPORT';
export const REQUEST_ORDER_COUNT_SUMMARY =
  'REPORTS/REQUEST_ORDER_COUNT_SUMMARY';
export const REQUEST_ORDER_COUNT_SUMMARY_EXPORT =
  'REPORTS/REQUEST_ORDER_COUNT_SUMMARY_EXPORT';
export const REQUEST_SALES_BY_MENU_ITEM_SUMMARY =
  'REPORTS?REQUEST_SALES_BY_MENU_ITEM_SUMMARY';
export const REQUEST_SALES_BY_MENU_ITEM_SUMMARY_EXPORT =
  'REPORTS?REQUEST_SALES_BY_MENU_ITEM_SUMMARY_EXPORT';
export const REQUEST_HOURLY_SALES_SUMMARY =
  'REPORTS?REQUEST_HOURLY_SALES_SUMMARY';
export const REQUEST_HOURLY_SALES_SUMMARY_EXPORT =
  'REPORTS?REQUEST_HOURLY_SALES_SUMMARY_EXPORT';
export const REQUEST_DAILY_STRIPE_SUMMARY =
  'REPORTS/REQUEST_DAILY_STRIPE_SUMMARY';
export const REQUEST_DAILY_STRIPE_SUMMARY_EXPORT =
  'REPORTS/REQUEST_DAILY_STRIPE_SUMMARY_EXPORT';
export const REQUEST_DAILY_ADYEN_SUMMARY =
  'REPORTS/REQUEST_DAILY_ADYEN_SUMMARY';
export const REQUEST_DAILY_ADYEN_SUMMARY_EXPORT =
  'REPORTS/REQUEST_DAILY_ADYEN_SUMMARY_EXPORT';
export const REQUEST_ADYEN_RECONCILIATION_SUMMARY =
  'REPORTS/REQUEST_ADYEN_RECONCILIATION_SUMMARY';
export const REQUEST_ADYEN_RECONCILIATION_SUMMARY_EXPORT =
  'REPORTS/REQUEST_ADYEN_RECONCILIATION_SUMMARY_EXPORT';
