import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  CreateTaxRequestBodyDTO,
  ITaxResponseDTO,
  TaxesState,
} from '../types/taxes.types';
import * as TaxTypes from './taxesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchTaxesData = createAppAsyncThunk<ITaxResponseDTO[], number>(
  TaxTypes.REQUEST_TAXES,
  async (resId, { rejectWithValue }) => {
    try {
      const response = await Api.get<CommonResponseDTO<ITaxResponseDTO[]>>(
        `${URLS.TAXES}?resId=${resId}`,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createTax = createAppAsyncThunk<
  ITaxResponseDTO,
  CreateTaxRequestBodyDTO
>(
  TaxTypes.REQUEST_TO_CREATE_TAXES,
  async (taxesData: CreateTaxRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<ITaxResponseDTO>>(
        URLS.TAXES,
        taxesData,
        { params: { resId: taxesData.resId } },
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateTaxData = createAppAsyncThunk<
  ITaxResponseDTO,
  { id: number; data: Partial<CreateTaxRequestBodyDTO> }
>(
  TaxTypes.REQUEST_TO_PATCH_UPDATE_TAXES,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<CommonResponseDTO<ITaxResponseDTO>>(
        `${URLS.TAXES}/${id}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchTaxCustomizationStatus = createAppAsyncThunk<boolean, void>(
  TaxTypes.REQUEST_TO_GET_TAX_CUSTOMIZATION_STATUS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<CommonResponseDTO<boolean>>(
        `${URLS.TAXES}/custom`,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleTaxCustomizationStatus = createAppAsyncThunk<boolean, void>(
  TaxTypes.REQUEST_TO_UPDATE_TAX_CUSTOMIZATION_STATUS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<boolean>>(
        `${URLS.TAXES}/custom`,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const taxesInitialState: TaxesState = {
  taxesData: [],
  loadingSingleTax: false,
  refreshing: false,
  isTaxCustomizationEnabled: false,
};

const taxesSlice = createSlice({
  name: 'taxes',
  initialState: taxesInitialState,
  reducers: {
    resetTaxesState: () => taxesInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchTaxesData.fulfilled, (state, action) => {
        state.taxesData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchTaxesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createTax.pending, (state) => {
        state.loadingSingleTax = true;
      })
      .addCase(createTax.fulfilled, (state, action) => {
        state.taxesData.push(action.payload);
        state.loadingSingleTax = false;
      })
      .addCase(createTax.rejected, (state) => {
        state.loadingSingleTax = false;
      })
      .addCase(patchUpdateTaxData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateTaxData.fulfilled, (state, action) => {
        const index = state.taxesData.findIndex(
          (tax) => tax.id === action.payload.id,
        );
        if (index !== -1) {
          state.taxesData[index] = {
            ...state.taxesData[index],
            ...action.payload,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateTaxData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchTaxCustomizationStatus.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchTaxCustomizationStatus.fulfilled, (state, action) => {
        state.isTaxCustomizationEnabled = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchTaxCustomizationStatus.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(toggleTaxCustomizationStatus.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(toggleTaxCustomizationStatus.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(toggleTaxCustomizationStatus.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetTaxesState } = taxesSlice.actions;

export default taxesSlice.reducer;
