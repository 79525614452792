import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  IConsolidatedShiftSummaryResponseDTO,
  IShiftSummaryResponseDTO,
  IShiftSummaryStatusResponseDTO,
  ISingleShiftSummaryResponseDTO,
  ShiftSummaryExtras,
  ShiftSummaryParams,
  ShiftSummaryState,
} from '../types/shift-summary.types';
import * as shiftSummaryTypes from './shiftSummaryActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchShiftSummaryReportStatus = createAppAsyncThunk<
  IShiftSummaryStatusResponseDTO,
  void
>(
  shiftSummaryTypes.REQUEST_SHIFT_SUMMARY_STATUS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<IShiftSummaryStatusResponseDTO>
      >(URLS.SHIFT_SUMMARY.SHIFT_SUMMARY_STATUS);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchShiftSummaryData = createAppAsyncThunk<
  PaginatedResponseDTO<IShiftSummaryResponseDTO, ShiftSummaryExtras>,
  ShiftSummaryParams
>(
  shiftSummaryTypes.REQUEST_SHIFT_SUMMARY,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip } = getState().reportPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<IShiftSummaryResponseDTO, ShiftSummaryExtras>
        >
      >(URLS.SHIFT_SUMMARY.DEFAULT, { params: { ...params, limit, skip } });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchShiftSummaryDataExport = createAppAsyncThunk<
  PaginatedResponseDTO<IShiftSummaryResponseDTO, ShiftSummaryExtras>,
  ShiftSummaryParams
>(
  shiftSummaryTypes.REQUEST_SHIFT_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<IShiftSummaryResponseDTO, ShiftSummaryExtras>
        >
      >(URLS.SHIFT_SUMMARY.DEFAULT, { params: { ...params } });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleShiftSummary = createAppAsyncThunk<
  ISingleShiftSummaryResponseDTO,
  string
>(
  shiftSummaryTypes.REQUEST_SINGLE_SHIFT_SUMMARY,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.SHIFT_SUMMARY.SINGLE_SHIFT_SUMMARY_V2}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<ISingleShiftSummaryResponseDTO>>(url);
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        return rejectWithValue(`${HttpStatusCode.NotFound}`);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getConsolidatedShiftSummary = createAppAsyncThunk<
  IConsolidatedShiftSummaryResponseDTO,
  ShiftSummaryParams
>(
  shiftSummaryTypes.REQUEST_CONSOLIDATED_SHIFT_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      const url = URLS.SHIFT_SUMMARY.CONSOLIDATED_SHIFT_SUMMARY;
      const response = await Api.get<
        CommonResponseDTO<IConsolidatedShiftSummaryResponseDTO>
      >(url, { params });
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        return rejectWithValue(`${HttpStatusCode.NotFound}`);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getConsolidatedShiftSummaryExport = createAppAsyncThunk<
  IConsolidatedShiftSummaryResponseDTO,
  ShiftSummaryParams
>(
  shiftSummaryTypes.REQUEST_CONSOLIDATED_SHIFT_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      const url = URLS.SHIFT_SUMMARY.CONSOLIDATED_SHIFT_SUMMARY;
      const response = await Api.get<
        CommonResponseDTO<IConsolidatedShiftSummaryResponseDTO>
      >(url, { params });
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        return rejectWithValue(`${HttpStatusCode.NotFound}`);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleShiftSummaryExport = createAppAsyncThunk<
  ISingleShiftSummaryResponseDTO,
  string
>(
  shiftSummaryTypes.REQUEST_SINGLE_SHIFT_SUMMARY_EXPORT,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.SHIFT_SUMMARY.SINGLE_SHIFT_SUMMARY_V2}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<ISingleShiftSummaryResponseDTO>>(url);
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        return rejectWithValue(`${HttpStatusCode.NotFound}`);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

const initialState: ShiftSummaryState = {
  ShiftSummaryData: [],
  extras: {
    limit: 10,
    total: 0,
  },
  shiftsReportStatus: { showLoadingScreen: false },
  refreshing: false,
};

const shiftSummarySlice = createSlice({
  name: 'shiftSummary',
  initialState,
  reducers: {
    resetShiftSummaryRecordStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftSummaryReportStatus.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchShiftSummaryReportStatus.fulfilled, (state, action) => {
        state.shiftsReportStatus = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchShiftSummaryReportStatus.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchShiftSummaryData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchShiftSummaryData.fulfilled, (state, action) => {
        state.ShiftSummaryData = action.payload.results;
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.extras.lastUpdated = action.payload.extras.lastUpdated;
        state.refreshing = false;
      })
      .addCase(fetchShiftSummaryData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleShiftSummary.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(getSingleShiftSummary.fulfilled, (state, action) => {
        state.singleShiftSummaryData = action.payload;
        state.refreshing = false;
      })
      .addCase(getSingleShiftSummary.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getConsolidatedShiftSummary.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(getConsolidatedShiftSummary.fulfilled, (state, action) => {
        state.consolidatedShiftSummaryData = action.payload;
        state.refreshing = false;
      })
      .addCase(getConsolidatedShiftSummary.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetShiftSummaryRecordStatus } = shiftSummarySlice.actions;

export default shiftSummarySlice.reducer;
