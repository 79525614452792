import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps, Theme } from '@mui/material/styles';
import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from '@mui/material/TextField';

import { ForwardedRef, forwardRef } from 'react';

import AppInputLabel from './AppInput/AppInputLabel';
import AppInputErrorMessage from './AppInput/AppInputErrorMessage';
import AppInputDescription from './AppInput/AppInputDescription';

export type AppTextInputProps = {
  icon?: React.ReactNode;
  labelRightComponent?: React.ReactNode;
  onClickIcon?: () => void;
  iconDisabled?: boolean;
  touched?: boolean;
  label?: string;
  variant?: TextFieldVariants;
  error?: string;
  description?: string;
  showOptional?: boolean;
  iconPosition?: 'start' | 'end';
  textSx?: SxProps<Theme>;
  prefix?: string;
  suffix?: string;
  multiline?: boolean;
} & Omit<TextFieldProps, 'error'>;

const AppTextInput = forwardRef(
  (
    {
      icon,
      iconDisabled,
      onClickIcon,
      label,
      labelRightComponent,
      error,
      description,
      iconPosition = 'end',
      sx = {},
      textSx = {},
      showOptional,
      prefix,
      suffix,
      disabled,
      multiline,
      ...props
    }: AppTextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Box
        sx={{
          width: '100%',
          ...sx,
        }}>
        <AppInputLabel
          label={label}
          labelRightComponent={labelRightComponent}
          showOptional={showOptional}
          disabled={disabled}
        />

        <TextField
          {...props}
          disabled={disabled}
          inputRef={ref}
          hiddenLabel={!label}
          multiline={multiline}
          sx={{
            width: '100%',
            '& fieldset': {
              borderRadius: '6px',
            },
            '& .MuiInputBase-input': {
              height: '1em',
            },
            ...textSx,
          }}
          error={!!error}
          InputProps={
            icon
              ? iconPosition === 'start'
                ? {
                    startAdornment: (
                      <InputAdornment
                        position={iconPosition}
                        onClick={onClickIcon}
                        disablePointerEvents={iconDisabled}>
                        {icon}
                      </InputAdornment>
                    ),
                  }
                : iconPosition === 'end'
                  ? {
                      endAdornment: (
                        <InputAdornment
                          position={iconPosition}
                          onClick={onClickIcon}
                          disablePointerEvents={iconDisabled}>
                          {icon}
                        </InputAdornment>
                      ),
                    }
                  : {}
              : prefix
                ? {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        disablePointerEvents={true}>
                        {prefix}
                      </InputAdornment>
                    ),
                  }
                : suffix
                  ? {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}>
                          {suffix}
                        </InputAdornment>
                      ),
                    }
                  : {}
          }
        />

        <AppInputDescription description={description} />
        <AppInputErrorMessage error={error} />
      </Box>
    );
  },
);

export default AppTextInput;
