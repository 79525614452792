import { createSlice } from '@reduxjs/toolkit';

import { MenuPaginationState } from '../types/menu-pagination.types';

export const menuPaginationInitialState: MenuPaginationState = {
  limit: 10,
  skip: 0,
  search_key: '',
};

export const menuPaginationSlice = createSlice({
  name: 'menuPagination',
  initialState: menuPaginationInitialState,
  reducers: {
    resetMenuPaginationState: () => menuPaginationInitialState,
    setMenuPaginationLimit: (state, action) => {
      state.limit = action.payload;
    },
    setMenuPaginationSkip: (state, action) => {
      state.skip = action.payload;
    },
    disableMenuPagination: (state) => {
      state.skip = undefined;
      state.limit = undefined;
    },
    setMenuPaginationSearchKey: (
      state,
      action: {
        payload: string;
      },
    ) => {
      state.search_key = action.payload.trim();
    },
  },
});

export const {
  resetMenuPaginationState,
  setMenuPaginationLimit,
  setMenuPaginationSkip,
  disableMenuPagination,
  setMenuPaginationSearchKey,
} = menuPaginationSlice.actions;

export default menuPaginationSlice.reducer;
