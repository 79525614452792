import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';

import loginLogo from '../assets/svgs/logo-text.svg';
import AppAuthFooter from '../components/AppAuthFooter';
import { Colors } from '../theme/colors';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  sxP?: SxProps<Theme>;
};

const AuthLayout = ({ children, sx = {}, sxP = {} }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '500px',
        ...sx,
      }}>
      <Box sx={{ mb: 2 }}>
        <img src={loginLogo} alt="Logo" />
      </Box>

      <Paper
        sx={{
          borderRadius: '28px',
          display: 'flex',
          flexDirection: 'column',
          p: 5,
          boxShadow: `0px 15px 35px 0px ${Colors.shadowPrimary}`,
          ...sxP,
        }}>
        {children}
      </Paper>
      <AppAuthFooter />
    </Box>
  );
};

export default AuthLayout;
