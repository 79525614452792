export const REQUEST_POS_DEVICES = 'SETTINGS/DEVICES/REQUEST_POS_DEVICES';
export const REQUEST_SINGLE_POS_DEVICE =
  'SETTINGS/DEVICES/REQUEST_SINGLE_POS_DEVICE';
export const REQUEST_TO_CREATE_POS_DEVICES =
  'SETTINGS/DEVICES/REQUEST_TO_CREATE_POS_DEVICES';
export const REQUEST_TO_PATCH_UPDATE_DEVICES =
  'SETTINGS/DEVICES/REQUEST_TO_PATCH_UPDATE_DEVICES';
export const REQUEST_TO_DELETE_POS_DEVICES =
  'SETTINGS/DEVICES/REQUEST_TO_DELETE_POS_DEVICES';
export const REQUEST_TO_RESET_LOG_IN =
  'SETTINGS/DEVICES/REQUEST_TO_RESET_LOG_IN';
export const REQUEST_TO_CLEAR_OPEN_TICKETS =
  'SETTINGS/DEVICES/REQUEST_TO_CLEAR_OPEN_TICKETS';
export const REQUEST_TO_DELETE_PENDING_TICKETS =
  'SETTINGS/DEVICES/REQUEST_TO_DELETE_PENDING_TICKETS';
export const REQUEST_TO_RESET_DEVICE_PASSWORD =
  'SETTINGS/DEVICES/REQUEST_TO_RESET_DEVICE_PASSWORD';
