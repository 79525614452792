import axios from 'axios';

import Environment from '../config/environment';
import { CaptureXHRBreadcrumb } from '../config/sentry-setup';

export const Api = axios.create({
  baseURL: Environment.baseURL,
});

Api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('userToken');

  config.headers.Authorization = `Bearer ${token}`;
  config.params = { ...config.params };
  CaptureXHRBreadcrumb({
    url: `${config.baseURL}${config.url}`,
    method: `${config.method}`,
    requestBody: config.data,
  });

  return config;
});

Api.interceptors.response.use(
  (response) => {
    CaptureXHRBreadcrumb({
      url: `${response.config.baseURL}${response.config.url}`,
      method: `${response.config.method}`,
      requestBody: response.config.data,
      status_code: response.status,
      reason: response.statusText,
      responseBody: response.data,
    });
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default Api;
