export enum ORDER_TYPE {
  DINEIN = 'DINEIN',
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

export enum ENTITY_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
}

export enum PRODUCTS {
  POS = 'POS',
  KIOSK = 'KIOSK',
  WEB = 'WEB',
}

export enum DISH_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  UNAVAILABLE_FOR_TODAY = 'UNAVAILABLE_FOR_TODAY',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
}

export enum ADDITIONAL_CHARGE_TYPE {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}

export enum ADDITIONAL_CHARGE_KEY {
  SERVICE_CHARGE = 'SERVICE_CHARGE',
  GST = 'GST',
  DISCOUNT = 'DISCOUNT',
  CUSTOM = 'CUSTOM',
}

export enum ADDITIONAL_CHARGE_VALUE_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE',
}

export enum PLACEMENT_TYPES {
  KIOSK = 'KIOSK',
  MERCHANT = 'MERCHANT',
  AOD = 'AOD',
  WEB = 'WEB',
  LITE_POS = 'LITE_POS',
  POS = 'POS',
}

export enum PAYMENT_STATUS {
  success = 'success',
  failed = 'failed',
  refunded = 'refunded',
}

export enum ORDER_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  PREPARING = 'PREPARING',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}

export enum ORDER_PAYMENT_ACTIONS {
  PROCESS_PAYMENT = 'process_payment',
  CASH_PAYMENT_SUCCESS = 'cash_payment_success',
  CARD_PAYMENT_SUCCESS = 'card_payment_success',
  CARD_PAYMENT_FAILURE = 'card_payment_failure',
}

export enum ORDER_DISH_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  PREPARING = 'PREPARING',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export enum RESTAURANT_PAYMENT_PROVIDER {
  STRIPE = 'STRIPE',
  ADYEN = 'ADYEN',
}

export enum ADYEN_LEGAL_ENTITY_TYPES {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organization',
  SOLE_PROPRIETORSHIP = 'sole_proprietorship',
  TRUST = 'trust',
}

/**
 * Adyen Legal Entity Status Definitions
 *
 * DRAFT - Account is created partially from the admin panel
 * PENDING - Account is not successfully onboarded yet and there are problems
 * REVIEW_PENDING - Account is not successfully onboarded yet and some capabilities are still pending but no problems
 * SUCCESS - Account is successfully onboarded with all capabilities
 * ACTION_REQUIRED - Account is onboarded but there are some problems that need attention
 * SUCCESS_REVIEW_PENDING - Account is successfully onboarded but some additional capabilities are still pending review
 *
 * Navigation Behavior:
 * - SUCCESS, ACTION_REQUIRED, SUCCESS_REVIEW_PENDING: Can access transactions, payouts, and account settings
 * - REVIEW_PENDING, PENDING: Can only access overview page
 * - DRAFT: Impossible state for Backoffice web
 */
export enum ADYEN_LEGAL_ENTITY_STATUS {
  PENDING = 'PENDING',
  ARCHIVE = 'ARCHIVE',
  SUCCESS = 'SUCCESS',
  DRAFT = 'DRAFT',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  REVIEW_PENDING = 'REVIEW_PENDING',
  SUCCESS_REVIEW_PENDING = 'SUCCESS_REVIEW_PENDING',
}

export enum ADYEN_PAYOUT_SCHEDULE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ADYEN_BANK_ACCOUNT_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
}
