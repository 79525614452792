export const REQUEST_DISCOUNTS_STATUS = 'DISCOUNTS/REQUEST_DISCOUNTS_STATUS';
export const REQUEST_DISCOUNTS = 'DISCOUNTS/REQUEST_DISCOUNTS';
export const REQUEST_SINGLE_DISCOUNT = 'DISCOUNTS/REQUEST_SINGLE_DISCOUNT';
export const REQUEST_TO_CREATE_DISCOUNTS =
  'DISCOUNTS/REQUEST_TO_CREATE_DISCOUNTS';
export const REQUEST_TO_UPDATE_DISCOUNTS =
  'DISCOUNTS/REQUEST_TO_UPDATE_DISCOUNTS';
export const REQUEST_TO_PATCH_UPDATE_DISCOUNTS =
  'DISCOUNTS/REQUEST_TO_PATCH_UPDATE_DISCOUNTS';
export const REQUEST_TO_DELETE_DISCOUNTS =
  'DISCOUNTS/REQUEST_TO_DELETE_DISCOUNTS';
