import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { RECONCILIATION_SUMMARY_COLUMNS } from '../constants';
import {
  FilterItems,
  IReconciliationSummaryResponseDTO,
  ReconciliationSummaryExtrasDTO,
} from '../types/reports.types';
import * as paymentsTypes from './reportsActionTypes';

export interface ReconciliationSummaryState {
  status: string;
  refreshing: boolean;
  columns: IAppTableColumn[];
  reconciliationSummaryData: IReconciliationSummaryResponseDTO[];
  lastUpdated: string;
  products: FilterItems[] | [];
  channels: FilterItems[] | [];
  selectedProducts: string[];
  selectedChannels: string[];
}

const initialState: ReconciliationSummaryState = {
  status: 'idle',
  refreshing: false,
  columns: RECONCILIATION_SUMMARY_COLUMNS,
  reconciliationSummaryData: [],
  lastUpdated: '',
  products: [],
  channels: [],
  selectedProducts: [],
  selectedChannels: [],
};

interface ReconciliationSummaryProps {
  country: string;
  date_from?: string;
  date_to?: string;
  product?: string;
  channel?: string;
  is_export?: string;
  offset?: string;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: ReconciliationSummaryProps,
): Promise<
  PaginatedResponseDTO<
    IReconciliationSummaryResponseDTO,
    ReconciliationSummaryExtrasDTO
  >
> => {
  const url = `${URLS.REPORTS.RECONCILIATION_SUMMARY}`;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<
        IReconciliationSummaryResponseDTO,
        ReconciliationSummaryExtrasDTO
      >
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchReconciliationSummaryDetails = createAppAsyncThunk<
  PaginatedResponseDTO<
    IReconciliationSummaryResponseDTO,
    ReconciliationSummaryExtrasDTO
  >,
  ReconciliationSummaryProps
>(
  paymentsTypes.REQUEST_RECONCILIATION_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const fetchReconciliationSummaryExport = createAppAsyncThunk<
  PaginatedResponseDTO<
    IReconciliationSummaryResponseDTO,
    ReconciliationSummaryExtrasDTO
  >,
  ReconciliationSummaryProps
>(
  paymentsTypes.REQUEST_RECONCILIATION_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const reconciliationSummarySlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setColumns: (
      state: ReconciliationSummaryState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
    setProducts: (state, action: PayloadAction<string[]>) => {
      state.selectedProducts = action.payload;
    },
    setChannels: (state, action: PayloadAction<string[]>) => {
      state.selectedChannels = action.payload;
    },
    resetReconciliationSummaryState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReconciliationSummaryDetails.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchReconciliationSummaryDetails.fulfilled, (state, action) => {
        state.reconciliationSummaryData = action.payload.results;
        state.lastUpdated = action.payload.extras.lastUpdated;
        state.products = action.payload.extras.products;
        state.channels = action.payload.extras.channels;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchReconciliationSummaryDetails.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchReconciliationSummaryExport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReconciliationSummaryExport.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(fetchReconciliationSummaryExport.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setColumns,
  setProducts,
  setChannels,
  resetReconciliationSummaryState,
} = reconciliationSummarySlice.actions;

export default reconciliationSummarySlice.reducer;
