import {
  Action,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
  UnknownAction,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

import appReducer, { AppState } from './AppSlice';
import Environment from '../config/environment';
import authReducer, { AuthState } from '../features/auth/redux/authSlice';
import discountReasonReducer from '../features/discounts/redux/discountReasonsSlice';
import discountsPaginationReducer from '../features/discounts/redux/discountsPaginationSlice';
import discountReducer from '../features/discounts/redux/discountsSlice';
import { DiscountReasonsState } from '../features/discounts/types/discount-reasons.types';
import { DiscountsState } from '../features/discounts/types/discounts.types';
import { DiscountsPaginationState } from '../features/discounts/types/discounts-pagination.types';
import employeePaginationReducer, {
  EmployeePaginationState,
} from '../features/employee/redux/employeePaginationSlice';
import employeesReducer from '../features/employee/redux/employeeSlice';
import { EmployeesState } from '../features/employee/types/employees.types';
import addOnGroupsReducer from '../features/menu/redux/addOnGroupSlice';
import categories from '../features/menu/redux/categoriesSlice';
import choiceGroupsReducer, {
  ChoiceGroupsState,
} from '../features/menu/redux/choiceGroupSlice';
import dishesReducer from '../features/menu/redux/dishesSlice';
import paginationReducer from '../features/menu/redux/menuPaginationSlice';
import pagesReducer from '../features/menu/redux/pagesSlice';
import subCategoriesReducer from '../features/menu/redux/subCategoriesSlice';
import { AddOnGroupsState } from '../features/menu/types/add-on-groups.types';
import { CategoriesState } from '../features/menu/types/categories.types';
import { DishesState } from '../features/menu/types/dishes.types';
import { MenuPaginationState } from '../features/menu/types/menu-pagination.types';
import { PagesState } from '../features/menu/types/pages.types';
import { SubCategoriesState } from '../features/menu/types/subCategory.types';
import { activeOrderReducer } from '../features/orders/redux/ordersSlice';
import { ActiveOrderState } from '../features/orders/types/orders.types';
import paymentReducer, {
  PaymentState,
} from '../features/payments/redux/paymentsSlice';
import dailyStripeSummaryReducer, {
  DailyStripeSummaryState,
} from '../features/reports/redux/dailyStripeSummarySlice';
import {
  hourlySalesSummaryReducer,
  HourlySalesSummaryState,
} from '../features/reports/redux/hourlySalesSummarySlice';
import {
  orderCountSummaryReducer,
  OrderCountSummaryState,
} from '../features/reports/redux/orderCountSummarySlice';
import receiptsReducer from '../features/reports/redux/receiptsSlice';
import reconciliationSummaryReducer, {
  ReconciliationSummaryState,
} from '../features/reports/redux/reconciliationSummarySlices';
import reportPaginationReducer, {
  ReportPaginationState,
} from '../features/reports/redux/reportPaginationSlice';
import {
  salesByMenuItemReducer,
  SalesByMenuItemSummaryState,
} from '../features/reports/redux/salesByMenuItemSummarySlice';
import salesSummaryReducer from '../features/reports/redux/salesSummarySlice';
import shiftSummaryReducer from '../features/reports/redux/shiftSummarySlice';

import { ReportsReceiptState } from '../features/reports/types/receipts.types';
import { ReportsSalesSummaryState } from '../features/reports/types/sales-summary.types';
import { ShiftSummaryState } from '../features/reports/types/shift-summary.types';
import devicesReducer from '../features/settings/redux/devicesSlice';
import generalSettingsReducer from '../features/settings/redux/generalSettingsSlice';
import notificationReducer, {
  NotificationState,
} from '../features/settings/redux/notificationsSlice';
import orderTypesReducer from '../features/settings/redux/orderTypesSlice';
import paymentTypesReducer from '../features/settings/redux/paymentTypesSlice';
import receiptReducer from '../features/settings/redux/receiptSlice';
import restaurantAreasReducer from '../features/settings/redux/restaurantAreasSlice';
import taxesReducer from '../features/settings/redux/taxesSlice';
import ticketsReducer from '../features/settings/redux/ticketsSlice';
import transactionChargesReducer from '../features/settings/redux/transactionChargesSlice';
import { DevicesState } from '../features/settings/types/devices.types';
import { GeneralSettingsState } from '../features/settings/types/general-settings.types';
import { OrderTypesState } from '../features/settings/types/order-types.types';
import { IPaymentTypesState } from '../features/settings/types/payment-types.types';
import { ReceiptState } from '../features/settings/types/receipt.types';
import { RestaurantAreasState } from '../features/settings/types/restaurant-areas.types';
import { TaxesState } from '../features/settings/types/taxes.types';
import { TicketsState } from '../features/settings/types/tickets.types';
import { TransactionChargesState } from '../features/settings/types/transaction-charges.types';
import { AccountState } from '../features/settings/types/account.types';
import accountReducer from '../features/settings/redux/accountSlice';
import adyenSessionsReducer, {
  AdyenSessionsState,
} from '../features/auth/redux/adyenSessionSlice';
import dailyAdyenSummaryReducer, {
  DailyAdyenSummaryState,
} from '../features/reports/redux/dailyAdyenSummarySlice';
import adyenReconciliationSummaryReducer, {
  AdyenReconciliationSummaryState,
} from '../features/reports/redux/adyenReconciliationSummarySlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'app'],
  blacklist: [],
  transform: [
    createWhitelistFilter('app', ['reportStartDate', 'reportEndDate']),
  ],
};

export type ICombinedReducer = Reducer<
  {
    app: AppState;
    auth: AuthState;
    account: AccountState;
    menuPagination: MenuPaginationState;
    activeOrders: ActiveOrderState;
    payments: PaymentState;
    subCategories: SubCategoriesState;
    categories: CategoriesState;
    choiceGroups: ChoiceGroupsState;
    pages: PagesState;
    dishes: DishesState;
    addOnGroups: AddOnGroupsState;
    tickets: TicketsState;
    receipt: ReceiptState;
    transactionCharges: TransactionChargesState;
    orderTypes: OrderTypesState;
    taxes: TaxesState;
    generalSettings: GeneralSettingsState;
    receipts: ReportsReceiptState;
    salesSummary: ReportsSalesSummaryState;
    dailyStripeSummary: DailyStripeSummaryState;
    reconciliationSummary: ReconciliationSummaryState;
    orderCountSummary: OrderCountSummaryState;
    salesByMenuItemSummary: SalesByMenuItemSummaryState;
    shiftSummary: ShiftSummaryState;
    hourlySales: HourlySalesSummaryState;
    reportPagination: ReportPaginationState;
    devices: DevicesState;
    paymentTypes: IPaymentTypesState;
    employees: EmployeesState;
    employeePagination: EmployeePaginationState;
    restaurantAreas: RestaurantAreasState;
    notifications: NotificationState;
    discounts: DiscountsState;
    discountsPagination: DiscountsPaginationState;
    discountReasons: DiscountReasonsState;
    adyenSession: AdyenSessionsState;
    dailyAdyenSummary: DailyAdyenSummaryState;
    adyenReconciliationSummary: AdyenReconciliationSummaryState;
  },
  UnknownAction
>;

const rootReducer: ICombinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  account: accountReducer,
  menuPagination: paginationReducer,
  activeOrders: activeOrderReducer,
  payments: paymentReducer,
  subCategories: subCategoriesReducer,
  categories,
  choiceGroups: choiceGroupsReducer,
  pages: pagesReducer,
  dishes: dishesReducer,
  addOnGroups: addOnGroupsReducer,
  tickets: ticketsReducer,
  receipt: receiptReducer,
  transactionCharges: transactionChargesReducer,
  orderTypes: orderTypesReducer,
  taxes: taxesReducer,
  generalSettings: generalSettingsReducer,
  receipts: receiptsReducer,
  salesSummary: salesSummaryReducer,
  dailyStripeSummary: dailyStripeSummaryReducer,
  reconciliationSummary: reconciliationSummaryReducer,
  orderCountSummary: orderCountSummaryReducer,
  salesByMenuItemSummary: salesByMenuItemReducer,
  shiftSummary: shiftSummaryReducer,
  hourlySales: hourlySalesSummaryReducer,
  reportPagination: reportPaginationReducer,
  devices: devicesReducer,
  paymentTypes: paymentTypesReducer,
  employees: employeesReducer,
  employeePagination: employeePaginationReducer,
  restaurantAreas: restaurantAreasReducer,
  notifications: notificationReducer,
  discounts: discountReducer,
  discountsPagination: discountsPaginationReducer,
  discountReasons: discountReasonReducer,
  adyenSession: adyenSessionsReducer,
  dailyAdyenSummary: dailyAdyenSummaryReducer,
  adyenReconciliationSummary: adyenReconciliationSummaryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (
  preloadedState?: ReturnType<typeof persistedReducer>,
) =>
  configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: Environment.isDebug,
  });

export const store = setupStore();
export const rootDispatch = store.dispatch;

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
