export const REQUEST_DISCOUNT_REASONS =
  'DISCOUNT_REASONS/REQUEST_DISCOUNT_REASONS';
export const REQUEST_SINGLE_DISCOUNT_REASON =
  'DISCOUNT_REASONS/REQUEST_SINGLE_DISCOUNT_REASON';
export const REQUEST_TO_CREATE_DISCOUNT_REASONS =
  'DISCOUNT_REASONS/REQUEST_TO_CREATE_DISCOUNT_REASONS';
export const REQUEST_TO_UPDATE_DISCOUNT_REASONS =
  'DISCOUNT_REASONS/REQUEST_TO_UPDATE_DISCOUNT_REASONS';
export const REQUEST_TO_PATCH_UPDATE_DISCOUNT_REASONS =
  'DISCOUNT_REASONS/REQUEST_TO_PATCH_UPDATE_DISCOUNT_REASONS';
export const REQUEST_TO_DELETE_DISCOUNT_REASONS =
  'DISCOUNT_REASONS/REQUEST_TO_DELETE_DISCOUNT_REASONS';
