import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  HourlySalesSummaryDTO,
  HourlySalesSummaryExtrasDTO,
  HourlySalesSummaryResponseDTO,
} from '../types/reports.types';
import * as requestTypes from './reportsActionTypes';

export interface HourlySalesSummaryState {
  status: string;
  refreshing: boolean;
  hourlySalesData: HourlySalesSummaryDTO[];
  columns: IAppTableColumn[];
  exportStatus: string;
  totalCollected: string;
  totalAvgHourlySale: string;
  lastUpdated: string;
}

const initialState: HourlySalesSummaryState = {
  status: 'idle',
  refreshing: false,
  columns: [],
  hourlySalesData: [],
  exportStatus: 'idle',
  totalCollected: '0',
  totalAvgHourlySale: '0',
  lastUpdated: '',
};

interface HourlySalesSummaryProps {
  date_from?: string;
  date_to?: string;
  offset?: string;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: HourlySalesSummaryProps,
): Promise<
  PaginatedResponseDTO<
    HourlySalesSummaryResponseDTO,
    HourlySalesSummaryExtrasDTO
  >
> => {
  const url = URLS.REPORTS.HOURLY_SALES_SUMMARY;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<
        HourlySalesSummaryResponseDTO,
        HourlySalesSummaryExtrasDTO
      >
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchHourlySalesSummary = createAppAsyncThunk<
  PaginatedResponseDTO<
    HourlySalesSummaryResponseDTO,
    HourlySalesSummaryExtrasDTO
  >,
  HourlySalesSummaryProps
>(
  requestTypes.REQUEST_HOURLY_SALES_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const fetchHourlySalesSummaryExport = createAppAsyncThunk<
  PaginatedResponseDTO<
    HourlySalesSummaryResponseDTO,
    HourlySalesSummaryExtrasDTO
  >,
  HourlySalesSummaryProps
>(
  requestTypes.REQUEST_HOURLY_SALES_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const hourlySalesSummary = createSlice({
  name: 'hourlySalesSummary',
  initialState,
  reducers: {
    resetHourlySalesSummaryReportsState: () => initialState,
    addColumnsToHourlySalesSummary: (
      state: HourlySalesSummaryState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = [...action.payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHourlySalesSummary.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchHourlySalesSummary.fulfilled, (state, action) => {
        state.hourlySalesData = action.payload.results;
        state.totalCollected = action.payload.extras.totalCollected;
        state.totalAvgHourlySale = action.payload.extras.avgSales;
        state.lastUpdated = action.payload.extras.lastUpdated;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchHourlySalesSummary.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchHourlySalesSummaryExport.pending, (state) => {
        state.exportStatus = 'loading';
      })
      .addCase(fetchHourlySalesSummaryExport.fulfilled, (state) => {
        state.exportStatus = 'success';
      })
      .addCase(fetchHourlySalesSummaryExport.rejected, (state) => {
        state.exportStatus = 'failed';
      });
  },
});

export const {
  resetHourlySalesSummaryReportsState,
  addColumnsToHourlySalesSummary,
} = hourlySalesSummary.actions;

export const { reducer: hourlySalesSummaryReducer } = hourlySalesSummary;

export default hourlySalesSummary.reducer;
