import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { IVerticalTabItem } from '../components/AppVerticalTabs';

/**
 * Hook for tab navigation, Assign the generic types to the tab and nested tab
 * T - current tab
 * U - current nested tab
 */
export const useTabNavigation = <T extends string, U = unknown>(
  tabs: IVerticalTabItem[],
  initialTabs: T,
  initialNestedTab?: U,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultTab = (searchParams.get('tab') as T) ?? initialTabs;
  const [currentTab, setCurrentTab] = useState<T>(() => defaultTab);
  const [currentNestedTab, setCurrentNestedTab] = useState<U | undefined>(
    initialNestedTab,
  );

  useEffect(() => {
    const path = location.pathname + location.hash;
    const foundTab = tabs.find((tab) => tab.path === path);
    const nestedTabs = tabs.flatMap((tab) => tab.nestedTabs ?? []);

    const foundNestedTab = nestedTabs?.find((tab) => tab.path === path);

    if (foundTab?.id) {
      setCurrentTab(foundTab.id as T);
      setSearchParams({ tab: String(foundTab.id) });
    }

    if (foundNestedTab?.id) {
      setCurrentNestedTab(foundNestedTab.id as U);

      const foundParentTab = tabs.find((tab) =>
        tab.nestedTabs?.some((nestedTab) => nestedTab.id === foundNestedTab.id),
      );

      if (foundParentTab?.id) {
        setCurrentTab(foundParentTab.id as T);
        setSearchParams({ tab: String(foundParentTab.id) });
      }
    }
  }, [location.hash, location.pathname, tabs, setSearchParams]);

  const handleTabChange = useCallback(
    (tab: Omit<IVerticalTabItem, 'tags' | 'icon'>) => {
      setCurrentTab(tab.id as T);
      setSearchParams({ tab: String(tab.id) });
      navigate(tab.path);
    },
    [navigate, setSearchParams],
  );

  return {
    currentTab,
    currentNestedTab,
    handleTabChange,
  };
};
