import { BasePaginationExtras } from '../../../types/api.types';
import { IOrderSummaryResponseDTO } from '../../../types/components/order-history.types';
import { IBaseEntity } from '../../menu/types/common.types';

export interface ReportsReceiptState {
  receiptsStatus: IReceiptsStatusResponseDTO;
  receiptsData: ReceiptsResponseDTO[];
  refreshing: boolean;
  loadingSingleReceipt: boolean;
  totalCount: number;
  succeededCount: number;
  refundedCount: number;
  deletedCount: number;
  activeTab: string[];
  extras: ReceiptsExtras & {
    date_from?: Date;
    date_to?: Date;
    employeeIds?: string;
  };
  selectedTab: SELECTED_TAB;
}

export interface ReceiptsResponseDTO extends IBaseEntity {
  currency: string;
  orderId: number;
  ticketNumber: string;
  ticketName: string;
  orderStatus: string;
  paymentMethods: string;
  cardBrands: string;
  createdById: number;
  createdByName: string;
  netTotal: string;
  completedAt: Date;
}

export interface IReceiptsTable extends Record<string, unknown> {
  placedAtTime: string;
  receipt: string;
  ticket: string;
  status: string;
  paymentType: string;
  total: number;
}

export interface GetAllReceiptsQueryDTO {
  status?: string;
  sort_order?: string;
  sort_by?: string;
  limit?: number;
  skip?: number;
  search_key?: string;
  date_from?: string;
  date_to?: string;
  employeeId?: number;
}

export enum SELECTED_TAB {
  DELETED = 'deleted',
  COMPLETED = 'completed',
  REFUNDED = 'refunded',
  ALL = 'all',
}

export interface ReceiptsParams {
  date_from?: string;
  date_to?: string;
  status?: string;
  limit?: number;
  skip?: number;
  employeeIds?: string;
  offset?: string;
}

export interface IReceiptsStatusResponseDTO {
  showLoadingScreen: boolean;
}

export interface ReceiptsExtras extends BasePaginationExtras {
  lastUpdated?: Date;
}

export interface ISingleReceiptResponseDTO {
  singleReceipt: IOrderSummaryResponseDTO;
}

export interface TabItem {
  label: string;
  value: string;
}

export interface ReceiptsCountsParams {
  date_from?: string;
  date_to?: string;
  employeeIds?: string;
  offset?: string;
}

export interface ReceiptsCountsExtras {
  employeeIds: string | undefined;
  date_from: Date;
  date_to: Date;
}

export interface IEmployeesResponseDTO {
  createdById: number;
  createdByName: string;
}

export interface IEmployeeData {
  createdBy: number;
  createdByName: string;
}

export interface ReceiptsCountsFullResponse {
  results: ReceiptsCountsResponseDTO;
  extras: ReceiptsCountsExtras;
}

export interface ReceiptsCountsResponseDTO {
  totalCount: number;
  succeededCount: number;
  RefundedCount: number;
  deletedCount: number;
}
