import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  GetTransactionChargeQueryDTO,
  TransactionChargeResponseDTO,
  TransactionChargesState,
} from '../types/transaction-charges.types';
import * as transactionChargesTypes from './transactionChargesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchTransactionChargeData = createAppAsyncThunk<
  TransactionChargeResponseDTO,
  GetTransactionChargeQueryDTO
>(
  transactionChargesTypes.REQUEST_TRANSACTION_CHARGE,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<{
          transactionChargeDetails: TransactionChargeResponseDTO;
        }>
      >(URLS.TRANSACTION_CHARGES, {
        params,
      });
      return response.data.data.transactionChargeDetails;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const transactionChargesInitialState: TransactionChargesState = {
  transactionChargeData: null,
  refreshing: false,
};

export const transactionChargesSlice = createSlice({
  name: 'transactionCharges',
  initialState: transactionChargesInitialState,
  reducers: {
    resetTransactionChargeState: () => transactionChargesInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionChargeData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchTransactionChargeData.fulfilled, (state, action) => {
        state.transactionChargeData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchTransactionChargeData.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetTransactionChargeState } = transactionChargesSlice.actions;

export default transactionChargesSlice.reducer;
