import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export interface ColumnItem {
  header: string;
  columnData: { label: string; key: string; fixed: boolean }[];
}

export const TABLE_TYPE = {
  TABLE: 'table',
  EXPORT: 'export',
};

export const PAYMENTS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'currency',
    header: 'CURRENCY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
    exportOnly: true,
  },
  {
    accessorKey: 'amount',
    header: 'AMOUNT',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'paymentId',
    header: 'PAYMENT ID',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'paymentMethod',
    header: 'PAYMENT METHOD',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'placedAtTime',
    header: 'DATE AND TIME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Default',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'cardNetwork',
    header: 'NETWORK',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Payment Details',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'cardType',
    header: 'TYPE',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Payment Details',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'cardCountry',
    header: 'COUNTRY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Payment Details',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'trxFeePercentage',
    header: 'TRX FEE %',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Charges',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'trxFixedPercentageValue',
    header: 'TRX FEE % VALUE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Charges',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'trxFixedFeeValue',
    header: 'TRX FIXED FEE VALUE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Charges',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'totalTrxFee',
    header: 'TOTAL TRX FEE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Charges',
    visible: true,
    visibilityChangeable: true,
  },
  {
    accessorKey: 'payable',
    header: 'PAYABLE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Payout',
    visible: true,
    visibilityChangeable: true,
  },
];
