import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../redux/store';
import { AccountState } from '../types/account.types';
import * as AccountTypes from './accountActionTypes';
import { AdyenStoreResponseDTO } from '../types/adyen-store.types';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { AdyenBusinessLineResponseDTO } from '../types/adyen-business-line.types';
import { AdyenPayoutScheduleResponseDTO } from '../types/adyen-payout-schedule.type';
import {
  AccountWarningsResponseDTO,
  AdyenAccountResponseDTO,
} from '../../auth/types/auth.types';
import { AdyenBankAccountResponseDTO } from '../types/adyen-bank-account.types';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchAccountData = createAppAsyncThunk<
  AdyenAccountResponseDTO,
  undefined
>(AccountTypes.REQUEST_ACCOUNT, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<CommonResponseDTO<AdyenAccountResponseDTO>>(
      `${URLS.ADYEN.ACCOUNTS.DEFAULT}`,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar(
      'Something went wrong fetching account data',
      SNACKBARTYPE.ERROR,
    );
    return rejectWithValue('An error occurred');
  }
});

export const fetchStoreData = createAppAsyncThunk<
  AdyenStoreResponseDTO,
  undefined
>(AccountTypes.REQUEST_STORES, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<CommonResponseDTO<AdyenStoreResponseDTO>>(
      `${URLS.ADYEN.STORE}`,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar(
      'Something went wrong fetching stores',
      SNACKBARTYPE.ERROR,
    );
    return rejectWithValue('An error occurred');
  }
});

export const fetchBusinessLines = createAppAsyncThunk<
  AdyenBusinessLineResponseDTO[],
  undefined
>(AccountTypes.REQUEST_BUSINESS_LINES, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<AdyenBusinessLineResponseDTO[]>
    >(`${URLS.ADYEN.BUSINESS_LINE}`);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar(
      'Something went wrong fetching business lines',
      SNACKBARTYPE.ERROR,
    );
    return rejectWithValue('An error occurred');
  }
});

export const fetchPayoutSchedules = createAppAsyncThunk<
  AdyenPayoutScheduleResponseDTO[],
  undefined
>(AccountTypes.REQUEST_PAYOUT_SCHEDULES, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<AdyenPayoutScheduleResponseDTO[]>
    >(`${URLS.ADYEN.PAYOUT_SCHEDULES}`);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar(
      'Something went wrong fetching payout schedules',
      SNACKBARTYPE.ERROR,
    );
    return rejectWithValue('An error occurred');
  }
});

export const updatePayoutSchedule = createAppAsyncThunk<
  AdyenPayoutScheduleResponseDTO,
  { id: number; data: { transferInstrumentId: number } }
>(
  AccountTypes.REQUEST_UPDATE_PAYOUT_SCHEDULE,
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<AdyenPayoutScheduleResponseDTO>
      >(`${URLS.ADYEN.PAYOUT_SCHEDULES}/${body.id}`, body.data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar(
        'Something went wrong updating payout schedule',
        SNACKBARTYPE.ERROR,
      );
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchBankAccounts = createAppAsyncThunk<
  AdyenBankAccountResponseDTO[],
  undefined
>(AccountTypes.REQUEST_BANK_ACCOUNTS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<AdyenBankAccountResponseDTO, unknown>
      >
    >(`${URLS.ADYEN.BANK_ACCOUNTS}`);
    return response.data.data?.results;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar(
      'Something went wrong fetching bank accounts',
      SNACKBARTYPE.ERROR,
    );
    return rejectWithValue('An error occurred');
  }
});

export const fetchAccountWarnings = createAppAsyncThunk<
  AccountWarningsResponseDTO[],
  undefined
>(AccountTypes.REQUEST_ACCOUNT_WARNINGS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<AccountWarningsResponseDTO[]>
    >(`${URLS.ADYEN.ACCOUNTS.WARNINGS}`);
    return response.data.data;
  } catch {
    return rejectWithValue('An error occurred');
  }
});

export const accountsInitialState: AccountState = {
  isLoadingStore: false,
  isLoadingBusinessLines: false,
  isLoadingPayoutSchedules: false,
  isLoadingAccount: false,
  isLoadingBankAccounts: false,
  isLoadingAccountWarnings: false,
  account: undefined,
  businessLines: [],
  store: undefined,
  payoutSchedules: [],
  bankAccounts: [],
  accountWarnings: [],
};

const accountSlice = createSlice({
  name: 'account',
  initialState: accountsInitialState,
  reducers: {
    resetAccountState: () => accountsInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountData.pending, (state) => {
        state.isLoadingAccount = true;
      })
      .addCase(fetchAccountData.fulfilled, (state, action) => {
        state.account = action.payload;
        state.isLoadingAccount = false;
      })
      .addCase(fetchAccountData.rejected, (state) => {
        state.isLoadingAccount = false;
      })
      .addCase(fetchBankAccounts.pending, (state) => {
        state.isLoadingBankAccounts = true;
      })
      .addCase(fetchBankAccounts.fulfilled, (state, action) => {
        state.bankAccounts = action.payload;
        state.isLoadingBankAccounts = false;
      })
      .addCase(fetchBankAccounts.rejected, (state) => {
        state.isLoadingBankAccounts = false;
      })
      .addCase(fetchPayoutSchedules.pending, (state) => {
        state.isLoadingPayoutSchedules = true;
      })
      .addCase(fetchPayoutSchedules.fulfilled, (state, action) => {
        state.payoutSchedules = action.payload;
        state.isLoadingPayoutSchedules = false;
      })
      .addCase(fetchPayoutSchedules.rejected, (state) => {
        state.isLoadingPayoutSchedules = false;
      })
      .addCase(fetchStoreData.pending, (state) => {
        state.isLoadingStore = true;
      })
      .addCase(fetchStoreData.fulfilled, (state, action) => {
        state.store = action.payload;
        state.isLoadingStore = false;
      })
      .addCase(fetchStoreData.rejected, (state) => {
        state.isLoadingStore = false;
      })
      .addCase(fetchBusinessLines.pending, (state) => {
        state.isLoadingBusinessLines = true;
      })
      .addCase(fetchBusinessLines.fulfilled, (state, action) => {
        state.businessLines = action.payload;
        state.isLoadingBusinessLines = false;
      })
      .addCase(fetchBusinessLines.rejected, (state) => {
        state.isLoadingBusinessLines = false;
      })
      .addCase(fetchAccountWarnings.pending, (state) => {
        state.isLoadingBankAccounts = true;
      })
      .addCase(fetchAccountWarnings.fulfilled, (state, action) => {
        state.accountWarnings = action.payload;
        state.isLoadingBankAccounts = false;
      })
      .addCase(fetchAccountWarnings.rejected, (state) => {
        state.isLoadingBankAccounts = false;
      });
  },
});

export const { resetAccountState } = accountSlice.actions;

export default accountSlice.reducer;
