export const REQUEST_ALL_CATEGORIES = 'CATEGORIES/REQUEST_ALL_CATEGORIES';
export const REQUEST_CATEGORIES = 'CATEGORIES/REQUEST_CATEGORIES';
export const REQUEST_SINGLE_CATEGORY = 'CATEGORIES/REQUEST_SINGLE_CATEGORY';
export const REQUEST_TO_CREATE_CATEGORIES =
  'CATEGORIES/REQUEST_TO_CREATE_CATEGORIES';
export const REQUEST_TO_UPDATE_CATEGORIES =
  'CATEGORIES/REQUEST_TO_UPDATE_CATEGORIES';
export const REQUEST_TO_PATCH_UPDATE_CATEGORIES =
  'CATEGORIES/REQUEST_TO_PATCH_UPDATE_CATEGORIES';
export const REQUEST_TO_DELETE_CATEGORIES =
  'CATEGORIES/REQUEST_TO_DELETE_CATEGORIES';
export const REQUEST_TO_UPDATE_CATEGORY_SEQUENCE_NUMBER =
  'CATEGORIES/REQUEST_TO_UPDATE_CATEGORY_SEQUENCE_NUMBER';
