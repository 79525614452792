import { createSlice } from '@reduxjs/toolkit';

export type EmployeePaginationState = {
  limit?: number;
  skip?: number;
  search_key?: string;
};

export const employeePaginationInitialState: EmployeePaginationState = {
  limit: 10, // Default limit per page
  skip: 0, // Default start position
  search_key: '',
};

export const employeePaginationSlice = createSlice({
  name: 'employeePagination',
  initialState: employeePaginationInitialState,
  reducers: {
    // Resets the pagination state to its initial values
    resetEmployeePaginationState: () => employeePaginationInitialState,

    // Sets the limit for pagination (number of items per page)
    setEmployeePaginationLimit: (state, action) => {
      state.limit = action.payload;
    },

    // Sets the skip value for pagination (number of items to skip)
    setEmployeePaginationSkip: (state, action) => {
      state.skip = action.payload;
    },
    setMenuPaginationSearchKey: (
      state,
      action: {
        payload: string;
      },
    ) => {
      state.search_key = action.payload.trim();
    },
  },
});

// Exporting the actions
export const {
  resetEmployeePaginationState,
  setEmployeePaginationLimit,
  setEmployeePaginationSkip,
  setMenuPaginationSearchKey,
} = employeePaginationSlice.actions;

// Default export of the reducer
export default employeePaginationSlice.reducer;
