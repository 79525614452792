import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { SNACKBARTYPE } from '../components/AppSnackbar';
import { openGlobalSnackbar } from '../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../config/sentry-setup';
import { URLS } from '../constants';
import { CommonResponseDTO } from '../types/api.types';
import {
  IAccountDeletionRequestDTO,
  IAccountDeletionResponseDTO,
} from '../types/components/app.types';
import Api from './api';
import { REQUEST_ACCOUNT_DELETION } from './appActionTypes';
import { AppDispatch, RootState } from './store';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault('Asia/Singapore');

export type AppState = {
  paymentsReportLastUpdated?: string;
  reportStartDate?: string;
  reportEndDate?: string;
  isLoadingAccountDeletion: boolean;
};

export const appInitialState: AppState = {
  paymentsReportLastUpdated: '',
  reportStartDate: dayjs.tz().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
  reportEndDate: dayjs.tz().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
  isLoadingAccountDeletion: false,
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const accountDeletion = createAppAsyncThunk<
  IAccountDeletionResponseDTO,
  IAccountDeletionRequestDTO
>(REQUEST_ACCOUNT_DELETION, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<
      CommonResponseDTO<IAccountDeletionResponseDTO>
    >(URLS.ACCOUNT_DELETION, body);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

interface SetDatePayload {
  reportStartDate?: string;
  reportEndDate?: string;
}

export const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    resetReportLastUpdateState: () => appInitialState,
    updatePaymentsReportLastUpdated: (state, action: PayloadAction<string>) => {
      state.paymentsReportLastUpdated = action.payload;
    },
    setReportDates: (state, action: PayloadAction<SetDatePayload>) => {
      state.reportStartDate = action.payload.reportStartDate;
      state.reportEndDate = action.payload.reportEndDate;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(accountDeletion.pending, (state) => {
        state.isLoadingAccountDeletion = true;
      })
      .addCase(accountDeletion.fulfilled, (state) => {
        state.isLoadingAccountDeletion = false;
      })
      .addCase(accountDeletion.rejected, (state) => {
        state.isLoadingAccountDeletion = false;
      });
  },
});

export const {
  resetReportLastUpdateState,
  updatePaymentsReportLastUpdated,
  setReportDates,
} = appSlice.actions;

export default appSlice.reducer;
