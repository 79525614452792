import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { Colors } from '../theme/colors';

type AppButtonProps = {
  loading?: boolean;
  startIcon?: React.ReactNode;
} & ButtonProps;

const AppButton = ({
  size = 'large',
  loading,
  variant,
  title,
  children,
  startIcon,
  sx = {},
  ...props
}: AppButtonProps) => {
  return (
    <Button
      variant={variant}
      {...props}
      size={size}
      startIcon={startIcon}
      sx={{
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '6px',
        minWidth: '60px',
        minHeight: '20px',
        ...sx,
      }}>
      {loading ? (
        <CircularProgress
          size="25px"
          thickness={5}
          sx={{
            color:
              variant === 'contained'
                ? Colors.fontColorInvert
                : Colors.fontColorPrimary,
          }}
        />
      ) : (
        (children ?? title)
      )}
    </Button>
  );
};

export default AppButton;
