import { createSlice } from '@reduxjs/toolkit';

export type ReportPaginationState = {
  limit?: number;
  skip?: number;
};

export const reportPaginationInitialState: ReportPaginationState = {
  limit: 10,
  skip: 0,
};

export const reportPaginationSlice = createSlice({
  name: 'reportPagination',
  initialState: reportPaginationInitialState,
  reducers: {
    resetReportPaginationState: () => reportPaginationInitialState,
    setReportPaginationStateLimit: (state, action) => {
      state.limit = action.payload;
    },
    setReportPaginationSkip: (state, action) => {
      state.skip = action.payload;
    },
  },
});

export const {
  resetReportPaginationState,
  setReportPaginationStateLimit,
  setReportPaginationSkip,
} = reportPaginationSlice.actions;

export default reportPaginationSlice.reducer;
