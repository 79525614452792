import CalculatorIcon from '@mui/icons-material/Calculate';
import CropIcon from '@mui/icons-material/Crop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListIcon from '@mui/icons-material/List';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import PieChartIcon from '@mui/icons-material/PieChart';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { IVerticalTabItem } from '../../../components/AppVerticalTabs';

export enum SETTINGS_NAVIGATION_TABS {
  GENERAL_SETTINGS = 'general_settings',
  ACCOUNT = 'account',
  POS_SETTINGS = 'pos_settings',
  ORDER_TYPE = 'order_type',
  PAYMENT_TYPE = 'payment-types',
  NOTIFICATIONS = 'notifications',
  TAXES = 'taxes',
  TICKET_CONFIGURATION = 'ticket_configuration',
  RECEIPTS = 'receipts',
  TRANSACTION_CHARGES = 'transaction_charges',
  DEVICES = 'devices',
  RESTAURANT_AREAS = 'restaurant-areas',
}

export const SETTINGS_TABS: IVerticalTabItem[] = [
  {
    id: SETTINGS_NAVIGATION_TABS.GENERAL_SETTINGS,
    label: 'General Settings',
    Icon: ListIcon,
    path: '/settings/general',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.ACCOUNT,
    label: 'Account',
    Icon: ManageAccountsIcon,
    path: '/settings/account',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.POS_SETTINGS,
    label: 'POS Settings',
    Icon: ListIcon,
    path: '/settings/pos-settings',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.ORDER_TYPE,
    label: 'Order Type',
    Icon: InboxIcon,
    path: '/settings/order-types',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.PAYMENT_TYPE,
    label: 'Payment Types',
    Icon: PaymentIcon,
    path: '/settings/payment-types',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.NOTIFICATIONS,
    label: 'Notifications',
    Icon: NotificationsIcon,
    path: '/settings/notifications',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.TAXES,
    label: 'Taxes',
    Icon: CalculatorIcon,
    path: '/settings/taxes',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.TICKET_CONFIGURATION,
    label: 'Table Configuration',
    Icon: LocalOfferIcon,
    path: '/settings/tickets',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.DEVICES,
    label: 'Devices',
    Icon: PointOfSaleIcon,
    path: '/settings/devices',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.RESTAURANT_AREAS,
    label: 'Restaurant Areas',
    Icon: CropIcon,
    path: '/settings/restaurant-areas',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.RECEIPTS,
    label: 'Receipt Settings',
    Icon: InsertDriveFileIcon,
    path: '/settings/receipts',
  },
  {
    id: SETTINGS_NAVIGATION_TABS.TRANSACTION_CHARGES,
    label: 'Transaction Charges',
    Icon: PieChartIcon,
    path: '/settings/charges',
  },
];

export enum CREATE_RESTAURANT_AREAS_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  TABLES = 'tables',
}
