import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { ORDER_ANALYTICS_SUMMARY } from '../constants';
import {
  OrderCountSummaryDTO,
  OrderCountSummaryExtrasDTO,
  OrderCountSummaryResponseDTO,
  TotalCounts,
} from '../types/reports.types';
import * as requestTypes from './reportsActionTypes';

export interface OrderCountSummaryState {
  status: string;
  refreshing: boolean;
  columns: IAppTableColumn[];
  orderSummaryData: OrderCountSummaryDTO[];
  exportStatus: string;
  orderCounts: TotalCounts;
  lastUpdated: string;
}

const initialState: OrderCountSummaryState = {
  status: 'idle',
  refreshing: false,
  columns: ORDER_ANALYTICS_SUMMARY,
  orderSummaryData: [],
  exportStatus: 'idle',
  orderCounts: {
    dineInCounts: 0,
    pickUpCounts: 0,
    totalCounts: 0,
    deliveryCounts: 0,
    nonEatMeCounts: 0,
  },
  lastUpdated: '',
};

interface OrderCountSummaryProps {
  date_from?: string;
  date_to?: string;
  offset?: string;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: OrderCountSummaryProps,
): Promise<
  PaginatedResponseDTO<OrderCountSummaryResponseDTO, OrderCountSummaryExtrasDTO>
> => {
  const url = URLS.REPORTS.ORDER_COUNT_SUMMARY;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<
        OrderCountSummaryResponseDTO,
        OrderCountSummaryExtrasDTO
      >
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchOrderSummary = createAppAsyncThunk<
  PaginatedResponseDTO<
    OrderCountSummaryResponseDTO,
    OrderCountSummaryExtrasDTO
  >,
  OrderCountSummaryProps
>(
  requestTypes.REQUEST_ORDER_COUNT_SUMMARY,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const fetchOrderSummaryExport = createAppAsyncThunk<
  PaginatedResponseDTO<
    OrderCountSummaryResponseDTO,
    OrderCountSummaryExtrasDTO
  >,
  OrderCountSummaryProps
>(
  requestTypes.REQUEST_ORDER_COUNT_SUMMARY_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const orderCountSummary = createSlice({
  name: 'orderCountSummary',
  initialState,
  reducers: {
    resetOrderCountSummaryReportsState: () => initialState,
    setColumns: (
      state: OrderCountSummaryState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrderSummary.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchOrderSummary.fulfilled, (state, action) => {
        state.orderSummaryData = action.payload.results;
        state.orderCounts = action.payload?.extras?.totalCounts;
        state.lastUpdated = action.payload?.extras?.lastUpdated;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchOrderSummary.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchOrderSummaryExport.pending, (state) => {
        state.exportStatus = 'loading';
      })
      .addCase(fetchOrderSummaryExport.fulfilled, (state) => {
        state.exportStatus = 'success';
      })
      .addCase(fetchOrderSummaryExport.rejected, (state) => {
        state.exportStatus = 'failed';
      });
  },
});

export const { setColumns, resetOrderCountSummaryReportsState } =
  orderCountSummary.actions;

export const { reducer: orderCountSummaryReducer } = orderCountSummary;

export default orderCountSummary.reducer;
