import { IBaseEntity } from '../features/menu/types/common.types';

export type ReorderParams<T> = {
  list: T[];
  dragId: string;
  dropId: string;
  id?: boolean;
};

export type ReorderBaseParams = {
  sequenceNumber: number;
  id?: number;
} & Partial<IBaseEntity>;

export const reorder = <T extends ReorderBaseParams>(
  args: ReorderParams<T>,
) => {
  const { list, dragId, dropId, id } = args;
  const dragItemIndex = list.findIndex((item) =>
    id ? item.id === Number(dragId) : item._id === dragId,
  );
  const dropItemIndex = list.findIndex((item) =>
    id ? item.id === Number(dropId) : item._id === dropId,
  );

  if (dragItemIndex === -1 || dropItemIndex === -1) return;

  const dragItem = list[dragItemIndex];
  const dropItem = list[dropItemIndex];

  const dropSequenceNumber = dropItem.sequenceNumber ?? 0;
  const dragSequenceNumber = dragItem.sequenceNumber ?? 0;

  const isUpwards = dragSequenceNumber > dropSequenceNumber;
  const isDownwards = dragSequenceNumber < dropSequenceNumber;

  if (isUpwards)
    list[dragItemIndex].sequenceNumber = updateSequenceNumber(
      list,
      (a, b) => a < b,
      -1,
      dropSequenceNumber,
    );
  if (isDownwards)
    list[dragItemIndex].sequenceNumber = updateSequenceNumber(
      list,
      (a, b) => a > b,
      1,
      dropSequenceNumber,
    );

  list.sort((a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0));

  return {
    list,
    dropItemIndex,
    dragItemIndex,
    dragSequenceNumber,
    dropSequenceNumber,
  };
};

const updateSequenceNumber = (
  list: ReorderBaseParams[],
  condition: (a: number, b: number) => boolean,
  operation: number,
  dropSequenceNumber: number,
) => {
  const item = list
    .filter((item) => condition(item.sequenceNumber, dropSequenceNumber))
    .sort((a, b) => operation * (a.sequenceNumber - b.sequenceNumber))[0];

  let newSequenceNumber;
  if (item) {
    newSequenceNumber = (dropSequenceNumber + item.sequenceNumber) / 2;
  } else {
    newSequenceNumber =
      operation === 1 ? dropSequenceNumber + 1 : dropSequenceNumber / 2;
  }
  return newSequenceNumber;
};
