import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Colors } from '../../theme/colors';

type Props = {
  error?: string;
};

const AppInputErrorMessage = ({ error }: Props) => {
  return (
    <Box
      sx={{
        height: '25px',
        marginTop: '-2px',
      }}>
      <Fade in={!!error}>
        {error ? (
          <Typography
            variant="caption"
            sx={{
              color: Colors.error,
              marginLeft: '10px',
              paddingTop: '5px',
              display: 'flex',
              lineHeight: 'normal',
            }}>
            {error}
          </Typography>
        ) : (
          <Box />
        )}
      </Fade>
    </Box>
  );
};

export default AppInputErrorMessage;
