import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  GeneralSettingsState,
  IGeneralSettingsResponseDTO,
  UpdateGeneralSettingsDTO,
} from '../types/general-settings.types';
import * as generalSettingsTypes from './generalSettingsActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const updateGeneralSettingsData = createAsyncThunk<
  IGeneralSettingsResponseDTO,
  UpdateGeneralSettingsDTO
>(
  generalSettingsTypes.REQUEST_TO_PATCH_UPDATE_GENERAL_SETTINGS,
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IGeneralSettingsResponseDTO>
      >(URLS.GENERAL_SETTINGS.DEFAULT, data);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleShiftManagement = createAsyncThunk<void, boolean>(
  generalSettingsTypes.REQUEST_TO_TOGGLE_SHIFT_MANAGEMENT_ACCESS,
  async (allowShiftAccess, { rejectWithValue }) => {
    try {
      await Api.post<CommonResponseDTO<IGeneralSettingsResponseDTO>>(
        URLS.GENERAL_SETTINGS.TOGGLE_SHIFT_MANAGEMENT,
        {
          allowShiftAccess,
        },
      );
    } catch (error) {
      SentryCaptureError(error);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleGuestCount = createAsyncThunk<void, boolean>(
  generalSettingsTypes.REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS,
  async (isGuestCountEnabled, { rejectWithValue }) => {
    try {
      await Api.post<CommonResponseDTO<IGeneralSettingsResponseDTO>>(
        URLS.GENERAL_SETTINGS.TOGGLE_GUEST_COUNT,
        {
          isGuestCountEnabled,
        },
      );
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleGuestCountMandatory = createAsyncThunk<void, boolean>(
  generalSettingsTypes.REQUEST_TO_TOGGLE_NUMBER_OF_GUESTS_MANDATORY,
  async (isGuestCountMandatory, { rejectWithValue }) => {
    try {
      await Api.post<CommonResponseDTO<IGeneralSettingsResponseDTO>>(
        URLS.GENERAL_SETTINGS.TOGGLE_GUEST_COUNT_MANDATORY,
        {
          isGuestCountMandatory,
        },
      );
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleCustomerInformation = createAsyncThunk<void, boolean>(
  generalSettingsTypes.REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION,
  async (isCustomerInformationEnabled, { rejectWithValue }) => {
    try {
      await Api.post<CommonResponseDTO<IGeneralSettingsResponseDTO>>(
        URLS.GENERAL_SETTINGS.TOGGLE_CUSTOMER_INFORMATION,
        {
          isCustomerInformationEnabled,
        },
      );
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleCustomerInformationMandatory = createAsyncThunk<
  void,
  boolean
>(
  generalSettingsTypes.REQUEST_TO_TOGGLE_CUSTOMER_INFORMATION_MANDATORY,
  async (isCustomerInformationMandatory, { rejectWithValue }) => {
    try {
      await Api.post<CommonResponseDTO<IGeneralSettingsResponseDTO>>(
        URLS.GENERAL_SETTINGS.TOGGLE_CUSTOMER_INFORMATION_MANDATORY,
        {
          isCustomerInformationMandatory,
        },
      );
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

const initialState: GeneralSettingsState = {
  refreshing: false,
};

export const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    resetGeneralSettingsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateGeneralSettingsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateGeneralSettingsData.fulfilled, (state, action) => {
        state.generalSettingsData = action.payload;
        state.refreshing = false;
      })
      .addCase(updateGeneralSettingsData.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetGeneralSettingsState } = generalSettingsSlice.actions;

export default generalSettingsSlice.reducer;
