import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  IReceiptResponseDTO,
  ReceiptState,
  UpdateReceiptDTO,
} from '../types/receipt.types';
import * as receiptsTypes from './receiptActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchReceiptData = createAppAsyncThunk<
  IReceiptResponseDTO[],
  void
>(receiptsTypes.REQUEST_RECEIPTS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<CommonResponseDTO<IReceiptResponseDTO[]>>(
      URLS.RECEIPT,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const updateReceipt = createAsyncThunk<
  IReceiptResponseDTO,
  UpdateReceiptDTO
>(
  receiptsTypes.REQUEST_TO_UPDATE_RECEIPTS,
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.patch<CommonResponseDTO<IReceiptResponseDTO>>(
        URLS.RECEIPT,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const ReceiptInitialState: ReceiptState = {
  receiptData: [],
  refreshing: false,
};

export const receiptSlice = createSlice({
  name: 'receipt',
  initialState: ReceiptInitialState,
  reducers: {
    resetReceiptState: () => ReceiptInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceiptData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchReceiptData.fulfilled, (state, action) => {
        state.receiptData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchReceiptData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateReceipt.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateReceipt.fulfilled, (state, action) => {
        state.receiptData = state.receiptData.map((receipt) =>
          receipt.id === action.payload.id ? action.payload : receipt,
        );
        state.refreshing = false;
      })
      .addCase(updateReceipt.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetReceiptState } = receiptSlice.actions;

export default receiptSlice.reducer;
