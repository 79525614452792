export const REQUEST_SUB_CATEGORIES = 'SUBCATEGORIES/REQUEST_SUB_CATEGORIES';
export const REQUEST_TO_CREATE_SUB_CATEGORY =
  'SUBCATEGORIES/REQUEST_TO_CREATE_SUB_CATEGORY';
export const REQUEST_TO_UPDATE_SUB_CATEGORY =
  'SUBCATEGORIES/REQUEST_TO_UPDATE_SUB_CATEGORY';
export const REQUEST_TO_PATCH_UPDATE_SUB_CATEGORY =
  'SUBCATEGORIES/REQUEST_TO_PATCH_UPDATE_SUB_CATEGORY';
export const REQUEST_TO_DELETE_SUB_CATEGORY =
  'SUBCATEGORIES/REQUEST_TO_DELETE_SUB_CATEGORY';
export const REQUEST_SINGLE_SUB_CATEGORY =
  'SUBCATEGORIES/REQUEST_SINGLE_SUB_CATEGORY';
export const REQUEST_TO_UPDATE_SUB_CATEGORY_SEQUENCE_NUMBER =
  'SUBCATEGORIES/REQUEST_TO_UPDATE_SUB_CATEGORY_SEQUENCE_NUMBER';
