import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { IOrderSummaryResponseDTO } from '../../../types/components/order-history.types';
import {
  IReceiptsStatusResponseDTO,
  ReceiptsCountsFullResponse,
  ReceiptsCountsParams,
  ReceiptsExtras,
  ReceiptsParams,
  ReceiptsResponseDTO,
  ReportsReceiptState,
  SELECTED_TAB,
} from '../types/receipts.types';
import * as receiptsTypes from './receiptsActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchReceiptsData = createAppAsyncThunk<
  PaginatedResponseDTO<ReceiptsResponseDTO, ReceiptsExtras>,
  ReceiptsParams
>(
  receiptsTypes.REQUEST_RECEIPTS,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip } = getState().reportPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<ReceiptsResponseDTO, ReceiptsExtras>
        >
      >(URLS.REPORTS.RECEIPTS_DEFAULT, { params: { ...params, limit, skip } });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchReceiptsDataExport = createAppAsyncThunk<
  PaginatedResponseDTO<ReceiptsResponseDTO, ReceiptsExtras>,
  ReceiptsParams
>(
  receiptsTypes.REQUEST_RECEIPTS_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<ReceiptsResponseDTO, ReceiptsExtras>
        >
      >(URLS.REPORTS.RECEIPTS_DEFAULT, { params: { ...params } });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchReceiptsStatus = createAppAsyncThunk<
  IReceiptsStatusResponseDTO,
  void
>(receiptsTypes.REQUEST_RECEIPTS_STATUS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<IReceiptsStatusResponseDTO>
    >(URLS.REPORTS.RECEIPTS_STATUS);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchSingleReceipt = createAppAsyncThunk<
  IOrderSummaryResponseDTO,
  string
>(receiptsTypes.REQUEST_SINGLE_RECEIPT, async (id, { rejectWithValue }) => {
  try {
    const url = `${URLS.REPORTS.RECEIPTS_DEFAULT}/${id}`;

    const response =
      await Api.get<CommonResponseDTO<IOrderSummaryResponseDTO>>(url);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchReceiptsCounts = createAppAsyncThunk<
  ReceiptsCountsFullResponse,
  ReceiptsCountsParams
>(
  receiptsTypes.REQUEST_RECEIPTS_COUNTS,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<ReceiptsCountsFullResponse>
      >(`${URLS.REPORTS.RECEIPTS_DEFAULT}/counts`, { params });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

const initialState: ReportsReceiptState = {
  receiptsData: [],
  extras: {
    limit: 10,
    total: 0,
    date_from: undefined,
    date_to: undefined,
    employeeIds: undefined,
  },
  loadingSingleReceipt: false,
  refreshing: false,
  receiptsStatus: { showLoadingScreen: false },
  totalCount: 0,
  succeededCount: 0,
  refundedCount: 0,
  deletedCount: 0,
  activeTab: [],
  selectedTab: SELECTED_TAB.ALL,
};

const receiptsSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    resetReceiptsState: () => initialState,
    setActiveTab: (state, action: PayloadAction<string[]>) => {
      state.activeTab = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<SELECTED_TAB>) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceiptsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchReceiptsData.fulfilled, (state, action) => {
        state.receiptsData = action.payload.results;
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.extras.lastUpdated = action.payload.extras.lastUpdated;
        state.refreshing = false;
      })
      .addCase(fetchReceiptsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchReceiptsStatus.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchReceiptsStatus.fulfilled, (state, action) => {
        state.receiptsStatus = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchReceiptsStatus.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchSingleReceipt.pending, (state) => {
        state.loadingSingleReceipt = true;
      })
      .addCase(fetchSingleReceipt.fulfilled, (state) => {
        state.loadingSingleReceipt = false;
      })
      .addCase(fetchSingleReceipt.rejected, (state) => {
        state.loadingSingleReceipt = false;
      })
      .addCase(fetchReceiptsCounts.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchReceiptsCounts.fulfilled, (state, action) => {
        const { results, extras } = action.payload;
        state.totalCount = results.totalCount;
        state.succeededCount = results.succeededCount;
        state.refundedCount = results.RefundedCount;
        state.deletedCount = results.deletedCount;

        state.extras.date_from = extras.date_from;
        state.extras.date_to = extras.date_to;
        state.extras.employeeIds = extras.employeeIds;
        state.refreshing = false;
      })
      .addCase(fetchReceiptsCounts.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetReceiptsState, setActiveTab, setSelectedTab } =
  receiptsSlice.actions;

export default receiptsSlice.reducer;
