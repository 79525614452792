import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  CreateEmployeesRequestBodyDTO,
  EmployeesParams,
  EmployeesState,
  FetchEmployeePinResponse,
  IEmployeesResponseDTO,
  IOrdersEmployeesResponseDTO,
  IPaymentsEmployeesResponseDTO,
  UpdateEmployeeError,
} from '../types/employees.types';
import * as employeesTypes from './employeeActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchEmployees = createAppAsyncThunk<
  PaginatedResponseDTO<IEmployeesResponseDTO, BasePaginationExtras>,
  EmployeesParams
>(employeesTypes.REQUEST_EMPLOYEES, async (params, { rejectWithValue }) => {
  try {
    // Convert each parameter in `params` to a string
    const queryParams = new URLSearchParams();
    (Object.keys(params) as Array<keyof EmployeesParams>).forEach((key) => {
      const value = params[key];
      if (value !== undefined) {
        queryParams.set(key, String(value));
      }
    });

    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<IEmployeesResponseDTO, BasePaginationExtras>
      >
    >(`${URLS.EMPLOYEES}?${queryParams.toString()}`);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchOrdersEmployees = createAsyncThunk<
  IOrdersEmployeesResponseDTO[],
  EmployeesParams
>(
  employeesTypes.REQUEST_ORDERS_EMPLOYEES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<IOrdersEmployeesResponseDTO[]>
      >(`${URLS.EMPLOYEES}/orders`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchPaymentsEmployees = createAsyncThunk<
  IPaymentsEmployeesResponseDTO[],
  EmployeesParams
>(
  employeesTypes.REQUEST_PAYMENTS_EMPLOYEES,
  async (params, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<IPaymentsEmployeesResponseDTO[]>
      >(`${URLS.EMPLOYEES}/payments`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const fetchEmployeePin = createAsyncThunk<string, void>(
  employeesTypes.REQUEST_POS_PIN,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<FetchEmployeePinResponse>
      >(`${URLS.EMPLOYEES}/pos-pin`);
      return response.data.data.posPin.toString();
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createEmployee = createAppAsyncThunk<
  IEmployeesResponseDTO,
  CreateEmployeesRequestBodyDTO
>(
  employeesTypes.REQUEST_CREATE_EMPLOYEE,
  async (employeesData, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<IEmployeesResponseDTO>>(
        URLS.EMPLOYEES,
        employeesData,
      );
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        return rejectWithValue(error.response.data);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleEmployee = createAppAsyncThunk<
  IEmployeesResponseDTO,
  string
>(
  employeesTypes.REQUEST_SINGLE_REQUEST_EMPLOYEE,
  async (empId, { rejectWithValue }) => {
    try {
      const url = `${URLS.EMPLOYEES}/${empId}`;
      const response =
        await Api.get<CommonResponseDTO<IEmployeesResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateEmployeesData = createAsyncThunk<
  IEmployeesResponseDTO,
  { id: string; data: Partial<CreateEmployeesRequestBodyDTO> },
  {
    rejectValue: UpdateEmployeeError;
  }
>(
  employeesTypes.REQUEST_UPDATE_EMPLOYEE,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IEmployeesResponseDTO>
      >(`${URLS.EMPLOYEES}/${id}`, data);
      return response.data.data;
    } catch (error) {
      const axiosError = error as AxiosError<UpdateEmployeeError>;
      if (axiosError.response) {
        return rejectWithValue(axiosError.response.data);
      } else {
        SentryCaptureError(error);
        openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
        return rejectWithValue({ message: 'An unexpected error occurred' });
      }
    }
  },
);

export const patchUpdateEmployeesData = createAppAsyncThunk<
  IEmployeesResponseDTO,
  { id: string; data: Partial<CreateEmployeesRequestBodyDTO> }
>(
  employeesTypes.REQUEST_TO_PATCH_UPDATE_EMPLOYEE,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IEmployeesResponseDTO>
      >(`${URLS.EMPLOYEES}/${id}/status`, data);
      return response.data.data;
    } catch (error) {
      const axiosError = error as AxiosError<UpdateEmployeeError>;
      if (axiosError.response) {
        const errorMessage =
          axiosError.response.data.message ?? 'An unexpected error occurred';
        openGlobalSnackbar(errorMessage, SNACKBARTYPE.ERROR);
        return rejectWithValue(errorMessage);
      } else {
        SentryCaptureError(error);
        openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
        return rejectWithValue('An unexpected error occurred');
      }
    }
  },
);

export const initialState: EmployeesState = {
  employeesData: [],
  ordersEmployeesData: [],
  paymentsEmployeesData: [],
  refreshing: false,
  extras: {
    limit: 10,
    total: 0,
  },
};

export const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetEmployeesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employeesData = action.payload.results;
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchEmployees.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchOrdersEmployees.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchOrdersEmployees.fulfilled, (state, action) => {
        state.ordersEmployeesData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchOrdersEmployees.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchPaymentsEmployees.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchPaymentsEmployees.fulfilled, (state, action) => {
        state.paymentsEmployeesData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchPaymentsEmployees.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createEmployee.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.employeesData.push(action.payload);
      })
      .addCase(createEmployee.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateEmployeesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateEmployeesData.fulfilled, (state, action) => {
        const index = state.employeesData.findIndex(
          (employee) => employee.empId === action.payload.empId,
        );
        if (index !== -1) {
          state.employeesData[index] = action.payload;
        }
        state.refreshing = false;
      })
      .addCase(updateEmployeesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleEmployee.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(getSingleEmployee.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleEmployee.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetEmployeesState } = employeeSlice.actions;

export default employeeSlice.reducer;
