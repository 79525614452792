import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { PAGES_COLUMNS } from '../constants';
import {
  CreatePageRequestBodyDTO,
  GetAllPagesQueryDTO,
  IPagesResponseDTO,
  PagesState,
} from '../types/pages.types';
import * as pageTypes from './pagesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchPageData = createAppAsyncThunk<
  PaginatedResponseDTO<IPagesResponseDTO, BasePaginationExtras>,
  Omit<GetAllPagesQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(pageTypes.REQUEST_PAGES, async (params, { rejectWithValue, getState }) => {
  try {
    const { limit, skip, search_key } = getState().menuPagination;
    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<IPagesResponseDTO, BasePaginationExtras>
      >
    >(URLS.PAGES, {
      params: {
        ...params,
        limit,
        skip,
        search_key,
      },
    });
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const getSinglePage = createAppAsyncThunk<IPagesResponseDTO, string>(
  pageTypes.REQUEST_SINGLE_PAGE,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.PAGES}/${id}`;
      const response = await Api.get<CommonResponseDTO<IPagesResponseDTO>>(url);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createPage = createAppAsyncThunk<
  IPagesResponseDTO,
  CreatePageRequestBodyDTO
>(pageTypes.CREATE_PAGES, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<CommonResponseDTO<IPagesResponseDTO>>(
      URLS.PAGES,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const updatePage = createAppAsyncThunk<
  IPagesResponseDTO,
  { id: string; body: CreatePageRequestBodyDTO }
>(pageTypes.UPDATE_PAGES, async ({ id, body }, { rejectWithValue }) => {
  try {
    const response = await Api.put<CommonResponseDTO<IPagesResponseDTO>>(
      `${URLS.PAGES}/${id}`,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    return rejectWithValue('An error occurred');
  }
});

export const patchUpdatePage = createAppAsyncThunk<
  IPagesResponseDTO,
  { id: string; body: Partial<CreatePageRequestBodyDTO> }
>(pageTypes.PATCH_UPDATE_PAGES, async ({ id, body }, { rejectWithValue }) => {
  try {
    const response = await Api.patch<CommonResponseDTO<IPagesResponseDTO>>(
      `${URLS.PAGES}/${id}`,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    return rejectWithValue('An error occurred');
  }
});

export const deletePage = createAppAsyncThunk<string, string>(
  pageTypes.DELETE_PAGES,
  async (id, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.PAGES}/${id}`);
      return id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updatePageSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  pageTypes.REQUEST_TO_UPDATE_PAGE_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const pagesData = [...cloneDeep(getState().pages.pagesData)];

      const reorderData = reorder<IPagesResponseDTO>({
        list: pagesData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newPagesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setPagesState(newPagesData));

      const response = await Api.patch<CommonResponseDTO<IPagesResponseDTO>>(
        `${URLS.PAGES}/${dragId}`,
        {
          sequenceNumber: newPagesData[dropItemIndex].sequenceNumber,
        },
      );

      if (response.status !== 200) {
        newPagesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newPagesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setPagesState(newPagesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const pagesInitialState: PagesState = {
  pagesData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  loadingSinglePage: false,
  refreshing: false,
  columns: PAGES_COLUMNS,
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: pagesInitialState,
  reducers: {
    resetPagesState: () => pagesInitialState,
    resetPagesEditingDataState: (state) => {
      state.editingData = undefined;
    },
    setPagesState: (state, action) => {
      state.pagesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchPageData.fulfilled, (state, action) => {
        state.pagesData = action.payload.results;
        state.extras = action.payload.extras;
        state.refreshing = false;
      })
      .addCase(fetchPageData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSinglePage.pending, (state) => {
        state.loadingSinglePage = true;
      })
      .addCase(getSinglePage.fulfilled, (state, action) => {
        state.editingData = action.payload;
        state.loadingSinglePage = false;
      })
      .addCase(getSinglePage.rejected, (state) => {
        state.loadingSinglePage = false;
      })
      .addCase(createPage.pending, (state) => {
        state.loadingSinglePage = true;
      })
      .addCase(createPage.fulfilled, (state, action) => {
        state.pagesData.unshift(action.payload);
      })
      .addCase(createPage.rejected, (state) => {
        state.loadingSinglePage = false;
      })
      .addCase(updatePage.pending, (state) => {
        state.loadingSinglePage = true;
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        const index = state.pagesData.findIndex(
          (item) => item._id === action.payload._id,
        );
        if (index !== -1) {
          state.pagesData[index] = action.payload;
        }
      })
      .addCase(updatePage.rejected, (state) => {
        state.loadingSinglePage = false;
      })
      .addCase(patchUpdatePage.pending, (state) => {
        state.loadingSinglePage = true;
      })
      .addCase(patchUpdatePage.fulfilled, (state, action) => {
        const index = state.pagesData.findIndex(
          (item) => item._id === action.payload._id,
        );
        if (index !== -1) {
          state.pagesData[index] = action.payload;
        }
      })
      .addCase(patchUpdatePage.rejected, (state) => {
        state.loadingSinglePage = false;
      })
      .addCase(deletePage.pending, (state) => {
        state.loadingSinglePage = true;
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        const index = state.pagesData.findIndex(
          (item) => item._id === action.payload,
        );
        if (index !== -1) {
          state.pagesData.splice(index, 1);
        }
      })
      .addCase(deletePage.rejected, (state) => {
        state.loadingSinglePage = false;
      });
  },
});

export const { resetPagesState, resetPagesEditingDataState, setPagesState } =
  pagesSlice.actions;

export default pagesSlice.reducer;
