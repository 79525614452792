import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  label?: string;
  labelRightComponent?: React.ReactNode;
  showOptional?: boolean;
  disabled?: boolean;
};

const AppInputLabel = ({
  label,
  labelRightComponent,
  showOptional,
  disabled,
}: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5px',
      }}>
      {label && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            opacity: disabled ? 0.5 : 1,
          }}>
          {label}
          {showOptional ? (
            <span
              style={{
                fontStyle: 'italic',
                marginLeft: '4px',
                marginRight: '4px',
                fontWeight: 400,
              }}>
              - Optional
            </span>
          ) : null}
        </Typography>
      )}
      {labelRightComponent}
    </Box>
  );
};

export default AppInputLabel;
