import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import {
  MRT_Cell,
  MRT_Column,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import { ReactNode } from 'react';

import { IChipCell } from '../../AppCells/ChipCell';
import { ICurrencyCell } from '../../AppCells/CurrencyCell';
import { IDateCell } from '../../AppCells/DateCell';
import { ITextCell } from '../../AppCells/TextCell';

export enum APP_LIST_ALIGNMENT {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum APP_LIST_COLUMN_TYPES {
  TEXT = 'TEXT',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  CHIP = 'CHIP',
  LIST = 'LIST',
}

export enum ACTION_TYPES {
  DEFAULT = 'DEFAULT',
  HEADER = 'HEADER',
  DIVIDER = 'DIVIDER',
}

export type IAppListCell = {
  type: APP_LIST_COLUMN_TYPES;
  data: unknown;
};

export interface IAppListColumn {
  accessorKey: string;
  header: string;
  headerAlign: APP_LIST_ALIGNMENT;
  bodyAlign: APP_LIST_ALIGNMENT;
  category: string;

  visible: boolean;
  exportable: boolean;

  visibilityChangeable?: boolean;

  headerSx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;
}

export type IAppListRow<T> = {
  [key in keyof T]:
    | IChipCell
    | ICurrencyCell
    | ITextCell
    | IDateCell
    | string
    | { type: APP_LIST_COLUMN_TYPES.LIST; data: { value: unknown[] } }
    | IListAction[];
} & {
  key: string;
  actions: IListAction[];
};

export interface IListAction {
  key: string;
  name: string;
  fontColor?: string;
  fontWeight?: string | number;
  backgroundColor?: string;
  type?: ACTION_TYPES;
}

export interface AppListProps<T> {
  columns: IAppListColumn[];
  rows: IAppListRow<T>[];
  refreshing?: boolean;
  pagination?: {
    pageIndex: number;
    pageSize: number;
    total: number;
  };
  handleNextClick?: () => void;
  handlePreviousClick?: () => void;
  disableNext?: boolean;
  onChangeColumnVisibility?: (newColumns: IAppListColumn[]) => void;
  emptyComponent?: JSX.Element;
  hideHeader?: boolean;
  onClickActions?: (key: string, data: IAppListRow<T>) => void;
  onClickRow?: (data: IAppListRow<T>) => void;
  enableRowActions?: boolean;
  renderDetailPanel?: (value: IAppListRow<T>) => JSX.Element;
  renderRowActions?: (value: T) => JSX.Element;
}

export interface AppListCellWrapperProps<T extends Record<string, unknown>> {
  cell: MRT_Cell<T>;
  renderedCellValue: number | string | ReactNode;
  column: MRT_Column<T>;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}
