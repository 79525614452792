export const REQUEST_PAYMENT_TYPES =
  'SETTINGS/PAYMENT_TYPES/REQUEST_PAYMENT_TYPES';
export const REQUEST_SINGLE_PAYMENT_TYPE =
  'SETTINGS/PAYMENT_TYPES/REQUEST_SINGLE_PAYMENT_TYPE';
export const REQUEST_TO_CREATE_PAYMENT_TYPES =
  'SETTINGS/PAYMENT_TYPES/REQUEST_TO_CREATE_PAYMENT_TYPES';
export const REQUEST_TO_PATCH_UPDATE_PAYMENT_TYPES =
  'SETTINGS/PAYMENT_TYPES/REQUEST_TO_PATCH_UPDATE_PAYMENT_TYPES';
export const REQUEST_TO_DELETE_PAYMENT_TYPES =
  'SETTINGS/PAYMENT_TYPES/REQUEST_TO_DELETE_PAYMENT_TYPES';
export const REQUEST_TO_UPDATE_CUSTOM_PAYMENT_SEQUENCE_NUMBER =
  'SETTINGS/REQUEST_TO_UPDATE_CUSTOM_PAYMENT_SEQUENCE_NUMBER';
