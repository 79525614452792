import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { PAYMENTS_COLUMNS } from '../constants';
import {
  IPaymentFilters,
  IPaymentResponseDTO,
  PAYMENT_STATUS,
  PaymentExtrasDTO,
} from '../types/payments.types';
import * as paymentsTypes from './paymentActionTypes';
import { IOnboardingLinkResponseDTO } from '../types/adyen-payments.types';

export interface PaymentState {
  paymentsData: IPaymentResponseDTO[];
  paymentsCounts: Record<PAYMENT_STATUS | 'total', number>;
  paymentFilters?: IPaymentFilters;
  status: string;
  refreshing: boolean;
  exportStatus: string;
  activeTab: string[];
  columns: IAppTableColumn[];
  onboardingLink: string;
}

const initialState: PaymentState = {
  paymentsData: [],
  paymentsCounts: {
    total: 0,
    failed: 0,
    refunded: 0,
    succeeded: 0,
    partialRefund: 0,
  },
  paymentFilters: {},
  status: 'idle',
  refreshing: false,
  exportStatus: 'idle',
  activeTab: [],
  columns: PAYMENTS_COLUMNS,
  onboardingLink: '',
};

interface PaymentsProps {
  type: string;
  status: string;
  limit?: number;
  skip?: number;
  date_from?: string;
  date_to?: string;
  offset?: string;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

const fetchData = async (
  params: PaymentsProps,
): Promise<PaginatedResponseDTO<IPaymentResponseDTO, PaymentExtrasDTO>> => {
  const url = URLS.PAYMENTS;

  const response = await Api.get<
    CommonResponseDTO<
      PaginatedResponseDTO<IPaymentResponseDTO, PaymentExtrasDTO>
    >
  >(url, {
    params,
  });

  return response.data.data;
};

export const fetchAdyenOnboardingLink = createAppAsyncThunk<
  IOnboardingLinkResponseDTO,
  undefined,
  { rejectValue: string }
>(paymentsTypes.REQUEST_ONBOARDING_LINK, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<IOnboardingLinkResponseDTO>
    >(URLS.ADYEN.ACCOUNTS.CREATE_ONBOARDING_URL);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

export const fetchPaymentsDetails = createAppAsyncThunk<
  PaginatedResponseDTO<IPaymentResponseDTO, PaymentExtrasDTO>,
  PaymentsProps
>(paymentsTypes.REQUEST_PAYMENTS, async (params, { rejectWithValue }) => {
  try {
    return await fetchData(params);
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('something went wrong');
  }
});

export const fetchPaymentsExport = createAppAsyncThunk<
  PaginatedResponseDTO<IPaymentResponseDTO, PaymentExtrasDTO>,
  PaymentsProps
>(
  paymentsTypes.REQUEST_PAYMENTS_EXPORT,
  async (params, { rejectWithValue }) => {
    try {
      return await fetchData(params);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetPaymentState: () => initialState,
    setActiveTab: (state, action: PayloadAction<string[]>) => {
      state.activeTab = action.payload;
    },
    setColumns: (
      state: PaymentState,
      action: PayloadAction<IAppTableColumn[]>,
    ) => {
      state.columns = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPaymentsDetails.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchPaymentsDetails.fulfilled, (state, action) => {
        state.paymentsData = action.payload.results;
        state.paymentsCounts = {
          succeeded: action.payload.extras.statusCounts?.succeeded ?? 0,
          failed: action.payload.extras.statusCounts?.failed ?? 0,
          refunded: action.payload.extras.statusCounts?.refunded ?? 0,
          total: action.payload.extras.statusCounts?.total ?? 0,
          partialRefund: action.payload.extras.statusCounts?.refunded ?? 0,
        };
        state.paymentFilters = action.payload.extras.paymentFilters;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchPaymentsDetails.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(fetchPaymentsExport.pending, (state) => {
        state.exportStatus = 'loading';
      })
      .addCase(fetchPaymentsExport.fulfilled, (state) => {
        state.exportStatus = 'success';
      })
      .addCase(fetchPaymentsExport.rejected, (state) => {
        state.exportStatus = 'failed';
      })
      .addCase(fetchAdyenOnboardingLink.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchAdyenOnboardingLink.fulfilled, (state, action) => {
        state.onboardingLink = action.payload.url;
        state.refreshing = false;
      })
      .addCase(fetchAdyenOnboardingLink.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { setActiveTab, setColumns, resetPaymentState } =
  paymentSlice.actions;

export default paymentSlice.reducer;
