export const REQUEST_EMPLOYEES = 'EMPLOYEES/REQUEST_EMPLOYEES';
export const REQUEST_SINGLE_REQUEST_EMPLOYEE =
  'EMPLOYEES/REQUEST_SINGLE_REQUEST_EMPLOYEE';
export const REQUEST_ORDERS_EMPLOYEES = 'EMPLOYEES/REQUEST_ORDERS_EMPLOYEES';
export const REQUEST_PAYMENTS_EMPLOYEES =
  'EMPLOYEES/REQUEST_PAYMENTS_EMPLOYEES';
export const REQUEST_POS_PIN = 'EMPLOYEES/REQUEST_POS_PIN';
export const REQUEST_CREATE_EMPLOYEE = 'EMPLOYEES/REQUEST_CREATE_EMPLOYEE';
export const REQUEST_UPDATE_EMPLOYEE = 'EMPLOYEES/REQUEST_UPDATE_EMPLOYEE';
export const REQUEST_TO_PATCH_UPDATE_EMPLOYEE =
  'EMPLOYEE/REQUEST_TO_PATCH_UPDATE_EMPLOYEE';
