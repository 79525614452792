export const REQUEST_ACCOUNT = 'ACCOUNTS/REQUEST_ACCOUNT';
export const REQUEST_STORES = 'STORES/REQUEST_STORES';
export const REQUEST_BUSINESS_LINES = 'BUSINESS_LINES/REQUEST_BUSINESS_LINES';
export const REQUEST_PAYOUT_SCHEDULES =
  'PAYOUT_SCHEDULES/REQUEST_PAYOUT_SCHEDULES';
export const REQUEST_UPDATE_PAYOUT_SCHEDULE =
  'PAYOUT_SCHEDULES/REQUEST_UPDATE_PAYOUT_SCHEDULE';
export const REQUEST_BANK_ACCOUNTS = 'BANK_ACCOUNTS/REQUEST_BANK_ACCOUNTS';
export const REQUEST_MAKE_BANK_ACCOUNT_PRIMARY =
  'BANK_ACCOUNTS/REQUEST_MAKE_BANK_ACCOUNT_PRIMARY';
export const REQUEST_ACCOUNT_WARNINGS = 'ACCOUNTS/REQUEST_ACCOUNT_WARNINGS';
