import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import error from '../../assets/error.png';
import successAlert from '../../assets/successAlert.png';
import warning from '../../assets/warning.png';
import { Colors } from '../../theme/colors';
import { SNACKBARTYPE } from './constants';
import { setGlobalSnackbar } from './globalSnackbar';
import { SnackbarContextProps } from './types';
import { SnackbarContext } from './SnackbarContext';

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<SNACKBARTYPE>(
    SNACKBARTYPE.SUCCESS,
  );

  const openSnackbar = useCallback(
    (message: string, type: SNACKBARTYPE = SNACKBARTYPE.SUCCESS) => {
      setSnackbarMessage(message);
      setSnackbarType(type);
      setSnackbarOpen(true);
    },
    [],
  );

  useEffect(() => {
    setGlobalSnackbar(openSnackbar);
  }, [openSnackbar]);

  const closeSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const contextValue: SnackbarContextProps = useMemo(
    () => ({
      openSnackbar,
    }),
    [openSnackbar],
  );

  const typeAttributes = {
    [SNACKBARTYPE.SUCCESS]: {
      color: Colors.chipSuccessBackground,
      icon: successAlert,
      textColor: Colors.chipSuccessText,
    },
    [SNACKBARTYPE.ERROR]: {
      color: Colors.failed,
      icon: error,
      textColor: Colors.textSecondary,
    },
    [SNACKBARTYPE.WARN]: {
      color: Colors.chipInfoBackground,
      icon: warning,
      textColor: Colors.textPrimary,
    },
    [SNACKBARTYPE.INFO]: {
      color: Colors.chipWarningBackground,
      icon: warning,
      textColor: Colors.chipWarningText,
    },
  };

  const { color, icon, textColor } = typeAttributes[snackbarType];

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}>
        <Alert
          variant="filled"
          severity={snackbarType}
          onClose={closeSnackbar}
          style={{
            backgroundColor: color,
            color: textColor,
            display: 'flex',
            alignItems: 'center',
            lineHeight: '26px',
          }}
          icon={
            <img
              src={icon}
              alt={snackbarType}
              style={{
                width: '26px',
                height: '26px',
                marginRight: '8px',
              }}
            />
          }>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
