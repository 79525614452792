import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mixpanel from 'mixpanel-browser';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AppButton from '../../../components/AppButton';
import AppTextInput from '../../../components/AppTextInput';
import AuthLayout from '../../../hocs/AuthLayout';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Colors } from '../../../theme/colors';
import { clearResId, login } from '../redux/authSlice';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { object, ObjectSchema, string } from 'yup';

interface ILoginForm {
  email: string;
  password: string;
}

const validationSchema: ObjectSchema<ILoginForm> = object({
  email: string().email('Enter a valid email').required('Email is required'),
  password: string().required('Password is required'),
});

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { resId: reduxResId } = useAppSelector(selectAuth); // Access resId from the Redux state

  const [showPassword, setShowPassword] = useState<boolean>(false);

  useMemo(() => {
    mixpanel.track('Page View - Login', {
      page: 'Login Page',
    });
  }, []);

  const form = useForm<ILoginForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = form.handleSubmit(async (values: ILoginForm) => {
    // Fallback: Get resId from URL search parameters if it's not in the Redux state
    const urlResId = searchParams.get('resId');
    const resId = reduxResId ?? (urlResId ? Number(urlResId) : undefined);
    const response = await dispatch(
      login({
        email: values.email,
        password: values.password,
        resId: resId,
      }),
    );

    if (response.meta.requestStatus === 'rejected') {
      form.setError('password', {
        type: 'manual',
        message: 'Invalid email or password',
      });
    }
    dispatch(clearResId());
  });

  const handleOnClickPasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <AuthLayout sxP={{ pb: 8 }}>
      <FormProvider {...form}>
        <Box
          component={'form'}
          onSubmit={onSubmit}
          sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">Sign in to your account</Typography>

          <Controller
            name="email"
            control={form.control}
            render={({ field: { onBlur, value, onChange } }) => (
              <AppTextInput
                label="Email"
                placeholder="yours@example.com"
                type="email"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={form.formState.errors.email?.message}
                data-testid="email"
                sx={{
                  marginTop: 4,
                }}
              />
            )}
          />

          <Box
            sx={{
              position: 'relative',
            }}>
            <Controller
              name="password"
              control={form.control}
              render={({ field: { onBlur, value, onChange } }) => (
                <AppTextInput
                  label="Password"
                  placeholder="your password"
                  type={showPassword ? 'text' : 'password'}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={form.formState.errors.password?.message}
                  icon={showPassword ? <VisibilityOff /> : <Visibility />}
                  onClickIcon={handleOnClickPasswordVisibility}
                  data-testid="password"
                  iconPosition="end"
                  labelRightComponent={
                    <Box>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate('/forgot-password');
                        }}
                        style={{
                          color: Colors.primary,
                          fontSize: 12,
                          textDecoration: 'none',
                        }}>
                        Forgot your password?
                      </a>
                    </Box>
                  }
                />
              )}
            />
          </Box>

          <AppButton
            type="submit"
            variant="contained"
            title="Continue"
            disabled={form.formState.isSubmitting}
            loading={form.formState.isSubmitting}
            sx={{
              mt: 3,
            }}
          />
        </Box>
      </FormProvider>
    </AuthLayout>
  );
};

export default LoginPage;
