export const REQUEST_NOTIFICATIONS =
  'SETTINGS/NOTIFICATIONS/REQUEST_NOTIFICATIONS';
export const REQUEST_TO_CREATE_NOTIFICATIONS =
  'SETTINGS/NOTIFICATIONS/REQUEST_TO_CREATE_NOTIFICATIONS';
export const REQUEST_SINGLE_NOTIFICATION =
  'SETTINGS/NOTIFICATIONS/REQUEST_SINGLE_NOTIFICATION';
export const REQUEST_TO_UPDATE_NOTIFICATION =
  'SETTINGS/NOTIFICATIONS/REQUEST_TO_UPDATE_NOTIFICATION';
export const REQUEST_TO_ENABLE_DISABLE_NOTIFICATION =
  'SETTINGS/NOTIFICATIONS/REQUEST_TO_ENABLE_DISABLE_NOTIFICATION';
export const REQUEST_TO_DELETE_NOTIFICATION =
  'SETTINGS/NOTIFICATIONS/REQUEST_TO_DELETE_NOTIFICATION';
