import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { DISHES_COLUMNS } from '../constants';
import {
  CreateDishRequestBodyDTO,
  DishesState,
  GetAllDishesQueryDTO,
  IDishesResponseDTO,
} from '../types/dishes.types';
import * as dishTypes from './dishesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchAllDishData = createAppAsyncThunk<
  PaginatedResponseDTO<IDishesResponseDTO, BasePaginationExtras>,
  GetAllDishesQueryDTO | undefined
>(dishTypes.REQUEST_ALL_DISHES, async (params, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<IDishesResponseDTO, BasePaginationExtras>
      >
    >(URLS.DISHES, {
      params,
    });
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchDishData = createAppAsyncThunk<
  PaginatedResponseDTO<IDishesResponseDTO, BasePaginationExtras>,
  Omit<GetAllDishesQueryDTO, 'limit' | 'skip' | 'search_key'> | undefined
>(dishTypes.REQUEST_DISHES, async (params, { rejectWithValue, getState }) => {
  try {
    const { limit, skip, search_key } = getState().menuPagination;
    const response = await Api.get<
      CommonResponseDTO<
        PaginatedResponseDTO<IDishesResponseDTO, BasePaginationExtras>
      >
    >(URLS.DISHES, {
      params: {
        ...params,
        limit,
        skip,
        search_key,
      },
    });
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const getSingleDish = createAppAsyncThunk<IDishesResponseDTO, string>(
  dishTypes.REQUEST_SINGLE_DISH,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.DISHES}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<IDishesResponseDTO>>(url);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createDish = createAppAsyncThunk<
  IDishesResponseDTO,
  CreateDishRequestBodyDTO
>(dishTypes.CREATE_DISHES, async (body, { rejectWithValue }) => {
  try {
    const response = await Api.post<CommonResponseDTO<IDishesResponseDTO>>(
      URLS.DISHES,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const updateDish = createAppAsyncThunk<
  IDishesResponseDTO,
  { id: string; body: CreateDishRequestBodyDTO }
>(dishTypes.UPDATE_DISHES, async ({ id, body }, { rejectWithValue }) => {
  try {
    const response = await Api.put<CommonResponseDTO<IDishesResponseDTO>>(
      `${URLS.DISHES}/${id}`,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const patchUpdateDish = createAppAsyncThunk<
  IDishesResponseDTO,
  { id: string; body: Partial<CreateDishRequestBodyDTO> }
>(dishTypes.PATCH_UPDATE_DISHES, async ({ id, body }, { rejectWithValue }) => {
  try {
    const response = await Api.patch<CommonResponseDTO<IDishesResponseDTO>>(
      `${URLS.DISHES}/${id}`,
      body,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const deleteDish = createAppAsyncThunk<string, string>(
  dishTypes.DELETE_DISHES,
  async (id, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.DISHES}/${id}`);
      return id;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateDishSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  dishTypes.REQUEST_TO_UPDATE_DISH_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const dishesData = [...cloneDeep(getState().dishes.dishesData)];

      const reorderData = reorder<IDishesResponseDTO>({
        list: dishesData,
        dragId,
        dropId,
      });

      if (!reorderData) return;

      const {
        list: newDishesData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setDishesState(newDishesData));

      const response = await Api.patch<CommonResponseDTO<IDishesResponseDTO>>(
        `${URLS.DISHES}/${dragId}`,
        {
          sequenceNumber: newDishesData[dropItemIndex].sequenceNumber,
        },
      );

      if (response.status !== 200) {
        newDishesData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newDishesData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setDishesState(newDishesData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const dishesInitialState: DishesState = {
  dishesData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  loadingSingleDish: false,
  refreshing: false,
  columns: DISHES_COLUMNS,
};

export const dishesSlice = createSlice({
  name: 'dishes',
  initialState: dishesInitialState,
  reducers: {
    resetDishesState: () => dishesInitialState,
    resetDishesEditingDataState: (state) => {
      state.editingData = undefined;
    },
    setDishesState: (state, action: { payload: IDishesResponseDTO[] }) => {
      state.dishesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDishData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchAllDishData.fulfilled, (state, action) => {
        state.dishesData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit;
        state.extras.skip = action.payload.extras.skip;
        state.extras.total = action.payload.extras.total;
        state.refreshing = false;
      })
      .addCase(fetchAllDishData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchDishData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchDishData.fulfilled, (state, action) => {
        state.dishesData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchDishData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleDish.pending, (state) => {
        state.loadingSingleDish = true;
      })
      .addCase(getSingleDish.fulfilled, (state, action) => {
        state.editingData = action.payload;
        state.loadingSingleDish = false;
      })
      .addCase(getSingleDish.rejected, (state) => {
        state.loadingSingleDish = false;
      })
      .addCase(createDish.pending, (state) => {
        state.loadingSingleDish = true;
      })
      .addCase(createDish.fulfilled, (state) => {
        state.loadingSingleDish = false;
      })
      .addCase(createDish.rejected, (state) => {
        state.loadingSingleDish = false;
      })
      .addCase(updateDish.pending, (state) => {
        state.loadingSingleDish = true;
      })
      .addCase(updateDish.fulfilled, (state, action) => {
        const updatedDish = action.payload;
        const index = state.dishesData.findIndex(
          (dish) => dish._id === updatedDish._id,
        );
        if (index !== -1) {
          state.dishesData[index] = updatedDish;
        }
        state.loadingSingleDish = false;
      })
      .addCase(updateDish.rejected, (state) => {
        state.loadingSingleDish = false;
      })
      .addCase(patchUpdateDish.pending, (state) => {
        state.loadingSingleDish = true;
      })
      .addCase(patchUpdateDish.fulfilled, (state, action) => {
        const updatedDish = action.payload;
        const index = state.dishesData.findIndex(
          (dish) => dish._id === updatedDish._id,
        );
        if (index !== -1) {
          state.dishesData[index] = {
            ...state.dishesData[index],
            ...updatedDish,
          };
        }
        state.loadingSingleDish = false;
      })
      .addCase(patchUpdateDish.rejected, (state) => {
        state.loadingSingleDish = false;
      })
      .addCase(deleteDish.pending, (state) => {
        state.loadingSingleDish = true;
      })
      .addCase(deleteDish.fulfilled, (state, action) => {
        const index = state.dishesData.findIndex(
          (dish) => dish._id === action.payload,
        );
        if (index !== -1) {
          state.dishesData.splice(index, 1);
        }
        state.loadingSingleDish = false;
      })
      .addCase(deleteDish.rejected, (state) => {
        state.loadingSingleDish = false;
      });
  },
});

export const { resetDishesState, resetDishesEditingDataState, setDishesState } =
  dishesSlice.actions;

export default dishesSlice.reducer;
