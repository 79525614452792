export const Colors = {
  greyBorder: '#BDBDBD',
  greyBorderLogin: '#E3E8EE',
  black: '#000000',
  white: '#FFFFFF',

  fontColorPrimary: '#30313D',
  fontColorSecondary: '#697386',
  fontColorInvert: '#FFFFFF',

  textPrimary: '#30313D',
  textSecondary: '#FFFFFF',

  primary: '#E27A39',
  secondary: '#F6F8FA',
  error: '#c20000',
  failed: '#DF1B41',

  primaryLight: '#fbebe1',
  errorLight: '#fcedb9',
  primaryFade: '#F5D3BD',

  backgroundPrimary: '#FFFFFF',
  backgroundSecondary: '#F6F8FA',
  backgroundLogin: '#F7FAFC',
  backgroundShade: '#EBEEF1',
  backgroundLight: '#f6f8fa7a',

  backdropPrimary: 'rgba(60, 66, 87, 0.08)',

  switchActiveColor: '#E27A39',
  switchInactiveColor: '#c0c8d2b3',

  borderPrimary: '#E3E8EE',

  chipDefaultBackground: '#EBEEF1',
  chipDefaultText: '#6A7383',

  chipSuccessBackground: '#D7F7C2',
  chipSuccessText: '#006908',

  chipFailedBackground: '#FFE7F2',
  chipFailedText: '#B3093C',

  chipWarningBackground: '#FDF5CE',
  chipWarningText: '#A68932',

  chipInfoBackground: '#CFF5F6',
  chipInfoText: '#0055BC',

  checkboxDeselected: '#FFFFFF',
  checkboxSelected: '#30313D',

  menuTabsBackground: '#FBFCFD',

  chartDeepBlue: '#294D69',
  chartLavender: '#B56DA5',
  chartCoralPink: '#DD7879',

  previewHeaderBackground: '#C6D0D4',

  primaryDark: '#c06015',

  cardSalesBackground: '#D7F7C270',

  toolTipSalesBackground: '#F9F5FF',
  toolTipOrdersBackground: '#FEF3F2',
  toolTipSalesChannelBackground: '#FFFAEB',
  toolTipOrderTypeBackground: '#ECFDF3',
  toolTipAnalyticsBackground: '#F0F9FF',

  shadowPrimary: 'rgba(60, 66, 87, 0.08)',

  supportCardBackground: '#F5FAFF',
};
