import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  BasePaginationExtras,
  CommonResponseDTO,
  PaginatedResponseDTO,
} from '../../../types/api.types';
import {
  CreateDiscountRequestBodyDTO,
  DiscountsState,
  GetAllDiscountsRequestQueryDTO,
  IDiscountsResponseDTO,
  IDiscountsStatusResponseDTO,
  UpdateDiscountRequestBodyDTO,
} from '../types/discounts.types';
import * as discountTypes from './discountsActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchDiscountsDataStatus = createAppAsyncThunk<
  IDiscountsStatusResponseDTO,
  void
>(discountTypes.REQUEST_DISCOUNTS_STATUS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<IDiscountsStatusResponseDTO>
    >(`${URLS.DISCOUNTS}/status`);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchDiscountsData = createAppAsyncThunk<
  PaginatedResponseDTO<IDiscountsResponseDTO, BasePaginationExtras>,
  | Omit<GetAllDiscountsRequestQueryDTO, 'limit' | 'skip' | 'search_key'>
  | undefined
>(
  discountTypes.REQUEST_DISCOUNTS,
  async (params, { rejectWithValue, getState }) => {
    try {
      const { limit, skip, search_key } = getState().discountsPagination;
      const response = await Api.get<
        CommonResponseDTO<
          PaginatedResponseDTO<IDiscountsResponseDTO, BasePaginationExtras>
        >
      >(URLS.DISCOUNTS, {
        params: {
          ...params,
          limit,
          skip,
          search_key,
        },
      });

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdateDiscountsData = createAppAsyncThunk<
  IDiscountsResponseDTO,
  { _id: string; data: UpdateDiscountRequestBodyDTO }
>(
  discountTypes.REQUEST_TO_PATCH_UPDATE_DISCOUNTS,
  async ({ _id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IDiscountsResponseDTO>
      >(`${URLS.DISCOUNTS}/${_id}`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createDiscountsData = createAppAsyncThunk<
  IDiscountsResponseDTO,
  CreateDiscountRequestBodyDTO
>(
  discountTypes.REQUEST_TO_CREATE_DISCOUNTS,
  async (data: CreateDiscountRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<IDiscountsResponseDTO>>(
        URLS.DISCOUNTS,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleDiscount = createAppAsyncThunk<
  IDiscountsResponseDTO,
  string
>(discountTypes.REQUEST_SINGLE_DISCOUNT, async (id, { rejectWithValue }) => {
  try {
    const url = `${URLS.DISCOUNTS}/${id}`;
    const response =
      await Api.get<CommonResponseDTO<IDiscountsResponseDTO>>(url);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const discountsInitialState: DiscountsState = {
  discountSDataStatus: {
    haveDiscounts: false,
  },
  discountsData: [],
  extras: {
    limit: 10,
    skip: 0,
    total: 0,
  },
  refreshing: false,
  loadingSingleDiscount: false,
  selectedDiscountsTypes: [],
  selectedDiscountsAppliedToTypes: [],
  editingData: undefined,
  creatingData: undefined,
};

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState: discountsInitialState,
  reducers: {
    resetDiscountsState: () => discountsInitialState,
    setCreatingData: (
      state,
      action: PayloadAction<Partial<CreateDiscountRequestBodyDTO>>,
    ) => {
      state.creatingData = action.payload;
    },
    resetCreatingData: (state) => {
      state.creatingData = undefined;
    },
    resetEditingData: (state) => {
      state.editingData = undefined;
    },
    setDiscountsTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedDiscountsTypes = action.payload;
    },
    setDiscountsAppliedToTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedDiscountsAppliedToTypes = action.payload;
    },
    setDiscountsState: (
      state,
      action: { payload: IDiscountsResponseDTO[] },
    ) => {
      state.discountsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscountsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchDiscountsData.fulfilled, (state, action) => {
        state.discountsData = action.payload.results ?? [];
        state.extras.limit = action.payload.extras.limit ?? 10;
        state.extras.skip = action.payload.extras.skip ?? 0;
        state.extras.total = action.payload.extras.total ?? 0;
        state.refreshing = false;
      })
      .addCase(fetchDiscountsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createDiscountsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createDiscountsData.fulfilled, (state, action) => {
        state.discountsData.push(action.payload);
        state.refreshing = false;
      })
      .addCase(createDiscountsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(patchUpdateDiscountsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(patchUpdateDiscountsData.fulfilled, (state, action) => {
        const updatedDiscount = action.payload;
        const index = state.discountsData.findIndex(
          (discount) => discount._id === updatedDiscount._id,
        );
        if (index !== -1) {
          state.discountsData[index] = {
            ...state.discountsData[index],
            ...updatedDiscount,
          };
        }
        state.refreshing = false;
      })
      .addCase(patchUpdateDiscountsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleDiscount.pending, (state) => {
        state.loadingSingleDiscount = true;
      })
      .addCase(getSingleDiscount.fulfilled, (state, action) => {
        state.editingData = action.payload;
        state.loadingSingleDiscount = false;
      })
      .addCase(getSingleDiscount.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchDiscountsDataStatus.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchDiscountsDataStatus.fulfilled, (state, action) => {
        state.discountSDataStatus = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchDiscountsDataStatus.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const {
  setCreatingData,
  resetCreatingData,
  resetEditingData,
  resetDiscountsState,
  setDiscountsState,
  setDiscountsTypes,
  setDiscountsAppliedToTypes,
} = discountsSlice.actions;

export default discountsSlice.reducer;
