export const REQUEST_TAXES = 'TAXES/REQUEST_TAXES';
export const REQUEST_SINGLE_TAX = 'TAXES/REQUEST_SINGLE_TAX';
export const REQUEST_TO_CREATE_TAXES = 'TAXES/REQUEST_TO_CREATE_TAXES';
export const REQUEST_TO_PATCH_UPDATE_TAXES =
  'TAXES/REQUEST_TO_PATCH_UPDATE_TAXES';

export const REQUEST_TO_GET_TAX_CUSTOMIZATION_STATUS =
  'TAXES/REQUEST_TO_GET_TAX_CUSTOMIZATION_STATUS';
export const REQUEST_TO_UPDATE_TAX_CUSTOMIZATION_STATUS =
  'TAXES/REQUEST_TO_UPDATE_TAX_CUSTOMIZATION_STATUS';
