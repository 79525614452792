import {
  APP_LIST_ALIGNMENT,
  IAppListColumn,
} from '../../../components/AppList/types/list.types';
import {
  APP_TABLE_ALIGNMENT,
  IAppTableColumn,
} from '../../../components/AppTable/types/table.types';

export * from './tabs.constants';

export enum ACTION_KEYS {
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABLE = 'AVAILABLE',
  DELETE = 'DELETE',
  HIDE = 'HIDE',
  EDIT = 'EDIT',
  DEACTIVATE = 'DEACTIVATE',
  RESET = 'RESET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CLEAR = 'CLEAR',
  ACTIVATE = 'ACTIVATE',
  PRIMARY = 'PRIMARY',
}

export const CATEGORIES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'itemsCount',
    header: 'ITEMS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const SUBCATEGORIES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'itemsCount',
    header: 'ITEMS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults222',
    visible: true,
    visibilityChangeable: false,
  },
];

export const CHOICE_GROUPS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'CHOICE GROUP NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '150px',
      maxWidth: '200px',
    },
  },
  {
    accessorKey: 'choiceItems',
    header: 'CHOICE ITEMS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      minWidth: '200px',
      maxWidth: '350px',
    },
  },
  {
    accessorKey: 'required',
    header: 'REQUIRED',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'minQty',
    header: 'MIN CHOICES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'maxQty',
    header: 'MAX CHOICES',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const CHOICE_ITEMS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'price',
    header: 'PRICE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DISHES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'ITEM NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '100px',
    },
  },
  {
    accessorKey: 'category',
    header: 'CATEGORY',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '200px',
      textTransform: 'capitalize',
    },
  },
  {
    accessorKey: 'price',
    header: 'PRICE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '150px',
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
  },
];

export const PAGES_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
    bodySx: {
      minWidth: '100px',
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: true,
  },
];

export const ADDON_GROUPS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'GROUP NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  {
    accessorKey: 'addOnItems',
    header: 'ADD-ONS',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const ADDON_ITEMS_COLUMNS: IAppTableColumn[] = [
  {
    accessorKey: 'name',
    header: 'NAME',
    headerAlign: APP_TABLE_ALIGNMENT.LEFT,
    bodyAlign: APP_TABLE_ALIGNMENT.LEFT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
    bodySx: {
      textTransform: 'capitalize',
      minWidth: '300px',
      maxWidth: '300px',
    },
  },
  {
    accessorKey: 'price',
    header: 'PRICE',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'max',
    header: 'MAXIMUM ADDONS',
    headerAlign: APP_TABLE_ALIGNMENT.RIGHT,
    bodyAlign: APP_TABLE_ALIGNMENT.RIGHT,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    headerAlign: APP_TABLE_ALIGNMENT.CENTER,
    bodyAlign: APP_TABLE_ALIGNMENT.CENTER,
    category: 'Defaults',
    visible: true,
    visibilityChangeable: false,
  },
];

export const DISH_CREATION_CHOICE_GROUPS_LIST_COLUMNS: IAppListColumn[] = [
  {
    accessorKey: 'name',
    header: 'CHOICE GROUP NAME',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'required',
    header: 'REQUIRED',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'minQty',
    header: 'MIN QTY',
    headerAlign: APP_LIST_ALIGNMENT.RIGHT,
    bodyAlign: APP_LIST_ALIGNMENT.RIGHT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'maxQty',
    header: 'MAX QTY',
    headerAlign: APP_LIST_ALIGNMENT.RIGHT,
    bodyAlign: APP_LIST_ALIGNMENT.RIGHT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
];

export const DISH_CREATION_ADDON_GROUPS_LIST_COLUMNS: IAppListColumn[] = [
  {
    accessorKey: 'name',
    header: 'CHOICE GROUP NAME',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
  {
    accessorKey: 'maxQty',
    header: 'MAX QTY',
    headerAlign: APP_LIST_ALIGNMENT.RIGHT,
    bodyAlign: APP_LIST_ALIGNMENT.RIGHT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
];

export const PAGE_CREATION_CATEGORIES_LIST_COLUMNS: IAppListColumn[] = [
  {
    accessorKey: 'name',
    header: 'CATEGORY NAME',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
];

export const PAGE_CREATION_DISHES_LIST_COLUMNS: IAppListColumn[] = [
  {
    accessorKey: 'name',
    header: 'ITEM NAME',
    headerAlign: APP_LIST_ALIGNMENT.LEFT,
    bodyAlign: APP_LIST_ALIGNMENT.LEFT,
    category: 'Defaults',
    exportable: false,
    visible: true,
    visibilityChangeable: false,
  },
];
