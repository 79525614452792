import { SxProps, Theme } from '@mui/material/styles';
import {
  MRT_Cell,
  MRT_Column,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import { ReactNode } from 'react';

import { IChipCell } from '../../AppCells/ChipCell';
import { ICurrencyCell } from '../../AppCells/CurrencyCell';
import { IDateCell } from '../../AppCells/DateCell';
import { ITextCell } from '../../AppCells/TextCell';

export enum APP_TABLE_ALIGNMENT {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum APP_COLUMN_TYPES {
  TEXT = 'TEXT',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  CHIP = 'CHIP',
}

export enum ACTION_TYPES {
  DEFAULT = 'DEFAULT',
  HEADER = 'HEADER',
  DIVIDER = 'DIVIDER',
}

export enum FILTER_TYPE {
  SELECT = 'select',
  CHECKBOX = 'checkBox',
}

export type IAppTableCell = {
  type: APP_COLUMN_TYPES;
  data: unknown;
};

export type IAppTableRow<T> = {
  [key in keyof T]:
    | IChipCell
    | ICurrencyCell
    | ITextCell
    | IDateCell
    | string
    | ITableAction[]
    | IAppTableRow<T>[];
} & {
  key: string;
  actions: ITableAction[];
  subRows: IAppTableRow<T>[];
};

export interface IAppTableColumn {
  accessorKey: string;
  header: string;
  headerAlign: APP_TABLE_ALIGNMENT;
  bodyAlign: APP_TABLE_ALIGNMENT;
  category: string;

  visible: boolean;
  visibilityChangeable?: boolean;
  exportOnly?: boolean;

  headerSx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;

  enableSorting?: boolean;
}

export interface ITableAction {
  key: string;
  name: string;
  fontColor?: string;
  fontWeight?: string | number;
  backgroundColor?: string;
  disabled?: boolean;
  type?: ACTION_TYPES;
}

export interface AppTableProps<T> {
  columns: IAppTableColumn[];
  rows: IAppTableRow<T>[];
  refreshing?: boolean;
  enablePagination?: boolean;
  pagination?: {
    pageIndex: number;
    pageSize: number;
    total: number;
    showAllStatus?: boolean;
  };
  handleNextClick?: () => void;
  handlePreviousClick?: () => void;
  disableNext?: boolean;
  onChangeColumnVisibility?: (newColumns: IAppTableColumn[]) => void;
  emptyComponent?: JSX.Element;
  hideHeader?: boolean;
  onClickActions?: (key: string, data: IAppTableRow<T>) => void;
  onClickRow?: (data: IAppTableRow<T>) => void;
  enableRowActions?: boolean;
  enableExpanding?: boolean;
  enableExpandAll?: boolean;
  dragLoading?: boolean;
  onDragDropChange?: (dragId: string, dropId: string) => void;
  preview?: boolean;
  lastUpdatedOn?: Date;
  sortOrder?: string;
  sortColumn?: string;
  onPressSortColumn?: (column: string) => void;
  enableShowAllResultsButton?: boolean;
  handleShowAllResultsClick?: () => void;
}

export interface AppTableCellWrapperProps<T extends Record<string, unknown>> {
  cell: MRT_Cell<T>;
  renderedCellValue: number | string | ReactNode;
  column: MRT_Column<T>;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}
