import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import * as adyenSessionTypes from './adyenSessionActionTypes';
import { AdyenSessionResponseDTO } from '../../../types/features/adyen.types';
import { CommonResponseDTO } from '../../../types/api.types';

export interface AdyenSessionsState {
  platformSessionId?: string;
  platformSessionToken?: string;
  isLoadingPlatformSession: boolean;
}

const initialState: AdyenSessionsState = {
  isLoadingPlatformSession: false,
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchPlatformSession = createAppAsyncThunk<
  AdyenSessionResponseDTO,
  void,
  { rejectValue: string }
>(
  adyenSessionTypes.REQUEST_PLATFORM_SESSION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get<
        CommonResponseDTO<AdyenSessionResponseDTO>
      >(URLS.ADYEN.SESSION);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('something went wrong');
    }
  },
);

export const adyenSessionsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    resetAdyenSessionState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlatformSession.pending, (state) => {
        state.isLoadingPlatformSession = true;
      })
      .addCase(fetchPlatformSession.fulfilled, (state, action) => {
        state.platformSessionId = action.payload.id;
        state.platformSessionToken = action.payload.token;
        state.isLoadingPlatformSession = false;
      })
      .addCase(fetchPlatformSession.rejected, (state) => {
        state.isLoadingPlatformSession = false;
      });
  },
});

export const { resetAdyenSessionState } = adyenSessionsSlice.actions;

export default adyenSessionsSlice.reducer;
