import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar/constants';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import {
  ICustomPaymentType,
  IDefaultPaymentType,
  IPaymentTypesRequestBodyDTO,
  IPaymentTypesResponseDTO,
  IPaymentTypesState,
} from '../types/payment-types.types';
import * as paymentTypesTypes from './paymentTypesActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchPaymentTypesData = createAsyncThunk<
  IPaymentTypesResponseDTO,
  void
>(paymentTypesTypes.REQUEST_PAYMENT_TYPES, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<CommonResponseDTO<IPaymentTypesResponseDTO>>(
      URLS.PAYMENT_TYPES,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const createPaymentTypesData = createAppAsyncThunk<
  ICustomPaymentType,
  IPaymentTypesRequestBodyDTO
>(
  paymentTypesTypes.REQUEST_TO_CREATE_PAYMENT_TYPES,
  async (data: IPaymentTypesRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<ICustomPaymentType>>(
        URLS.PAYMENT_TYPES,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deletePaymentTypesData = createAppAsyncThunk<string, number>(
  paymentTypesTypes.REQUEST_TO_DELETE_PAYMENT_TYPES,
  async (id: number, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.PAYMENT_TYPES}/${id}`);
      return id.toString();
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSinglePaymentType = createAppAsyncThunk<
  ICustomPaymentType,
  string
>(
  paymentTypesTypes.REQUEST_SINGLE_PAYMENT_TYPE,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.PAYMENT_TYPES}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<ICustomPaymentType>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const patchUpdatePaymentTypesData = createAppAsyncThunk<
  IPaymentTypesResponseDTO,
  Partial<IDefaultPaymentType>
>(
  paymentTypesTypes.REQUEST_TO_PATCH_UPDATE_PAYMENT_TYPES,
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<IPaymentTypesResponseDTO>
      >(`${URLS.PAYMENT_TYPES}/default`, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateCustomPaymentTypesData = createAppAsyncThunk<
  ICustomPaymentType,
  { id: string; data: Partial<IPaymentTypesRequestBodyDTO> }
>(
  paymentTypesTypes.REQUEST_TO_PATCH_UPDATE_PAYMENT_TYPES,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<CommonResponseDTO<ICustomPaymentType>>(
        `${URLS.PAYMENT_TYPES}/${id}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateCustomPaymentsSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  paymentTypesTypes.REQUEST_TO_UPDATE_CUSTOM_PAYMENT_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const customPaymentTypes = [
        ...cloneDeep(
          getState().paymentTypes.paymentTypesData.customPaymentTypes,
        ),
      ];

      const reorderData = reorder<ICustomPaymentType>({
        list: customPaymentTypes,
        dragId,
        dropId,
        id: true,
      });

      if (!reorderData) return;

      const {
        list: newCustomPaymentTypes,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setCustomPaymentTypesState(newCustomPaymentTypes));

      const response = await Api.put<CommonResponseDTO<ICustomPaymentType>>(
        `${URLS.PAYMENT_TYPES}/${dragId}`,
        {
          sequenceNumber: newCustomPaymentTypes[dropItemIndex].sequenceNumber,
        },
      );

      if (response.status !== 200) {
        newCustomPaymentTypes[dragItemIndex].sequenceNumber =
          dragSequenceNumber;
        newCustomPaymentTypes.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setCustomPaymentTypesState(newCustomPaymentTypes));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const PaymentTypesInitialState: IPaymentTypesState = {
  paymentTypesData: {
    customPaymentTypes: [],
    defaultPaymentTypes: [],
    walletPaymentTypes: [],
  },
  refreshing: false,
  loadingSinglePaymentType: false,
  saveTicketStatus: false,
};

export const paymentTypesSlice = createSlice({
  name: 'paymentTypes',
  initialState: PaymentTypesInitialState,
  reducers: {
    resetPaymentTypesState: () => PaymentTypesInitialState,
    setCustomPaymentTypesState: (
      state,
      action: { payload: ICustomPaymentType[] },
    ) => {
      state.paymentTypesData.customPaymentTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentTypesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchPaymentTypesData.fulfilled, (state, action) => {
        state.paymentTypesData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchPaymentTypesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createPaymentTypesData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createPaymentTypesData.fulfilled, (state, action) => {
        state.paymentTypesData.customPaymentTypes.push(action.payload);
        state.refreshing = false;
      })
      .addCase(createPaymentTypesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateCustomPaymentTypesData.pending, (state) => {
        state.loadingSinglePaymentType = true;
      })
      .addCase(updateCustomPaymentTypesData.fulfilled, (state, action) => {
        const updatedCustomPaymentType = action.payload;
        const index = state.paymentTypesData.customPaymentTypes.findIndex(
          (customPayment) => customPayment.id === updatedCustomPaymentType.id,
        );
        if (index !== -1) {
          state.paymentTypesData.customPaymentTypes[index] =
            updatedCustomPaymentType;
        }
        state.loadingSinglePaymentType = false;
      })
      .addCase(updateCustomPaymentTypesData.rejected, (state) => {
        state.loadingSinglePaymentType = false;
      })
      .addCase(getSinglePaymentType.pending, (state) => {
        state.loadingSinglePaymentType = true;
      })
      .addCase(getSinglePaymentType.fulfilled, (state) => {
        state.loadingSinglePaymentType = false;
      })
      .addCase(getSinglePaymentType.rejected, (state) => {
        state.loadingSinglePaymentType = false;
      })
      .addCase(deletePaymentTypesData.pending, (state) => {
        state.refreshing = false;
      })
      .addCase(deletePaymentTypesData.fulfilled, (state, action) => {
        const idToDelete = Number(action.payload);
        const index = state.paymentTypesData.customPaymentTypes.findIndex(
          (customPayment) => customPayment.id === idToDelete,
        );
        if (index !== -1) {
          state.paymentTypesData.customPaymentTypes.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deletePaymentTypesData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateCustomPaymentsSequenceNumbers.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateCustomPaymentsSequenceNumbers.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(updateCustomPaymentsSequenceNumbers.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetPaymentTypesState, setCustomPaymentTypesState } =
  paymentTypesSlice.actions;

export default paymentTypesSlice.reducer;
