import CategoryIcon from '@mui/icons-material/Category';
import DishesIcon from '@mui/icons-material/Fastfood';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import { IVerticalTabItem } from '../../../components/AppVerticalTabs';

export enum MENU_NAVIGATION_TABS {
  PAGES = 'pages',
  DISHES = 'items',
  CATEGORIES = 'categories',
  SUB_CATEGORIES = 'sub_categories',
  MODIFIERS = 'modifiers',
}

export enum MENU_NAVIGATION_NESTED_TABS {
  CHOICES = 'choices',
  ADDONS = 'addons',
}

export enum CREATE_CHOICE_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  ADD_CHOICE_ITEMS = 'add_choice_items',
}

export enum CREATE_ADDON_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  ADD_ADDON_ITEMS = 'add_addon_items',
}

export enum EDIT_CHOICE_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  ADD_CHOICE_ITEMS = 'edit_choice_items',
}

export enum EDIT_ADDON_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  ADD_ADDON_ITEMS = 'edit_addon_items',
}

export enum CREATE_DISHES_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  CHOICES = 'choices',
  ADDONS = 'addons',
  UPLOAD_IMAGES = 'upload_images',
}

export enum CREATE_PAGES_NAVIGATION_TABS {
  BASIC_DETAILS = 'basic_details',
  CATEGORIES = 'categories',
  DISHES = 'items',
}

export const MENU_TABS: IVerticalTabItem[] = [
  {
    id: MENU_NAVIGATION_TABS.PAGES,
    label: 'Pages',
    Icon: CategoryIcon,
    path: '/menu/pages',
  },
  {
    id: MENU_NAVIGATION_TABS.DISHES,
    label: 'Items',
    Icon: DishesIcon,
    path: '/menu/items',
  },
  {
    id: MENU_NAVIGATION_TABS.CATEGORIES,
    label: 'Categories',
    Icon: CategoryIcon,
    path: '/menu/categories',
  },
  {
    id: MENU_NAVIGATION_TABS.SUB_CATEGORIES,
    label: 'Subcategories',
    Icon: CategoryIcon,
    path: '/menu/subcategories',
  },
  {
    id: MENU_NAVIGATION_TABS.MODIFIERS,
    label: 'Modifiers',
    Icon: RestaurantIcon,
    path: '',
    nestedTabs: [
      {
        id: MENU_NAVIGATION_NESTED_TABS.CHOICES,
        label: 'Choices',
        path: '/menu/choices',
      },
      {
        id: MENU_NAVIGATION_NESTED_TABS.ADDONS,
        label: 'Add-Ons',
        path: '/menu/addons',
      },
    ],
  },
];
