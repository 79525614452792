import {
  addBreadcrumb,
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  withScope,
} from '@sentry/react';
import axios, { AxiosError, HttpStatusCode } from 'axios';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import { logout } from '../../src/features/auth/redux/authSlice';
import { store } from '../../src/redux/store';
import Environment from './environment';

export const initSentry = () => {
  init({
    dsn: 'https://56209fedcd0db3c782ce7be61ab72609@o4504932505878528.ingest.sentry.io/4506176966950912',
    environment: Environment.env,
    dist: Environment.version,
    release: Environment.version,
    enabled: Environment.sentryEnabled,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    autoSessionTracking: false,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
  });
};

export const SentryCaptureError = (error: unknown) => {
  try {
    if (axios.isCancel(error)) {
      return null;
    }

    if (
      error instanceof AxiosError &&
      (error.code === 'ERR_NETWORK' || !error.response)
    ) {
      return null;
    }

    if (
      error instanceof AxiosError &&
      error.response?.status === HttpStatusCode.Unauthorized
    ) {
      store.dispatch(logout());
      return null;
    }

    withScope(() => {
      captureException(error);
    });
  } catch (err) {
    console.error('Sentry Error', err);
  }
};

export const CaptureXHRBreadcrumb = ({
  url,
  method,
  status_code,
  reason,
  responseBody,
  requestBody,
}: {
  url: string;
  method?: string;
  status_code?: number;
  reason?: string;
  responseBody?: unknown;
  requestBody?: unknown;
}) => {
  try {
    addBreadcrumb({
      type: 'http',
      category: 'xhr',
      level: 'info',
      timestamp: Date.now(),
      data: {
        url,
        method,
        status_code,
        reason,
        responseBody: JSON.stringify(responseBody),
        requestBody: JSON.stringify(requestBody),
      },
    });
  } catch (err) {
    console.error('Sentry Error', err);
  }
};
