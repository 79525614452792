import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import {
  CreateNotificationsBodyDTO,
  INotificationsResponseCreateDTO,
  INotificationsResponseDTO,
  UpdateNotificationsBodyDTO,
} from '../types/notifications.types';
import * as notificationTypes from './notificationsActionTypes';

export interface NotificationState {
  status: string;
  refreshing: boolean;
  isLoadingSingle: boolean;
  notificationData: INotificationsResponseDTO[];
}

const initialState: NotificationState = {
  status: 'idle',
  refreshing: false,
  isLoadingSingle: false,
  notificationData: [],
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchNotificationData = createAsyncThunk<
  INotificationsResponseDTO[],
  void
>(notificationTypes.REQUEST_NOTIFICATIONS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.get<
      CommonResponseDTO<INotificationsResponseDTO[]>
    >(URLS.NOTIFICATIONS);
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const createNotifications = createAppAsyncThunk<
  INotificationsResponseCreateDTO,
  CreateNotificationsBodyDTO
>(
  notificationTypes.REQUEST_TO_CREATE_NOTIFICATIONS,
  async (data: CreateNotificationsBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<INotificationsResponseCreateDTO>
      >(URLS.NOTIFICATIONS, data);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const getSingleNotification = createAppAsyncThunk<
  INotificationsResponseDTO,
  string
>(
  notificationTypes.REQUEST_SINGLE_NOTIFICATION,
  async (id, { rejectWithValue }) => {
    try {
      const url = `${URLS.NOTIFICATIONS}/${id}`;
      const response =
        await Api.get<CommonResponseDTO<INotificationsResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateNotification = createAppAsyncThunk<
  INotificationsResponseDTO,
  { id: string; data: Partial<UpdateNotificationsBodyDTO> }
>(
  notificationTypes.REQUEST_TO_UPDATE_NOTIFICATION,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Api.put<
        CommonResponseDTO<INotificationsResponseDTO>
      >(`${URLS.NOTIFICATIONS}/${id}`, data);
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.Conflict
      ) {
        openGlobalSnackbar('Email already exists', SNACKBARTYPE.ERROR);
        return rejectWithValue(error.response?.data?.message);
      }

      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const enableDisableNotification = createAppAsyncThunk<
  INotificationsResponseDTO,
  string
>(
  notificationTypes.REQUEST_TO_ENABLE_DISABLE_NOTIFICATION,
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.patch<
        CommonResponseDTO<INotificationsResponseDTO>
      >(`${URLS.NOTIFICATIONS}/${id}`);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteNotification = createAppAsyncThunk<
  INotificationsResponseDTO,
  string
>(
  notificationTypes.REQUEST_TO_DELETE_NOTIFICATION,
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete<
        CommonResponseDTO<INotificationsResponseDTO>
      >(`${URLS.NOTIFICATIONS}/${id}`);
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotificationsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotificationData.pending, (state) => {
        state.status = 'loading';
        state.refreshing = true;
      })
      .addCase(fetchNotificationData.fulfilled, (state, action) => {
        state.notificationData = action.payload;
        state.status = 'success';
        state.refreshing = false;
      })
      .addCase(fetchNotificationData.rejected, (state) => {
        state.status = 'failed';
        state.refreshing = false;
      })
      .addCase(createNotifications.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createNotifications.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(createNotifications.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleNotification.pending, (state) => {
        state.isLoadingSingle = true;
      })
      .addCase(getSingleNotification.fulfilled, (state) => {
        state.isLoadingSingle = false;
      })
      .addCase(getSingleNotification.rejected, (state) => {
        state.isLoadingSingle = false;
      })
      .addCase(updateNotification.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateNotification.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(updateNotification.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(enableDisableNotification.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(enableDisableNotification.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(enableDisableNotification.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteNotification.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetNotificationsState } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.payments;

export default notificationsSlice.reducer;
