export const REQUEST_LOGIN = 'AUTH/REQUEST_LOGIN';
export const REQUEST_CHANGE_RESTAURANT = 'AUTH/REQUEST_CHANGE_RESTAURANT';
export const REQUEST_PROFILE = 'AUTH/REQUEST_PROFILE';
export const REQUEST_RESTAURANT = 'AUTH/REQUEST_RESTAURANT';
export const REQUEST_TOKEN_VERIFICATION = 'AUTH/REQUEST_TOKEN_VERIFICATION';
export const REQUEST_REGISTER = 'AUTH/REQUEST_REGISTER';
export const REQUEST_LOGOUT = 'AUTH/REQUEST_LOGOUT';
export const REQUEST_FORGOT_PASSWORD = 'AUTH/REQUEST_FORGOT_PASSWORD';
export const REQUEST_OTP_VERIFICATION = 'AUTH/REQUEST_OTP_VERIFICATION';
export const REQUEST_PASSWORD_RESET = 'AUTH/REQUEST_PASSWORD_RESET';
