import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, HttpStatusCode } from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import { SNACKBARTYPE } from '../../../components/AppSnackbar';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SentryCaptureError } from '../../../config/sentry-setup';
import { URLS } from '../../../constants';
import Api from '../../../redux/api';
import { AppDispatch, RootState } from '../../../redux/store';
import { CommonResponseDTO } from '../../../types/api.types';
import { reorder } from '../../../utils/dnd.utils';
import { TICKET_COLUMNS } from '../constants';
import {
  CreateTicketsRequestBodyDTO,
  ITicketsConfigResponse,
  ITicketsResponseDTO,
  TicketsState,
} from '../types/tickets.types';
import * as ticketsTypes from './ticketsActionTypes';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const fetchTicketConfigs = createAsyncThunk<
  ITicketsConfigResponse,
  number
>(ticketsTypes.REQUEST_TICKETS_CONFIG, async (resId, { rejectWithValue }) => {
  try {
    const url = `${URLS.PREDEFINED_TICKETS}/configs?resId=${resId}`;
    const response =
      await Api.get<CommonResponseDTO<ITicketsConfigResponse>>(url);

    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const fetchTickets = createAsyncThunk<
  ITicketsResponseDTO[],
  { hasArea?: boolean } | undefined
>(ticketsTypes.REQUEST_TICKETS, async (args, { rejectWithValue }) => {
  try {
    const queryParams =
      args?.hasArea !== undefined ? `?hasArea=${args.hasArea}` : '';
    const response = await Api.get<CommonResponseDTO<ITicketsResponseDTO[]>>(
      `${URLS.PREDEFINED_TICKETS}${queryParams}`,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const getSingleTicket = createAppAsyncThunk<
  ITicketsResponseDTO,
  { resId: number; ticketId: string }
>(
  ticketsTypes.REQUEST_SINGLE_TICKET,
  async ({ resId, ticketId }, { rejectWithValue }) => {
    try {
      const url = `${URLS.PREDEFINED_TICKETS}/${ticketId}?resId=${resId}`;
      const response =
        await Api.get<CommonResponseDTO<ITicketsResponseDTO>>(url);

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const togglePredefinedTickets = createAsyncThunk<
  { predefinedTickets: boolean },
  number
>(
  ticketsTypes.REQUEST_TOGGLE_PREDEFINED_TICKETS,
  async (resId: number, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<ITicketsConfigResponse>
      >(`${URLS.PREDEFINED_TICKETS}/predefine?resId=${resId}`, {});
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);
export const toggleSaveTickets = createAsyncThunk<
  { saveTickets: boolean },
  number
>(
  ticketsTypes.REQUEST_TOGGLE_SAVE_TICKETS,
  async (resId: number, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<ITicketsConfigResponse>
      >(`${URLS.PREDEFINED_TICKETS}/save?resId=${resId}`, {});
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'Cannot disable save tables while there are ongoing orders',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const createTicket = createAsyncThunk<
  ITicketsResponseDTO,
  CreateTicketsRequestBodyDTO
>(
  ticketsTypes.REQUEST_CREATE_TICKET,
  async (data: CreateTicketsRequestBodyDTO, { rejectWithValue }) => {
    try {
      const response = await Api.post<CommonResponseDTO<ITicketsResponseDTO>>(
        `${URLS.PREDEFINED_TICKETS}?resId=${data.resId}`,
        { name: data.name },
      );

      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateTicketsData = createAppAsyncThunk<
  ITicketsResponseDTO,
  { ticketId: string; data: { name: string } }
>(
  ticketsTypes.REQUEST_TO_UPDATE_TICKET,
  async ({ ticketId, data }, { rejectWithValue }) => {
    try {
      const response = await Api.patch<CommonResponseDTO<ITicketsResponseDTO>>(
        `${URLS.PREDEFINED_TICKETS}/${ticketId}`,
        data,
      );
      return response.data.data;
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const deleteTicketsData = createAppAsyncThunk<string, string>(
  ticketsTypes.REQUEST_TO_DELETE_TICKET,
  async (id: string, { rejectWithValue }) => {
    try {
      await Api.delete(`${URLS.PREDEFINED_TICKETS}/${id}`);
      return id;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'There are ongoing orders on this ticket. Complete them before deleting the ticket.',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const clearPredefinedTicket = createAsyncThunk<
  ITicketsResponseDTO,
  string
>(
  ticketsTypes.REQUEST_TO_CLEAR_TICKET,
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await Api.put<CommonResponseDTO<ITicketsResponseDTO>>(
        `${URLS.PREDEFINED_TICKETS}/${ticketId}/clear`,
      );
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        openGlobalSnackbar(
          'There are ongoing orders on this ticket. Complete them before clearing the ticket.',
          SNACKBARTYPE.ERROR,
        );
        return rejectWithValue(error.response?.data?.message);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const updateTicketSequenceNumbers = createAppAsyncThunk<
  void,
  { dragId: string; dropId: string }
>(
  ticketsTypes.REQUEST_TO_UPDATE_TICKET_SEQUENCE_NUMBER,
  async ({ dragId, dropId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const ticketsData = [...cloneDeep(getState().tickets.ticketsData)];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const reorderData = reorder<any>({
        list: ticketsData,
        dragId,
        dropId,
        id: true,
      });

      if (!reorderData) return;

      const {
        list: newTicketsData,
        dragItemIndex,
        dropItemIndex,
        dragSequenceNumber,
      } = reorderData;

      dispatch(setTicketsState(newTicketsData));

      const response = await Api.patch<CommonResponseDTO<ITicketsResponseDTO>>(
        `${URLS.PREDEFINED_TICKETS}/${dragId}`,
        {
          sequenceNumber: newTicketsData[dropItemIndex].sequenceNumber,
        },
      );

      if (response.status !== 200) {
        newTicketsData[dragItemIndex].sequenceNumber = dragSequenceNumber;
        newTicketsData.sort(
          (a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0),
        );
        dispatch(setTicketsState(newTicketsData));
      }
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Drag and drop action failed', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const toggleCloseShift = createAsyncThunk<ITicketsConfigResponse, void>(
  ticketsTypes.REQUEST_TOGGLE_SHIFT_CLOSE,
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.post<
        CommonResponseDTO<ITicketsConfigResponse>
      >(`${URLS.PREDEFINED_TICKETS}/close-shift`);
      return response.data.data;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.status === HttpStatusCode.UnprocessableEntity
      ) {
        return rejectWithValue(HttpStatusCode.UnprocessableEntity);
      }
      SentryCaptureError(error);
      openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
      return rejectWithValue('An error occurred');
    }
  },
);

export const clearAllOngoingTickets = createAsyncThunk<
  ITicketsResponseDTO,
  void
>(ticketsTypes.REQUEST_TO_CLEAR_ALL_TICKETS, async (_, { rejectWithValue }) => {
  try {
    const response = await Api.put<CommonResponseDTO<ITicketsResponseDTO>>(
      `${URLS.PREDEFINED_TICKETS}/clear`,
    );
    return response.data.data;
  } catch (error) {
    SentryCaptureError(error);
    openGlobalSnackbar('Oops! Something went wrong.', SNACKBARTYPE.ERROR);
    return rejectWithValue('An error occurred');
  }
});

export const initialState: TicketsState = {
  ticketsData: [],
  refreshing: false,
  columns: TICKET_COLUMNS,
  loadingSingleTicket: false,
  saveTickets: false,
  predefinedTickets: false,
  closeShiftsWithOngoingOrders: false,
};

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    resetTicketsState: () => initialState,
    setTicketsState: (state, action: { payload: ITicketsResponseDTO[] }) => {
      state.ticketsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketConfigs.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchTicketConfigs.fulfilled, (state, action) => {
        state.saveTickets = action.payload.saveTickets;
        state.predefinedTickets = action.payload.predefinedTickets;
        state.closeShiftsWithOngoingOrders =
          action.payload.closeShiftsWithOngoingOrders;
        state.refreshing = false;
      })
      .addCase(fetchTicketConfigs.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(fetchTickets.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.ticketsData = action.payload;
        state.refreshing = false;
      })
      .addCase(fetchTickets.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(createTicket.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.ticketsData.push(action.payload);
        state.loadingSingleTicket = false;
        state.refreshing = false;
      })
      .addCase(createTicket.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(toggleSaveTickets.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(toggleSaveTickets.fulfilled, (state, action) => {
        state.saveTickets = action.payload.saveTickets;
        state.refreshing = false;
      })
      .addCase(toggleSaveTickets.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(togglePredefinedTickets.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(togglePredefinedTickets.fulfilled, (state, action) => {
        state.predefinedTickets = action.payload.predefinedTickets;
        state.refreshing = false;
      })
      .addCase(togglePredefinedTickets.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(getSingleTicket.pending, (state) => {
        state.loadingSingleTicket = true;
      })
      .addCase(getSingleTicket.fulfilled, (state) => {
        state.loadingSingleTicket = false;
      })
      .addCase(getSingleTicket.rejected, (state) => {
        state.loadingSingleTicket = false;
      })
      .addCase(updateTicketsData.pending, (state) => {
        state.loadingSingleTicket = true;
      })
      .addCase(updateTicketsData.fulfilled, (state, action) => {
        const index = state.ticketsData.findIndex(
          (ticket) => ticket.id === action.payload.id,
        );
        if (index !== -1) {
          state.ticketsData[index] = action.payload;
        }
      })
      .addCase(updateTicketsData.rejected, (state) => {
        state.loadingSingleTicket = false;
      })
      .addCase(deleteTicketsData.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(deleteTicketsData.fulfilled, (state, action) => {
        const index = state.ticketsData.findIndex(
          (ticket) => ticket.id.toString() === action.payload.toString(),
        );
        if (index !== -1) {
          state.ticketsData.splice(index, 1);
        }
        state.refreshing = false;
      })
      .addCase(deleteTicketsData.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(clearPredefinedTicket.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(clearPredefinedTicket.fulfilled, (state, action) => {
        const index = state.ticketsData.findIndex(
          (ticket) => ticket.id === action.payload.id,
        );
        if (index !== -1) {
          state.ticketsData[index] = action.payload;
        }
        state.refreshing = false;
      })
      .addCase(clearPredefinedTicket.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(updateTicketSequenceNumbers.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(updateTicketSequenceNumbers.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(updateTicketSequenceNumbers.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(toggleCloseShift.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(toggleCloseShift.fulfilled, (state) => {
        state.refreshing = false;
      })
      .addCase(toggleCloseShift.rejected, (state) => {
        state.refreshing = false;
      })
      .addCase(clearAllOngoingTickets.pending, (state) => {
        state.refreshing = true;
      })
      .addCase(clearAllOngoingTickets.fulfilled, (state) => {
        state.refreshing = false;
        state.closeShiftsWithOngoingOrders = false;
      })
      .addCase(clearAllOngoingTickets.rejected, (state) => {
        state.refreshing = false;
      });
  },
});

export const { resetTicketsState, setTicketsState } = ticketSlice.actions;

export default ticketSlice.reducer;
